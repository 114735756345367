import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Col, Accordion, Badge, Dropdown, Modal, Form, ButtonGroup, ToggleButton, Table, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShip, faArrowLeft, faFilePdf, faFileExcel, faEye, faDownload, faTrash, faEllipsisV, faSearch, faEdit, faPen, faPlusCircle, faFile, faComment, faCashRegister, faCalendar, faCalendarAlt, faCheck } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../../navbar/Navbar";
import Dropzone from 'react-dropzone'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
//import "./DetailedOperation.css";
import { del, get, post, put } from '../../../controllers/Endpoints';
import { getUserId, getUserToken, logout } from '../../../controllers/UserController';
import { processDate, fileToBase64, timeDueWithBadge, convert2Base64, getFileIcon, downloadFile } from '../../../tools/tools';
import "../../../styles/styles.css";
import { timeDue } from '../../../tools/tools';
import { params } from '../../../tools/constants';
import PDFViewer from 'mgr-pdf-viewer-react';

export default function DetailedOperation() {

    let opTypeAux = "";
    let complexityAux = "";
    const [error, setError] = React.useState(<div></div>);
    const [operationType, setOperationType] = React.useState('');
    const [operationTypes, setOperationTypes] = React.useState([]);
    const [operation, setOperation] = React.useState({});
    const [operationId, setOperationId] = React.useState(0);
    const [operationStatus, setOperationStatus] = React.useState("");
    const [events, setEvents] = React.useState([]);
    const [comments, setComments] = React.useState([]);
    const [statuses, setStatuses] = React.useState([]);
    const [currencies, setCurrencies] = React.useState([]);
    const [receiptTypes, setReceiptTypes] = React.useState([]);
    const [selectedBusiness, setSelectedBusiness] = React.useState(null);
    const [coverDate, setCoverDate] = React.useState(new Date());
    const [operationData, setOperationData] = React.useState({});
    const [shippingData, setShippingData] = React.useState({ vessel: "", line: "", terminal: "", comment: "" });

    const [showEditStatusModal, setShowEditStatusModal] = React.useState(false);
    const handleCloseEditStatusModal = () => { setShowEditStatusModal(false); };
    const handleShowEditStatusModal = () => {
        setShowEditStatusModal(true);
    };

    const [selectedEventId, setSelectedEventId] = React.useState(null);
    const [showDeleteEventModal, setShowDeleteEventModal] = React.useState(false);
    const handleCloseDeleteEventModal = () => { setShowDeleteEventModal(false); };
    const handleShowDeleteEventModal = (id) => {
        setSelectedEventId(id)
        setShowDeleteEventModal(true);
    };

    const [showDeleteOperationModal, setShowDeleteOperationModal] = React.useState(false);
    const handleCloseDeleteOperationModal = () => { setShowDeleteOperationModal(false); };
    const handleShowDeleteOperationModal = () => {
        setShowDeleteOperationModal(true);
    };

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState("");
    const handleCloseViewFileModal = () => { setShowViewFileModal(false); };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };

    const [textEditEventName, setTextEditEventName] = React.useState("");
    const [textEditEventDescription, setTextEditEventDescription] = React.useState("");
    const [pickerEditEventDueDate, setPickerEditEventDueDate] = React.useState(new Date());
    const [pickerEditEventAlertDate, setPickerEditEventAlertDate] = React.useState(new Date());
    const [showEditEventModal, setShowEditEventModal] = React.useState(false);
    const handleCloseEditEventModal = () => { setShowEditEventModal(false); };
    const handleShowEditEventModal = (id, name, description, dueDate, alertDate) => {
        setTextEditEventName(name)
        setTextEditEventDescription(description)
        setPickerEditEventDueDate(new Date(dueDate))
        setPickerEditEventAlertDate(new Date(alertDate))
        setSelectedEventId(id)
        setShowEditEventModal(true);
    };
    const [providers, setProviders] = React.useState([]);

    const [users, setUsers] = React.useState([]);

    const [steps, setSteps] = React.useState([]);
    const [step1, setStep1] = React.useState([]);
    const [step2, setStep2] = React.useState([]);
    const [step3, setStep3] = React.useState([]);
    const [step4, setStep4] = React.useState([]);
    const [step5, setStep5] = React.useState([]);
    const [step6, setStep6] = React.useState([]);
    const [step7, setStep7] = React.useState([]);
    const [step8, setStep8] = React.useState([]);
    const [step9, setStep9] = React.useState([]);

    const [file, setFile] = React.useState(<div></div>);

    const handleDate = (event) => {
        setCoverDate(event);
    };

    const [merchantCategories, setMerchantCategories] = React.useState([]);

    const [modalNewCommentTitle, setModalNewCommentTitle] = React.useState("Nuevo comentario");
    const [showNewFile, setShowNewFile] = React.useState(false);
    const [newCommentTitle, setNewCommentTitle] = React.useState("");
    const [newCommentContent, setNewCommentContent] = React.useState("");
    const [newCommentFile, setNewCommentFile] = React.useState(null);
    const [newCommentStep, setNewCommentStep] = React.useState(0);

    const handleShowNewFile = (step, stepName) => {
        setModalNewCommentTitle(`Nuevo comentario en ${stepName}`)
        setNewCommentStep(step);
        setShowNewFile(true);
    }

    const handleCloseNewBulkFile = () => setShowNewBulkFile(false);
    const [showNewBulkFile, setShowNewBulkFile] = React.useState(false);
    const [newBulkCommentFile, setNewBulkCommentFile] = React.useState(null);
    const [newBulkCommentStep, setNewBulkCommentStep] = React.useState(0);

    const handleShowNewBulkFile = (step) => {
        setNewBulkCommentStep(step);
        setShowNewBulkFile(true);
    }

    const handleCloseNewFile = () => setShowNewFile(false);

    const [showEditCommentModal, setShowEditCommentModal] = React.useState(false);
    const [editCommentTitle, setEditCommentTitle] = React.useState("");
    const [editCommentDescription, setEditCommentDescription] = React.useState("");
    const [editCommentId, setEditCommentId] = React.useState(null);
    const [editCommentFile, setEditCommentFile] = React.useState(null);
    const [editCommentFileName, setEditCommentFileName] = React.useState("");
    const [editCommentFileChanged, setEditCommentFileChanged] = React.useState(false);

    const handleShowEditCommentModal = (data) => {
        setEditCommentId(data.id)
        setEditCommentTitle(data.title);
        setEditCommentDescription(data.content);
        setEditCommentFileChanged(false);
        if (data.fileId == null) {
            setEditCommentFile(null)
            setEditCommentFileName(null)
        } else {
            setEditCommentFile(null)
            setEditCommentFileName(
                <Row>
                    <Col>
                        <Alert className='m-0' variant='secondary'>{data.File.name}</Alert>
                    </Col>
                    <Col xs={2} className='align-center-vertically'>
                        <Button variant='danger' onClick={deleteEditCommentFile}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button>
                    </Col>
                </Row>
            )
        }

        setShowEditCommentModal(true);
    }

    const deleteEditCommentFile = () => {
        setEditCommentFileChanged(true)
        setEditCommentFileName(null)
    }

    const handleCloseEditCommentModal = () => setShowEditCommentModal(false);


    const [isEditing, setIsEditing] = React.useState(false);
    const handleIsEditing = () => {
        setIsEditing(!isEditing)
    };


    const [showSureDeleteComment, setShowSureDeleteComment] = React.useState(false);
    const [deleteCommentId, setDeleteCommentId] = React.useState(0);
    const handleShowSureDeleteComment = (commentId) => {
        setDeleteCommentId(commentId);
        setShowSureDeleteComment(true);
    }

    const handleCloseSureDeleteComment = () => setShowSureDeleteComment(false);

    const [modalNewPaymentTitle, setModalNewPaymentTitle] = React.useState("Nueva factura");
    const [newPaymentAmount, setNewPaymentAmount] = React.useState("");
    const [newPaymentCurrencyId, setNewPaymentCurrencyId] = React.useState(0);
    const [newPaymentExchAmount, setNewPaymentExchAmount] = React.useState("");
    const [newPaymentExchQuotation, setNewPaymentExchQuotation] = React.useState("");
    const [newPaymentExchCurrencyId, setNewPaymentExchCurrencyId] = React.useState(0);
    const [newPaymentContent, setNewPaymentContent] = React.useState("");
    const [newPaymentInvoiceNumber, setNewPaymentInvoiceNumber] = React.useState("");
    const [newPaymentType, setNewPaymentType] = React.useState("Ingreso");
    const [showNewPayment, setShowNewPayment] = React.useState(false);
    const [newPaymentStep, setNewPaymentStep] = React.useState(0);

    const handleShowNewPayment = (step, stepName) => {
        setModalNewPaymentTitle(`Nueva factura en ${stepName}`)
        setNewPaymentStep(step);
        setShowNewPayment(true);
    }

    const [modalData, setModalData] = React.useState([]);
    const [invoices, setInvoices] = React.useState([]);
    const [modalSearchChain, setModalSearchChain] = React.useState("");
    const [modalTableHeader, setModalTableHeader] = React.useState([]);

    const [newSIRADispatcher, setNewSIRADispatcher] = React.useState(null);
    const [modalType, setModalType] = React.useState("");
    const [showSelect, setShowSelect] = React.useState(false);
    const handleCloseSelect = () => setShowSelect(false);
    const handleShowSelect = (t) => {
        if (t == "PAYMENT") {
            setError();
            setModalType("Proveedor / Cliente")
            setShowSelect(true)
        } else if (t == "SIRA") {
            setError();
            setModalType("Despachante")
            setShowSelect(true)
        }

    };

    const handleCloseNewPayment = () => setShowNewPayment(false);

    const handleNewPaymentAmount = (event) => {
        setNewPaymentAmount(event.target.value);
    };

    const handleNewPaymentCurrencyId = (event) => {
        setNewPaymentCurrencyId(event.target.value);
    };

    const handleNewPaymentExchAmount = (event) => {
        setNewPaymentExchAmount(event.target.value);
    };

    const handleNewPaymentExchQuotation = (event) => {
        setNewPaymentExchQuotation(event.target.value);
    };

    const handleNewPaymentExchCurrencyId = (event) => {
        setNewPaymentExchCurrencyId(event.target.value);
        getExchange(event.target.value);
    };

    const handleNewPaymentContent = (event) => {
        setNewPaymentContent(event.target.value);
    };

    const handleNewPaymentType = (event) => {
        setNewPaymentType(event);
    };

    const handleNewPaymentInvoiceNumber = (event) => {
        setNewPaymentInvoiceNumber(event.target.value);
    };

    const [newReceiptNum, setNewReceiptNum] = React.useState("");
    const [newReceiptType, setNewReceiptType] = React.useState(0);
    const [newReceiptDescription, setNewReceiptDescription] = React.useState("");
    const [newReceiptFile, setNewReceiptFile] = React.useState("");
    const [showNewReceipt, setShowNewReceipt] = React.useState(false);
    const [newReceiptPayment, setNewReceiptPayment] = React.useState(0);

    const handleShowNewReceipt = () => {
        handleCloseInvoices()
        setNewReceiptPayment();
        setSelectedBusiness();
        setShowNewReceipt(true);
    }

    const handleCloseNewReceipt = () => setShowNewReceipt(false);

    const handleNewReceiptNum = (event) => {
        setNewReceiptNum(event.target.value);
    };

    const handleNewReceiptDescription = (event) => {
        setNewReceiptDescription(event.target.value);
    };

    const handleNewReceiptType = (event) => {
        setNewReceiptType(event.target.value);
    };

    const [newEventName, setNewEventName] = React.useState("");
    const [newEventDescription, setNewEventDescription] = React.useState(0);
    const [newEventDueDate, setNewEventDueDate] = React.useState(new Date());
    const [newEventAlertDate, setNewEventAlertDate] = React.useState(new Date());
    const [selectedStep, setSelectedStep] = React.useState(null);
    const [showNewEvent, setShowNewEvent] = React.useState(false);

    const handleShowNewEvent = (stepId) => {
        setSelectedStep(stepId)
        setShowNewEvent(true);
    }

    const handleCloseNewEvent = () => setShowNewEvent(false);

    const [newSIRADate, setNewSIRADate] = React.useState(new Date());
    const [newSIRAOficializationDate, setNewSIRAOficializationDate] = React.useState(new Date());
    const [newSIRALicExpirationDate, setNewSIRALicExpirationDate] = React.useState(new Date());
    const [newSIRAExpirationDate, setNewSIRAExpirationDate] = React.useState(new Date());
    const [newSIRAAccessMLCDate, setNewSIRAAccessMLCDate] = React.useState(new Date());
    const [newSIRAFile, setNewSIRAFile] = React.useState(null);

    const handleNewSIRADate = (event) => { setNewSIRADate(event) };
    const handleNewSIRAOficializationDate = (event) => { setNewSIRAOficializationDate(event) };
    const handleNewSIRALicExpirationDate = (event) => { setNewSIRALicExpirationDate(event) };
    const handleNewSIRAExpirationDate = (event) => { setNewSIRAExpirationDate(event) };
    const handleNewSIRAAccessMLCDate = (event) => { setNewSIRAAccessMLCDate(event) };

    const handleNewSIRAFile = async (event) => {
        const file = event.target.files[0]
        setNewSIRAFile(file)
    }

    const [showNewSIRAModal, setShowNewSIRAModal] = React.useState(false);
    const handleShowNewSIRAModal = () => {
        setShowNewSIRAModal(true);
        setError();
        setSelectedBusiness();
    }
    const handleCloseNewSIRAModal = () => setShowNewSIRAModal(false);


    const [proformaNumber, setProformaNumber] = React.useState("");
    const [newProformaFile, setNewProformaFile] = React.useState(null);
    const [newProformaDate, setNewProformaDate] = React.useState(new Date());
    const handleNewProformaDate = (event) => { setNewProformaDate(event) };

    const handleNewProformaFile = async (event) => {
        const file = event.target.files[0]
        setNewProformaFile(file)
    }

    const [showNewProformaModal, setShowNewProformaModal] = React.useState(false);
    const handleShowNewProformaModal = () => {
        setError();
        setShowNewProformaModal(true);
    }
    const handleCloseNewProformaModal = () => setShowNewProformaModal(false);


    const [newCIFile, setNewCIFile] = React.useState(null);
    const [newCIDate, setNewCIDate] = React.useState(new Date());
    const handleNewCIDate = (event) => { setNewCIDate(event) };

    const handleNewCIFile = async (event) => {
        const file = event.target.files[0]
        setNewCIFile(file)
    }

    const [showNewCommercialInvoiceModal, setShowNewCommercialInvoiceModal] = React.useState(false);
    const handleShowNewCommercialInvoiceModal = () => {
        setError();
        setShowNewCommercialInvoiceModal(true);
    }
    const handleCloseNewCommercialInvoiceModal = () => setShowNewCommercialInvoiceModal(false);


    const [showShippingDataModal, setShowShippingDataModal] = React.useState(false);
    const handleShowShippingDataModal = () => {
        setError();
        setShowShippingDataModal(true);
    }
    const handleCloseShippingDataModal = () => setShowShippingDataModal(false);


    const handleNewEventName = (event) => {
        setNewEventName(event.target.value);
    };

    const handleNewEventDescription = (event) => {
        setNewEventDescription(event.target.value);
    };


    const [showInvoices, setShowInvoices] = React.useState(false);

    const handleShowInvoices = (i, pid) => {
        //setNewReceiptPayment(pid)
        setInvoices(i)
        setShowInvoices(true);
    }

    const handleCloseInvoices = () => setShowInvoices(false);



    const PAGE_TITLE = "Operación ";
    const PAGE_ICON = faShip;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `GrinBalb - ${PAGE_TITLE}`;
        init();
    }, []);


    const init = async function () {
        //window.scrollTo(0, 0);
        registerLocale('es', es);
        //getProforma();
        getCurrencies();
        getSteps();
        getOperation();
        getReceiptTypes();
        getEvents();
        getUsers();
        getStatuses();

    }

    const getStatuses = async function () {

        const req = await get(`/status/operations`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res);
            setStatuses(res);
        } else {
            alert(res.message);
        }
    }

    const getOperation = async function () {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const operationId = urlParams.get('id');

        const req = await get(`/operations/detail/${operationId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res);
            processOperation(res);
        } else {
            alert(res.message);
        }
    }

    const searchProviders = async function (c) {
        if (c.length > 0) {
            const chain = {
                chain: c
            }

            const req = await post('/business/type/ALL/search', getUserToken(), chain);
            const res = await req.json();

            if (req.status === 200) {
                processProviders(res)
            } else {
                alert(res.message);
            }
        } else {
            processProviders([])
        }
    }

    const processProviders = function (providers) {
        const cols = ['Código', 'Nombre', 'CUIT', 'Razón social', ''];
        setModalTableHeader(cols);

        let result = [];
        for (let p of providers) {
            p.action = <Button className='buttonBlue' variant="primary" onClick={() => confirmBusiness(p.id, p.name)}><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></Button>
            result.push(p);
        }
        setProviders(result)
    }

    const confirmBusiness = function (id, data) {
        setShowSelect(false);
        document.getElementById('business').value = data;
        setSelectedBusiness(id);
    }

    const processOperation = function (operation) {
        let result = [];
        for (let i = 0; i < 9; i++) {
            result.push([]);
        }

        for (let ot of operation.OperationTimelines) {
            result[ot.stepId - 1].push(ot);
        }

        opTypeAux = operation.OperationType.name;

        if(operation.Shipping){
            setShippingData(operation.Shipping)
        }

        switch (operation.complexityId) {
            case 1:
                complexityAux = <Badge bg='success' className='content-table status'>Baja</Badge>;
                break;
            case 2:
                complexityAux = <Badge bg='warning' className='content-table status'>Media</Badge>;
                break;
            case 3:
                complexityAux = <Badge bg='danger' className='content-table status'>Alta</Badge>;
                break;
        }

        setOperationData({
            complexity: complexityAux
        })

        setComments([])

        if (result[0].length > 0) { setStep1(processStepData(result[0])) } else { setStep1(noData()) }
        if (result[1].length > 0) { setStep2(processStepData(result[1])) } else { setStep2(noData()) }
        if (result[2].length > 0) { setStep3(processStepData(result[2])) } else { setStep3(noData()) }
        if (result[3].length > 0) { setStep4(processStepData(result[3])) } else { setStep4(noData()) }
        if (result[4].length > 0) { setStep5(processStepData(result[4])) } else { setStep5(noData()) }
        if (result[5].length > 0) { setStep6(processStepData(result[5])) } else { setStep6(noData()) }
        if (result[6].length > 0) { setStep7(processStepData(result[6])) } else { setStep7(noData()) }
        if (result[7].length > 0) { setStep8(processStepData(result[7])) } else { setStep8(noData()) }
        if (result[8].length > 0) { setStep9(processStepData(result[8])) } else { setStep9(noData()) }

        setOperationStatus(<Badge bg='' className='content-table status' style={{ backgroundColor: `#${operation.Status.color}` }}>{operation.Status.name}</Badge>)
        setOperation(operation)
        getProforma();
    }

    const processStepData = function (data, reset) {
        let commentsAux = comments;
        let result = [];
        let isFirst = true;
        let aux1, aux2, aux3, aux4, aux5;

        for (let s of data) {
            if (s.Comment != null) {
                //Its a comment
                result.push(Comment(isFirst, s.Comment.title, s.Comment.content, s.showAsFile, s.Comment.File, s.User, s.createdAt, s.Comment.id, s.Comment));
                commentsAux.push(s)
            } else if (s.Payment != null) {
                //Its a payment
                aux1 = `${s.Payment.amount} ${s.Payment.currencyAmount.code}`;
                aux2 = `${s.Payment.exchAmount} ${s.Payment.currencyExchange.code}`;
                aux3 = <Badge bg={s.Payment.Status.color}>{s.Payment.Status.name}</Badge>
                aux4 = s.Payment.Receipts[0]
                aux5 = s.Payment.Receipts
                result.push(Payment(s.Payment.income, aux1, aux2, s.Payment.description, s.User, s.createdAt, s.Payment.id, s.Payment.Receipts, s.Payment.Business.legalName, aux3, aux4.number, aux5))
            } else if (s.Event != null) {
                //Its an event
                result.push(Event(s.Event.name, s.Event.description, s.Event.User, s.Event.alert, s.Event.dueDate, s.Event.createdAt, s.Event.id, s.Event.isDone))
            } else if (s.Cover != null) {
                result.push(Cover(opTypeAux, s.Cover, s.User, s.createdAt))
            } else if (s.SIRA != null) {
                //Its an SIRA
                console.log(s)
                result.push(SIRA(isFirst, "SIRA", s.SIRA.number, s.showAsFile, s.SIRA.File, s.User, s.createdAt, s.SIRA.id))
            } else if (s.Proforma != null) {
                //Its an Proforma
                result.push(Proforma(isFirst, "Proforma", s.Proforma.number, s.showAsFile, s.Proforma.File, s.User, s.createdAt, s.Proforma.id))
            } else if (s.CommercialInvoice != null) {
                //Its an Commercial Invoice
                result.push(Proforma(isFirst, "Commercial Invoice", s.CommercialInvoice.number, s.showAsFile, s.CommercialInvoice.File, s.User, s.createdAt, s.CommercialInvoice.id))
            }
            isFirst = false;
        }

        setComments([...commentsAux])
        return result;
    }

    const noData = function () {
        return ([NoElement()])
    }

    const getSteps = async function () {
        const req = await get(`/operations/steps`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setSteps(res);
        } else {
            alert(res.message);
        }
    }

    const getExchange = async function (currency) {
        const req = await get(`/exchange/${currency}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setNewPaymentExchQuotation(res.exchange)
            if (newPaymentAmount != null) {
                if (newPaymentAmount.length > 0) {
                    setNewPaymentExchAmount((newPaymentAmount / res.exchange).toFixed(2))
                }
            }
        } else {
            alert(res.message);
        }
    }

    /*const viewFile = async function (fileId) {
        const req = await get(`/files/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            //setShowFile(true);
            //showFileData(res.data);

            const linkSource = res.data;
            const downloadLink = document.createElement("a");
            const fileName = "demo.pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

        } else {
            alert(res.message);
        }
    }*/

    const viewFile = async (fileId, name) => {
        const req = await get(`/files/byIdNoHeader/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActualViewFile(res.file)
            handleShowViewFileModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const showFileData = function (file) {
        if (file.includes("pdf")) {
            //Its a PDF file
            setFile(
                <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                    <object data={file} width="100%" height="100%"></object>
                </div>
            )
        }
    }

    const handleNewCommentFile = async function (event) {
        const file = event.target.files[0]
        setNewCommentFile(file)
    }

    const handleNewBulkCommentFile = async function (event) {
        const file = event.target.files
        setNewBulkCommentFile(file)
    }

    const handleEditCommentFile = async function (event) {
        const file = event.target.files[0]
        setEditCommentFile(file)
        setEditCommentFileChanged(true)
    }

    const processReceiptFile = async function (file) {
        const result = await fileToBase64(file[0]);
        setNewReceiptFile({
            data: result,
            name: file[0].name,
            extension: file[0].name.split(".")[(file[0].name.split(".").length) - 1]
        });
        /*setFile1Alert(
            <Alert variant='success'>
                {file[0].name}
            </Alert>
        );*/

    }

    const createComment = async function () {
        let file = null;
        let fileExtension = null;

        if (newCommentFile != null) {
            file = await convert2Base64(newCommentFile);
            const aux = newCommentFile.name.split('.')
            fileExtension = `.${aux[aux.length - 1]}`
        }

        const comment = {
            file: file,
            extension: fileExtension,
            title: document.getElementById('textNewFileTitle').value,
            content: document.getElementById('textNewFileDescription').value,
            stepId: newCommentStep,
            operationId: operation.id
        }

        const req = await post(`/operations/comment`, getUserToken(), comment);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else {
            alert(res.message);
        }
    }

    const createBulkComment = async function () {
        let files = [];
        let aux;

        for(let f of newBulkCommentFile){
            aux = f.name.split('.')
            files.push({
                file: await convert2Base64(f),
                extension: `.${aux[aux.length - 1]}`,
                name: f.name
            })
        }

        console.log(files)

        const comment = {
            files: files,
            stepId: newBulkCommentStep,
            operationId: operation.id
        }

        const req = await post(`/operations/commentbulk`, getUserToken(), comment);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else {
            alert(res.message);
        }
    }

    const editComment = async function () {
        let file = null;
        let fileExtension = null;

        let body = {
            title: document.getElementById('textEditCommentTitle').value,
            content: document.getElementById('textEditCommentDescription').value,
            id: editCommentId,
            operationId: operation.id
        }

        if (editCommentFileChanged) {
            if (editCommentFile != null) {
                file = await convert2Base64(editCommentFile);
                const aux = editCommentFile.name.split('.')
                fileExtension = `.${aux[aux.length - 1]}`

                body.file = file;
                body.extension = fileExtension;
            } else {
                body.file = null;
            }
        }

        const req = await put(`/operations/comment`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else {
            alert(res.message);
        }
    }

    const createPayment = async function () {
        const payment = {
            amount: newPaymentAmount,
            currencyId: newPaymentCurrencyId,
            exchAmount: newPaymentExchAmount,
            exchQuotation: newPaymentExchQuotation,
            exchCurrencyId: newPaymentExchCurrencyId,
            description: newPaymentContent,
            income: newPaymentType == "Ingreso" ? true : false,
            stepId: newPaymentStep,
            operationId: operation.id,
            businessId: selectedBusiness,
            file: newReceiptFile,
            invoiceNumber: newPaymentInvoiceNumber
        }
        console.log(payment)

        const req = await post(`/operations/payment`, getUserToken(), payment);
        const res = await req.json();

        if (req.status === 200) {
            setShowNewPayment(false);
            getOperation();
        } else {
            alert(res.message);
        }
    }

    const getCurrencies = async function () {
        const req = await get('/currencies/', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setCurrencies(res);
            console.log(res)
        } else {
            alert(res.message);
        }
    }

    const getEvents = async function () {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const operationId = urlParams.get('id');

        const req = await get(`/operations/events/byOperation/${operationId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setEvents(res)
            processEvents(res)
        } else {
            alert(res.message);
        }
    }

    const getProforma = async function () {
        const req = await get(`/operations/proforma/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            if (res.number) {
                document.getElementById('textCoverProformaNumber').value = res.number
            }

        } else {
            alert(res.message);
        }
    }

    const processEvents = (data) => {
        setEvents(data);
    }

    const getUsers = async function () {
        const req = await get(`/users/employees`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(getUserId(), res)
            setUsers(res)
        } else {
            alert(res.message);
        }
    }

    const getReceiptTypes = async function () {
        const req = await get('/receipts/types/', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setReceiptTypes(res);
        } else {
            alert(res.message);
        }
    }

    const createReceipt = async function () {
        const receipt = {
            file: newReceiptFile,
            number: newReceiptNum,
            description: newReceiptDescription,
            receiptTypeId: newReceiptType,
            paymentId: newReceiptPayment,
        }
        console.log(receipt)

        const req = await post(`/operations/payment/receipt`, getUserToken(), receipt);
        const res = await req.json();

        if (req.status === 200) {
            setShowNewReceipt(false);
            getOperation();
        } else {
            alert(res.message);
        }
    }

    const createEvent = async function () {
        let notif = [];

        for (let u of users) {
            if (document.getElementById(`checkEventUser-${u.id}`).checked === true) {
                notif.push(u.id)
            }
        }

        const body = {
            operationId: operation.id,
            eventTypeId: null,
            name: newEventName,
            description: newEventDescription,
            alert: newEventAlertDate,
            dueDate: newEventDueDate,
            step: selectedStep,
            users: notif
        }

        console.log(body);

        const req = await post(`/operations/events`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            setShowNewEvent(false);
            getOperation();
            getEvents();
        } else {
            alert(res.message);
        }
    }

    const deleteComment = async function () {
        const body = {
            commentId: deleteCommentId
        }
        console.log(body)

        const req = await del(`/operations/comment`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseSureDeleteComment();
            getOperation();
        } else {
            alert(res.message);
        }
    }

    const deleteEvent = async function () {
        const body = {
            eventId: selectedEventId
        }

        const req = await del(`/operations/events`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseDeleteEventModal();
            getOperation();
            getEvents();
        } else {
            alert(res.message);
        }
    }

    const editEvent = async function () {
        const body = {
            name: document.getElementById('textEditEventName').value,
            description: document.getElementById('textEditEventDescription').value,
            alert: pickerEditEventAlertDate,
            dueDate: pickerEditEventDueDate,
            id: selectedEventId
        }

        const req = await put(`/operations/events`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            setShowEditEventModal(false);
            getOperation();
            getEvents();
        } else {
            alert(res.message);
        }
    }



    const Cover = (type, cover, user, date) => (
        <div className='p-0'>
            <Accordion className='p-0'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div>
                            <p className='m-0'>Carátula</p>
                            <p className='mt-3 mb-0' style={{ fontSize: 12 }}><img className='me-1' style={{ width: 20, borderRadius: "50%" }} src={`${params().dataUri}${user.avatar}`} alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{user.name} {user.surname} @ {processDate(date)}</span></p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>











                        <Row className='ms-4 me-4 mt-4'>
                            <Col xs={2} className='cover content rows title'>
                                Fecha de creación
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" placeholder="" value={processDate(date)} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Tipo de operación
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value={type} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Complejidad
                            </Col>
                            <Col>
                                {complexityAux}
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-5'>
                            <Col xs={2} className='cover content rows title'>

                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Empresa
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Contacto
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Importador
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value={cover.Importer.name} />
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value={cover.ImporterContact != null ? cover.ImporterContact.name : ""} />

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Exportador
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value={cover.Exporter.name} />
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value={cover.ExporterContact != null ? cover.ExporterContact.name : ""} />

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Cliente
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value={cover.Client.name} />
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value={cover.ClientContact != null ? cover.ClientContact.name : ""} />

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Despachante
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value={cover.Dispatcher != null ? cover.Dispatcher.name : ""}/>

                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value={cover.DispatcherContact != null ? cover.DispatcherContact.name : ""} />

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3 mb-5'>
                            <Col xs={2} className='cover content rows title'>
                                Forwarder
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value={cover.Forwarder != null ? cover.Forwarder.name : ""} />

                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="" placeholder="" value={cover.ForwarderContact != null ? cover.ForwarderContact.name : ""} />

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-5'>
                            <Col xs={2} className='cover content rows title'>

                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Valores
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Unidades
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Totales (USD)
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Mercadería
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.chCommodity} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.chCommodityUnit} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Posición arancelaria
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly defaultValue={cover.chTariffPosition} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly defaultValue={cover.chTariffPositionUnit} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Origen
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.Country != null ? cover.Country.name : ""} />
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                ETD
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={processDate(cover.chEtd)} />
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                ETA
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={processDate(cover.chEta)} />
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Licencia
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.chLicense} />
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Cantidad
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.chQuantity} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.chQuantityUnit} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                FOB real
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.chFobReal} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.chFobRealUnit} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.chFobRealTotal} />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                CIF real
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.chCifReal} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.chCifRealUnit} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.chCifRealTotal} />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Incoterm
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.chIncoterm} />
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                CNTR
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.chCntr} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.chCntrUnit} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Proforma
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textCoverProformaNumber" readOnly placeholder="" />
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3 mb-5'>
                            <Col xs={2} className='cover content rows title'>
                                Condición de pago
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.chPaymentCondition} />
                            </Col>

                        </Row>

                        <Row className='ms-4 me-4 mt-5'>
                            <Col xs={2} className='cover content rows title'>

                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Valores
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Unidades
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Totales (USD)
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Shipper
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buShipper} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buShipperUnit} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buShipperTotal} />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Cliente
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buClient} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buClientUnit} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buClientTotal} />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Despachante
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buDispatcher} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buDispatcherUnit} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buDispatcherTotal} />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Forwarder (x gs agencia)
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buForwarderAgency} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buForwarderAgencyUnit} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buForwarderAgencyTotal} />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Forwarder (x dif flete)
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buForwarderCharter} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buForwarderCharterUnit} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buForwarderCharterTotal} />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Diferencia TC anticipo
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buDiffTCAdvance} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buDiffTCAdvanceUnit} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buDiffTCAdvanceTotal} />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Diferencia TC saldo
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buDiffTCBalance} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buDiffTCBalanceUnit} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buDiffTCBalanceTotal} />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Diferencia impositiva
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buDiffTax} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buDiffTaxUnit} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buDiffTaxTotal} />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Varios
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buOthers} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buOthersUnit} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={cover.buOthersTotal} />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3 mb-2'>
                            <Col xs={2} className='cover content rows title'>
                                Total
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="" readOnly placeholder="" defaultValue={cover.buTotal} />
                            </Col>
                        </Row>



                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )



    /* const vesselFinder = function(first){
 
         return(
             <div className={`map element mt-0}`}>
                 <iframe name="marinetraffic" id="marinetraffic" height="500" width="100%" scrolling="no" frameborder="0" src="https://www.marinetraffic.com/en/ais/embed/zoom:7/maptype:4/shownames:false/mmsi:0/shipid:6883302/vtypes:/showmenu:true/remember:false"></iframe>
             </div>
         )
     }*/

    const Comment = (isFirst, title, content, isFile, file, user, date, commentId, data) => (
        <div className={`file element ${isFirst ? 'mt-0' : 'mt-2'}`}>
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>{title}</p>
                        <p className='m-0' style={{ fontSize: 12 }}>{content}</p>
                        <p className='mt-3 mb-0' style={{ fontSize: 12 }}><img className='me-1' style={{ width: 20, borderRadius: "50%" }} src={`${params().dataUri}${user.avatar}`} alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{user.name} {user.surname} @ {processDate(date)}</span></p>
                    </td>
                    <td>

                        {isFile ? <FontAwesomeIcon icon={getFileIcon(file.url)} style={{ marginRight: 20, color: 'black', fontSize: 30 }} /> : <div></div>}
                    </td>
                    <td className='w-100'>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>

                                {isFile ? <div>{file.extension == '.pdf' ? <Dropdown.Item onClick={() => viewFile(file.id, file.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver</Dropdown.Item> : <div></div>}</div> : ''}
                                {isFile ? <Dropdown.Item onClick={() => downloadFile(file.id, file.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 9, marginLeft: 1 }} />Descargar</Dropdown.Item> : ''}
                                <Dropdown.Item onClick={() => handleShowEditCommentModal(data)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10, marginLeft: 2 }} />Modificar</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleShowSureDeleteComment(commentId)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            </table>
        </div>
    )

    const SIRA = (isFirst, title, content, isFile, file, user, date, commentId, data) => (
        <div className={`file element ${isFirst ? 'mt-0' : 'mt-2'}`}>
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>{title}</p>
                        <p className='m-0' style={{ fontSize: 12 }}>{content}</p>
                        <p className='mt-3 mb-0' style={{ fontSize: 12 }}><img className='me-1' style={{ width: 20, borderRadius: "50%" }} src={`${params().dataUri}${user.avatar}`} alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{user.name} {user.surname} @ {processDate(date)}</span></p>
                    </td>
                    <td>

                        {isFile ? <FontAwesomeIcon icon={getFileIcon(file.url)} style={{ marginRight: 20, color: 'black', fontSize: 30 }} /> : <div></div>}
                    </td>
                    <td className='w-100'>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>

                                {isFile ? <div>{file.extension == '.pdf' ? <Dropdown.Item onClick={() => viewFile(file.id, file.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver</Dropdown.Item> : <div></div>}</div> : ''}
                                {isFile ? <Dropdown.Item onClick={() => downloadFile(file.id, file.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 9, marginLeft: 1 }} />Descargar</Dropdown.Item> : ''}
                                <Dropdown.Item onClick={() => handleShowSureDeleteComment(commentId)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            </table>
        </div>
    )

    const Proforma = (isFirst, title, content, isFile, file, user, date, commentId, data) => (
        <div className={`file element ${isFirst ? 'mt-0' : 'mt-2'}`}>
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>{title}</p>
                        <p className='m-0' style={{ fontSize: 12 }}>{content}</p>
                        <p className='mt-3 mb-0' style={{ fontSize: 12 }}><img className='me-1' style={{ width: 20, borderRadius: "50%" }} src={`${params().dataUri}${user.avatar}`} alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{user.name} {user.surname} @ {processDate(date)}</span></p>
                    </td>
                    <td>

                        {isFile ? <FontAwesomeIcon icon={getFileIcon(file.url)} style={{ marginRight: 20, color: 'black', fontSize: 30 }} /> : <div></div>}
                    </td>
                    <td className='w-100'>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>

                                {isFile ? <div>{file.extension == '.pdf' ? <Dropdown.Item onClick={() => viewFile(file.id, file.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver</Dropdown.Item> : <div></div>}</div> : ''}
                                {isFile ? <Dropdown.Item onClick={() => downloadFile(file.id, file.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 9, marginLeft: 1 }} />Descargar</Dropdown.Item> : ''}
                                <Dropdown.Item onClick={() => handleShowSureDeleteComment(commentId)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            </table>
        </div>
    )

    const Payment = (income, amount, exchange, description, user, date, paymentId, receipts, business, status, invoice, invoices) => (
        <div className={`file element mt-2`}>
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>{income ? "Cobro" : "Pago"} - Factura {invoice} - {description}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{status} - {business}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{amount} - {exchange}</p>
                        <p className='mt-3 mb-0' style={{ fontSize: 12 }}><img className='me-1' style={{ width: 20, borderRadius: "50%" }} src={`${params().dataUri}${user.avatar}`} alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{user.name} {user.surname} @ {processDate(date)}</span></p>
                    </td>
                    <td>
                        {receipts[0].File ? <FontAwesomeIcon icon={getFileIcon(receipts[0].File.url)} style={{ marginRight: 20, color: '#DC3545', fontSize: 30 }} /> : <div></div>}

                    </td>
                    <td className='w-100'>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleShowInvoices(invoices, paymentId)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver documentos</Dropdown.Item>
                                <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            </table>
        </div>
    )

    const Event = (title, description, user, alertDate, dueDate, date, eventId, done) => (
        <div className='file element mt-2' >
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>{title}</p>
                        <p className='m-0' style={{ fontSize: 12 }}>{description}</p>
                        <p>{done ? <Badge bg='success'>Realizado</Badge> : <span>{timeDueWithBadge(dueDate)}</span>}</p>
                        <p className='mt-3 mb-0' style={{ fontSize: 12 }}><img className='me-1' style={{ width: 20, borderRadius: "50%" }} src={`${params().dataUri}${user.avatar}`} alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{user.name} {user.surname} @ {processDate(date)}</span></p>
                    </td>
                    <td className='w-100'>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setEventAsCompleted(eventId)}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 10, marginLeft: 2 }} />Marcar como completado</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleShowEditEventModal(eventId, title, description, dueDate, alertDate)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10, marginLeft: 2 }} />Modificar</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleShowDeleteEventModal(eventId)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            </table>
        </div>
    )

    const NoElement = () => (
        <Row style={{ height: 300, textAlign: "center" }}>
            <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faShip}></FontAwesomeIcon></p>
            <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay elementos en esta etapa</p>
        </Row>
    )



    /*const fileExtIcon = function (ext) {
        if (ext.includes(".pdf")) {
            return (<FontAwesomeIcon icon={faFilePdf} style={{ marginRight: 20, color: '#DC3545', fontSize: 30 }} />)
        }
        if (ext.includes(".xlsx")) {
            return (<FontAwesomeIcon icon={faFileExcel} style={{ marginRight: 20, color: '#28A745', fontSize: 30 }} />)
        }
    }*/



    const getBusiness = async function () {
        const req = await get('/business/type/ALL', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processBusiness(res)
        } else {
            alert(res.message);
        }
    }

    const handleSearchChain = (event) => {
        const chain = event.target.value;

        searchProviders(chain)
    };

    const searchBusiness = async function (c) {
        const chain = {
            chain: c
        }

        const req = await post('/business/type/ALL/search', getUserToken(), chain);
        const res = await req.json();

        if (req.status === 200) {
            processBusiness(res)
        } else {
            alert(res.message);
        }
    }

    const setEventAsCompleted = async function (id) {
        const body = {
            id: id
        }

        const req = await put('/operations/events/completed', getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            init();
        } else {
            alert(res.message);
        }
    }

    const createSIRA = async function () {
        if (checkSIRA()) {
            let file = null;
            let fileExtension = null;

            if (newSIRAFile != null) {
                file = await convert2Base64(newSIRAFile);
                const aux = newSIRAFile.name.split('.')
                fileExtension = `.${aux[aux.length - 1]}`
            }

            const body = {
                date: newSIRADate,
                number: document.getElementById('textNewSIRANumber').value,
                dispatcherId: selectedBusiness,
                oficialization: newSIRAOficializationDate,
                license: document.getElementById('selectNewSIRALicense').value,
                licenceExpiration: newSIRALicExpirationDate,
                expiration: newSIRAExpirationDate,
                status: document.getElementById('textNewSIRAStatus').value,
                accessMLC: newSIRAAccessMLCDate,
                accessMLCDays: document.getElementById('textNewSIRAMLCDays').value,
                lnaForm: document.getElementById('textNewSIRALNAForm').value,
                comment: document.getElementById('textNewSIRAComments').value,
                file: file,
                extension: fileExtension,
                operationId: operation.id
            }

            const req = await post('/operations/sira/', getUserToken(), body);
            const res = await req.json();

            if (req.status === 201) {
                init();
                handleCloseNewSIRAModal();
            } else {
                alert(res.message);
            }
        }
    }

    const checkSIRA = () => {
        if (document.getElementById('textNewSIRANumber').value.length <= 1) {
            setError(<Alert className='mt-4 w-100 content-table status' variant='danger'>Ingrese un número de SIRA</Alert>);
            return false;
        } else if (isNaN(parseInt(document.getElementById('textNewSIRAMLCDays').value))) {
            setError(<Alert className='mt-4 w-100 content-table status' variant='danger'>El plazo de acceso MLC debe ser un número</Alert>);
            return false;
        } else if (newSIRAFile == null) {
            setError(<Alert className='mt-4 w-100 content-table status' variant='danger'>Especifique un archivo</Alert>);
            return false;
        } else {
            setError();
            return true;
        }
    }

    const createProforma = async function () {
        if (checkProforma()) {
            let file = null;
            let fileExtension = null;

            if (newProformaFile != null) {
                file = await convert2Base64(newProformaFile);
                const aux = newProformaFile.name.split('.')
                fileExtension = `.${aux[aux.length - 1]}`
            }

            const body = {
                date: newProformaDate,
                number: document.getElementById('textNewProformaNumber').value,
                file: file,
                extension: fileExtension,
                operationId: operation.id
            }

            const req = await post('/operations/proforma/', getUserToken(), body);
            const res = await req.json();

            if (req.status === 201) {
                init();
                handleCloseNewProformaModal();
            } else {
                alert(res.message);
            }
        }
    }

    const checkProforma = () => {
        if (document.getElementById('textNewProformaNumber').value.length <= 1) {
            setError(<Alert className='mt-4 w-100 content-table status' variant='danger'>Ingrese un número de Proforma</Alert>);
            return false;
        } else if (newProformaFile == null) {
            setError(<Alert className='mt-4 w-100 content-table status' variant='danger'>Especifique un archivo</Alert>);
            return false;
        } else {
            setError();
            return true;
        }
    }





    const createCommercialInvoice = async function () {
        if (checkCommercialInvoice()) {
            let file = null;
            let fileExtension = null;

            if (newCIFile != null) {
                file = await convert2Base64(newCIFile);
                const aux = newCIFile.name.split('.')
                fileExtension = `.${aux[aux.length - 1]}`
            }

            const body = {
                date: newCIDate,
                number: document.getElementById('textNewCINumber').value,
                file: file,
                extension: fileExtension,
                operationId: operation.id
            }

            const req = await post('/operations/ci/', getUserToken(), body);
            const res = await req.json();

            if (req.status === 201) {
                init();
                handleCloseNewCommercialInvoiceModal();
            } else {
                alert(res.message);
            }
        }
    }

    const checkCommercialInvoice = () => {
        if (document.getElementById('textNewCINumber').value.length <= 1) {
            setError(<Alert className='mt-4 w-100 content-table status' variant='danger'>Ingrese un número de Proforma</Alert>);
            return false;
        } else if (newCIFile == null) {
            setError(<Alert className='mt-4 w-100 content-table status' variant='danger'>Especifique un archivo</Alert>);
            return false;
        } else {
            setError();
            return true;
        }
    }

    const editShippingData = async function () {
        const body = {
            vessel: document.getElementById('textShippingVessel').value,
            terminal: document.getElementById('textShippingTerminal').value,
            line: document.getElementById('textShippingVesselLine').value,
            comment: document.getElementById('textShippingComment').value,
            operationId: operation.id
        }

        const req = await post('/operations/shipping/', getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            init();
            handleCloseShippingDataModal();
        } else {
            alert(res.message);
        }
    }



    const processBusiness = function (providers) {
        const cols = ['Código', 'Nombre', 'CUIT', 'Razón social', ''];
        setModalTableHeader(cols);

        let result = [];
        for (let p of providers) {
            p.action = <Button className='buttonBlue' variant="primary" onClick={() => confirmBusiness(p.id, p.legalName, false)}>Seleccionar</Button>
            result.push(p);
        }

        setModalData(
            <Table striped hover>
                <thead>
                    <tr>
                        {cols.map((c, i) => (
                            <th key={i} className='opTable head'>{c}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {result.map((r, i) => (
                        <tr key={i} >
                            <td className='opTable rows align-middle'>{r.identifier}</td>
                            <td className='opTable rows align-middle'>{r.name}</td>
                            <td className='opTable rows align-middle'>{r.legalId}</td>
                            <td className='opTable rows align-middle'>{r.legalName}</td>
                            <td className='opTable rows align-middle'>{r.action}</td>
                        </tr>
                    ))}

                </tbody>
            </Table>
        )
    }



    const getParamId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const updateStatus = async function () {
        const body = {
            statusId: document.getElementById('selectStatus').value,
            operationId: getParamId()
        }
        const req = await put(`/operations/status`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseEditStatusModal()
            init()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const editCover = () => {
        window.location.href = `/operaciones/nueva/?id=${getParamId()}`;
    }

    const deleteOperation = async function () {
        const body = {
            operationId: getParamId()
        }
        const req = await del(`/operations/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = '/operaciones'
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontWeight: 300 }}>
                                    <p style={{ fontSize: 20 }} className='text-muted mb-0'>Operación</p>
                                    <p style={{ color: '#A0A0A0', fontSize: 45 }}>{operation.code}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={faShip} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-4'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left50'>
                                    <Link to={'/operaciones'} className='linkBlue'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Operaciones
                                    </Link>
                                    <span className='ms-3' style={{ color: '#A0A0A0' }}>|</span><span className='ms-3'>{operationStatus}</span>
                                </td>
                                <td className='header rows right'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={handleShowEditStatusModal}><FontAwesomeIcon icon={faPen} style={{ marginRight: 8 }} />Modificar estado</Dropdown.Item>
                                            <Dropdown.Item onClick={editCover}><FontAwesomeIcon icon={faPen} style={{ marginRight: 8 }} />Modificar carátula</Dropdown.Item>
                                            <Dropdown.Item onClick={handleShowShippingDataModal}><FontAwesomeIcon icon={faPen} style={{ marginRight: 8 }} />Modificar datos de envío</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleShowNewEvent(null)}><FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: 8 }} />Crear evento</Dropdown.Item>
                                            <Dropdown.Item onClick={handleShowNewProformaModal}><FontAwesomeIcon icon={faFile} style={{ marginRight: 8 }} />Nueva Proforma</Dropdown.Item>
                                            <Dropdown.Item onClick={handleShowNewCommercialInvoiceModal}><FontAwesomeIcon icon={faFile} style={{ marginRight: 8 }} />Nueva Commercial Invoice</Dropdown.Item>
                                            <Dropdown.Item onClick={handleShowNewSIRAModal}><FontAwesomeIcon icon={faFile} style={{ marginRight: 8 }} />Nueva SIRA</Dropdown.Item>
                                            <Dropdown.Item onClick={handleShowDeleteOperationModal}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 8 }} />Eliminar</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </table>


                        <Container>
                            <Row className='mt-4 mb-4 ps-3 pe-3'>
                                {step1.map((s, i) => (
                                    <div>{s}</div>
                                ))}

                                <Accordion className=' mt-3'>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <div>
                                                <p className='m-0'>Datos de envío</p>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Row className='mt-3'>
                                                <Col xs={2}>
                                                    Buque
                                                </Col>
                                                <Col>
                                                    <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={shippingData.vessel} />
                                                </Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col xs={2}>
                                                    Terminal
                                                </Col>
                                                <Col>
                                                    <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={shippingData.terminal} />
                                                </Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col xs={2}>
                                                    Línea
                                                </Col>
                                                <Col>
                                                    <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={shippingData.line} />
                                                </Col>
                                            </Row>
                                            <Row className='mt-3 mb-3'>
                                                <Col xs={2}>
                                                    BL / CONT
                                                </Col>
                                                <Col>
                                                    <Form.Control className='' type="text" id="" placeholder="" readOnly defaultValue={shippingData.comment} />
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                                <Accordion className=' mt-3'>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <div>
                                                <p className='m-0'>Eventos</p>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {events.length > 0 ?
                                                <div>
                                                    {events.map((e, i) => (
                                                        <div>
                                                            {Event(e.name, e.description, e.User, e.alert, e.dueDate, e.createdAt, e.id, e.isDone)}
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                <Row style={{ height: 300, textAlign: "center" }}>
                                                    <p className='' style={{ height: 20, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon></p>
                                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay eventos</p>
                                                </Row>
                                            }


                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                                <Accordion className=' mt-3'>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <div>
                                                <p className='m-0'>Comentarios</p>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {comments.length > 0 ?
                                                <div>
                                                    {comments.map((s, i) => (
                                                        <div>
                                                            {Comment(false, s.Comment.title, s.Comment.content, s.showAsFile, s.Comment.File, s.User, s.createdAt, s.Comment.id)}
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                <Row style={{ height: 300, textAlign: "center" }}>
                                                    <p className='' style={{ height: 20, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faComment}></FontAwesomeIcon></p>
                                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay comentarios</p>
                                                </Row>
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            </Row>
                        </Container>


                    </Card>
                </Row>
            </Container>

            <Container>
                {steps.map((s, i) => (
                    <Row>
                        {i == 0 ? "" :
                            <Card className='cards content mb-4'>
                                <table className='header content'>
                                    <tr>
                                        <td className='header rows left50'>
                                            <span>{s.name}</span>
                                        </td>
                                        <td className='header rows right'>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleShowNewBulkFile(s.id)}><FontAwesomeIcon icon={faComment} style={{ marginRight: 9, marginLeft: 1 }} />DATA ENTRY</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleShowNewFile(s.id, s.name)}><FontAwesomeIcon icon={faComment} style={{ marginRight: 9, marginLeft: 1 }} />Nuevo comentario</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleShowNewPayment(s.id, s.name)}><FontAwesomeIcon icon={faCashRegister} style={{ marginRight: 10, marginLeft: 2 }} />Nueva factura</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleShowNewEvent(s.id)}><FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: 10, marginLeft: 2 }} />Nuevo evento</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                </table>
                                <Container>
                                    <Row className='mt-4 mb-4 ps-3 pe-3'>
                                        {i == 1 ? step2.map((s, i) => (
                                            <div>{s}</div>
                                        )) : ""}
                                        {i == 2 ? step3.map((s, i) => (
                                            <div>{s}</div>
                                        )) : ""}
                                        {i == 3 ? step4.map((s, i) => (
                                            <div>{s}</div>
                                        )) : ""}
                                        {i == 4 ? step5.map((s, i) => (
                                            <div>{s}</div>
                                        )) : ""}
                                        {i == 5 ? step6.map((s, i) => (
                                            <div>{s}</div>
                                        )) : ""}
                                        {i == 6 ? step7.map((s, i) => (
                                            <div>{s}</div>
                                        )) : ""}
                                        {i == 7 ? step8.map((s, i) => (
                                            <div>{s}</div>
                                        )) : ""}
                                        {i == 8 ? step9.map((s, i) => (
                                            <div>{s}</div>
                                        )) : ""}
                                    </Row>
                                </Container>
                            </Card>
                        }
                    </Row>
                ))}

            </Container>




            <Modal show={showNewFile} centered onHide={handleCloseNewFile}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalNewCommentTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="textNewFileTitle" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Comentario</Form.Text>
                        <Form.Control as="textarea" rows={4} id="textNewFileDescription" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Archivo</Form.Text>
                        <Form.Control accept='.png, .jpg, .jpeg, .pdf, .docx, .doc, .xlsx, .xslx' onChange={(event) => handleNewCommentFile(event)} type="file" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewFile}>
                        Cancelar
                    </Button>

                    <Button variant="danger" onClick={createComment}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showNewBulkFile} centered onHide={handleCloseNewBulkFile}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevos archivos (data entry)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mt-2'>
                        <Form.Text>Archivos</Form.Text>
                        <Form.Control accept='.png, .jpg, .jpeg, .pdf, .docx, .doc, .xlsx, .xslx' multiple onChange={(event) => handleNewBulkCommentFile(event)} type="file" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewBulkFile}>
                        Cancelar
                    </Button>

                    <Button variant="danger" onClick={createBulkComment}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showEditCommentModal} centered onHide={handleCloseEditCommentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar comentario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="textEditCommentTitle" defaultValue={editCommentTitle} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Comentario</Form.Text>
                        <Form.Control as="textarea" rows={4} id="textEditCommentDescription" defaultValue={editCommentDescription} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Archivo</Form.Text>
                        {editCommentFileName ?
                            <div>{editCommentFileName}</div>
                            :
                            <Form.Control accept='.png, .jpg, .jpeg, .pdf, .docx, .doc' onChange={(event) => handleEditCommentFile(event)} type="file" />
                        }
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditCommentModal}>
                        Cancelar
                    </Button>

                    <Button variant="danger" onClick={editComment}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showNewPayment} centered onHide={handleCloseNewPayment}>
                <Modal.Header >
                    <Modal.Title>{modalNewPaymentTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <ButtonGroup>
                            <ToggleButton
                                key={1}
                                id={1}
                                type="radio"
                                variant='outline-success'
                                name="radio"
                                value={"Ingreso"}
                                checked={newPaymentType === "Ingreso"}
                                onChange={(e) => handleNewPaymentType(e.currentTarget.value)}
                            >
                                Ingreso
                            </ToggleButton>

                            <ToggleButton
                                key={2}
                                id={2}
                                type="radio"
                                variant='outline-danger'
                                name="radio"
                                value={"Egreso"}
                                checked={newPaymentType === "Egreso"}
                                onChange={(e) => handleNewPaymentType(e.currentTarget.value)}
                            >
                                Egreso
                            </ToggleButton>
                        </ButtonGroup>
                    </Row>
                    <Row className='mt-2'>
                        <Form.Text>Cliente / Proveedor</Form.Text>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control className='cover content rows element' readOnly type="text" id="business" placeholder="" />
                            <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={() => handleShowSelect("PAYMENT")}><FontAwesomeIcon icon={faSearch} /></Button>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control type="text" id="" placeholder="Monto" onChange={(event) => handleNewPaymentAmount(event)} />
                        </Col>
                        <Col xs={4}>
                            <Form.Control type="text" id="" value="ARS" readOnly />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Form.Text>Conversión a moneda extranjera</Form.Text>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <Form.Control type="text" id="" placeholder="Monto" value={newPaymentExchAmount} onChange={(event) => handleNewPaymentExchAmount(event)} />
                        </Col>
                        <Col xs={1}>
                            @
                        </Col>
                        <Col xs={3}>
                            <Form.Control type="text" id="" placeholder="Cotización" value={newPaymentExchQuotation} onChange={(event) => handleNewPaymentExchQuotation(event)} />
                        </Col>
                        <Col xs={4}>
                            <Form.Select className='' onChange={(event) => handleNewPaymentExchCurrencyId(event)} id="">
                                <option value="0">Seleccionar...</option>
                                {currencies.map((c, i) => (
                                    <option key={i} id={i} value={c.id}>{c.code}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <Col>
                            <Form.Text>Descripción</Form.Text>
                            <Form.Control type="text" id="" placeholder="" onChange={(event) => handleNewPaymentContent(event)} />
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <Col>
                            <Form.Text>Factura</Form.Text>
                            <Form.Control type="text" id="" placeholder="" onChange={(event) => handleNewPaymentInvoiceNumber(event)} />
                        </Col>
                    </Row>
                    <Dropzone className='mt-3' multiple={false} onDrop={file => processReceiptFile(file)}>
                        {({ getRootProps, getInputProps }) => (
                            <section className='dropzone mt-2 pt-5 pb-5'>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Arrastrá el archivo o hacé clic para buscarlo</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewPayment}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={createPayment}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewReceipt} centered onHide={handleCloseNewReceipt}>
                <Modal.Header >
                    <Modal.Title>Nuevo comprobante</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="" placeholder="Número de comprobante" onChange={(event) => handleNewReceiptNum(event)} />
                    <Form.Select className='mt-2' onChange={(event) => handleNewReceiptType(event)} id="">
                        <option value="0">Seleccionar tipo de comprobante...</option>
                        {receiptTypes.map((c, i) => (
                            <option key={i} id={i} value={c.id}>{c.name}</option>
                        ))}
                    </Form.Select>
                    <Form.Control className='mt-2 mb-4' type="text" id="" placeholder="Comentario" onChange={(event) => handleNewReceiptDescription(event)} />
                    <Dropzone multiple={false} onDrop={file => processReceiptFile(file)}>
                        {({ getRootProps, getInputProps }) => (
                            <section className='dropzone pt-5 pb-5'>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Arrastrá el archivo o hacé clic para buscarlo</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewReceipt}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={createReceipt}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewEvent} centered onHide={handleCloseNewEvent}>
                <Modal.Header >
                    <Modal.Title>Crear evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Text className="text-muted">Nombre</Form.Text>
                    <Form.Control className='mb-2' type="text" id="" placeholder="" onChange={(event) => handleNewEventName(event)} />

                    <Form.Text className="text-muted">Descripción</Form.Text>
                    <Form.Control className='mb-2' type="text" id="" placeholder="" onChange={(event) => handleNewEventDescription(event)} />

                    <Form.Text className="text-muted">Fecha de aviso</Form.Text>
                    <DatePicker className='mb-2 form-control' locale="es" dateFormat="dd/MM/yyyy" selected={newEventAlertDate} onChange={(d) => setNewEventAlertDate(d)} />

                    <Form.Text className="text-muted">Fecha de evento</Form.Text>
                    <DatePicker className=' form-control' locale="es" dateFormat="dd/MM/yyyy" selected={newEventDueDate} onChange={(d) => setNewEventDueDate(d)} />

                    <Form.Group className='mt-2'>
                        <Form.Text>Usuarios</Form.Text>
                        {users.map((r, i) => (
                            <Form.Check
                                className='mt-1'
                                type="checkbox"
                                key={i}
                                defaultChecked={parseInt(r.id) === parseInt(getUserId()) ? true : false}
                                id={`checkEventUser-${r.id}`}
                                label={`${r.name} ${r.surname}`}
                            />
                        ))}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewEvent}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={createEvent}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showInvoices} size="lg" centered onHide={handleCloseInvoices}>
                <Modal.Header>
                    <Modal.Title>Documentos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row style={{ marginTop: -17 }}>
                            <Form.Group className="" controlId="exampleForm.ControlInput1">
                                {invoices != undefined ? invoices.map((r, i) => (
                                    <div>{Comment(false, `${r.ReceiptType.name} - ${r.number}`, r.description, true, r.File, r.User, r.createdAt, r.id)}</div>
                                )) : ""}
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseInvoices}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSureDeleteComment} centered onHide={handleCloseSureDeleteComment}>
                <Modal.Header >
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de querer eliminarlo?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSureDeleteComment}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={deleteComment}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewSIRAModal} onHide={handleCloseNewSIRAModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva SIRA</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Fecha de emisión</Form.Text>
                        <DatePicker className='form-control' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newSIRADate} onChange={(d) => handleNewSIRADate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2 mb-2'>
                        <Form.Text>Nº de SIRA</Form.Text>
                        <Form.Control type="text" id="textNewSIRANumber" placeholder="" />
                    </Form.Group>

                    <Form.Text>Despachante</Form.Text>
                    <Row >
                        <Col>

                            <Form.Control type="text" id="business" readOnly />
                        </Col>
                        <Col xs={2}>
                            <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={() => handleShowSelect("SIRA")}><FontAwesomeIcon icon={faSearch} /></Button>
                        </Col>
                    </Row>

                    <Form.Group className='mt-2'>
                        <Form.Text>Oficialización</Form.Text>
                        <DatePicker className='form-control' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newSIRAOficializationDate} onChange={(d) => handleNewSIRAOficializationDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Licencia</Form.Text>
                        <Form.Select id="selectNewSIRALicense" className='' >
                            <option value={"Automática"}>Automática</option>
                            <option value={"No automática"}>No automática</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Vencimiento licencia</Form.Text>
                        <DatePicker className='form-control ' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newSIRALicExpirationDate} onChange={(d) => handleNewSIRALicExpirationDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Vencimiento SIRA</Form.Text>
                        <DatePicker className='form-control' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newSIRAExpirationDate} onChange={(d) => handleNewSIRAExpirationDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Estado</Form.Text>
                        <Form.Control type="text" id="textNewSIRAStatus" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Acceso MLC</Form.Text>
                        <DatePicker className='form-control' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newSIRAAccessMLCDate} onChange={(d) => handleNewSIRAAccessMLCDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Plazo acceso MLC (días)</Form.Text>
                        <Form.Control type="text" id="textNewSIRAMLCDays" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Formulario LNA</Form.Text>
                        <Form.Control type="text" id="textNewSIRALNAForm" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Observaciones</Form.Text>
                        <Form.Control type="text" id="textNewSIRAComments" placeholder="" />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Text>Archivo</Form.Text>
                        <Form.Control accept='.png, .jpg, .jpeg, .pdf, .docx, .doc' onChange={(event) => handleNewSIRAFile(event)} type="file" />
                    </Form.Group>

                    {error}


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewSIRAModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createSIRA}>Crear</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showNewProformaModal} onHide={handleCloseNewProformaModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva Proforma</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Fecha de emisión</Form.Text>
                        <DatePicker className='form-control' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newProformaDate} onChange={(d) => handleNewProformaDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2 mb-2'>
                        <Form.Text>Nº de Proforma</Form.Text>
                        <Form.Control type="text" id="textNewProformaNumber" placeholder="" />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Text>Archivo</Form.Text>
                        <Form.Control accept='.png, .jpg, .jpeg, .pdf, .docx, .doc' onChange={(event) => handleNewProformaFile(event)} type="file" />
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewProformaModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createProforma}>Crear</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showNewCommercialInvoiceModal} onHide={handleCloseNewCommercialInvoiceModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva Commercial Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Fecha de emisión</Form.Text>
                        <DatePicker className='form-control' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newCIDate} onChange={(d) => handleNewCIDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2 mb-2'>
                        <Form.Text>Nº de Commercial Invoice</Form.Text>
                        <Form.Control type="text" id="textNewCINumber" placeholder="" />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Text>Archivo</Form.Text>
                        <Form.Control accept='.png, .jpg, .jpeg, .pdf, .docx, .doc' onChange={(event) => handleNewCIFile(event)} type="file" />
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewCommercialInvoiceModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createCommercialInvoice}>Crear</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showShippingDataModal} onHide={handleCloseShippingDataModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Datos de envío</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Buque</Form.Text>
                        <Form.Control type="text" id="textShippingVessel" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Terminal</Form.Text>
                        <Form.Control type="text" id="textShippingTerminal" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Línea</Form.Text>
                        <Form.Control type="text" id="textShippingVesselLine" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2 mb-2'>
                        <Form.Text>BL / CONT</Form.Text>
                        <Form.Control type="text" id="textShippingComment" placeholder="" />
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseShippingDataModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={editShippingData}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showEditStatusModal} onHide={handleCloseEditStatusModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar estado</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Select className='mt-2' id="selectStatus" >
                       
                        {statuses.map((s, i) => (
                            <option key={i} value={s.id}>{s.name}</option>
                        ))}
                    </Form.Select>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditStatusModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={updateStatus}>Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteEventModal} onHide={handleCloseDeleteEventModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar el evento?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteEventModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteEvent}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditEventModal} centered onHide={handleCloseEditEventModal}>
                <Modal.Header >
                    <Modal.Title>Modificar evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Text className="text-muted">Nombre</Form.Text>
                    <Form.Control className='mb-2' type="text" id="textEditEventName" defaultValue={textEditEventName} />

                    <Form.Text className="text-muted">Descripción</Form.Text>
                    <Form.Control className='mb-2' type="text" id="textEditEventDescription" defaultValue={textEditEventDescription} />

                    <Form.Text className="text-muted">Fecha de aviso</Form.Text>
                    <DatePicker className='mb-2 form-control' locale="es" dateFormat="dd/MM/yyyy" selected={pickerEditEventAlertDate} onChange={(d) => setPickerEditEventAlertDate(new Date(d))} />

                    <Form.Text className="text-muted">Fecha de evento</Form.Text>
                    <DatePicker className=' form-control' locale="es" dateFormat="dd/MM/yyyy" selected={pickerEditEventDueDate} onChange={(d) => setPickerEditEventDueDate(new Date(d))} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditEventModal}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={editEvent}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteOperationModal} onHide={handleCloseDeleteOperationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar la operación?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteOperationModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteOperation}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSelect} size="lg" onHide={handleCloseSelect}>
                <Modal.Header>
                    <Modal.Title>{modalType}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Form.Group className="" >
                                <div className='mb-3' style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='cover content rows element ms-2' type="text" id="businessIdentifier" placeholder="" autoComplete={false} onChange={(event) => handleSearchChain(event)} /></div>
                                <Container>
                                    {providers.map((r, i) => (
                                        <Row key={i} className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                            <Col xs={1} className='align-center-vertically middle'>
                                                <div>
                                                    <p className='m-0'><Badge bg='primary'>{r.identifier}</Badge></p>
                                                </div>
                                            </Col>

                                            <Col xs={9} className='align-center-vertically'>
                                                <div>
                                                    <p className='m-0' style={{ fontSize: 10 }}>{r.legalName}</p>
                                                    <p className='m-0'>{r.name}</p>
                                                </div>
                                            </Col>

                                            <Col xs={2} className='' style={{ textAlign: 'right' }}>
                                                <div>
                                                    {r.action}
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}


                                </Container>
                            </Form.Group>
                        </Row>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSelect}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size='lg' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer document={{
                        base64: actualViewFile
                    }} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewFileModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}