import { params } from "../tools/constants";

export const validateUser = function (){
    const token = localStorage.getItem("userToken");
    if(token !== undefined && token !== null){
        if(token.length > 10){
            //Usuario está logueado
            if(window.location.href !== params.homePage){
                //Estoy en el inicio
                if(getUserRole() == "Cliente"){
                    window.location.href = "/citas";
                }else{
                    window.location.href = "/inicio";
                }
                
            }
            
        }else{
            window.location.href = "/login";
        }
    }else{
        window.location.href = "/login";
    }
}

export const saveUserData = function (user){
    console.log(user)
    localStorage.setItem('userToken', user.token);
    localStorage.setItem('userName', user.name);
    localStorage.setItem('userSurname', user.surname);
    localStorage.setItem('userAvatar', user.avatar);
    localStorage.setItem('userId', user.id);
    //localStorage.setItem('userRole', user.Role.name);
}

export const getUserToken = function (){
    return localStorage.getItem('userToken');
}

export const getUserRole = function (){
    return localStorage.getItem('userRole');
}

export const getUserId = function (){
    return localStorage.getItem('userId');
}

export const logout = function (){
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userRole');
    window.location.href = '/login';
}

export const saveAvatar = function (avatar){
    localStorage.setItem('userAvatar', avatar);
}