import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Col, Card, Badge, Button, Table, Dropdown } from "react-bootstrap";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';

import moment from 'moment'
import 'moment/locale/es-mx';
import { get, post } from '../../controllers/Endpoints';
import { getUserToken } from '../../controllers/UserController';
import { processDateCalendar, fileToBase64, timeDueWithBadge, processDateEvents, timeDue, timeSince } from '../../tools/tools';
import "../../styles/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faEllipsisV, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';

import NavBar from "../navbar/Navbar";


export default function Home() {
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    const [operations, setOperations] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [indicators, setIndicators] = React.useState([]);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto']
            }
        });

        document.title = "GrinBalb - Inicio";


        init();
    }, []);

    const init = function () {
        getEvents();
        getOperations();
        getIndicators();
    }


    const getEvents = async function () {
        const req = await get('/operations/events/', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processEvents(res)
        } else {
            alert(res.message);
        }
    }

    const getIndicators = async function () {
        const req = await get('/indicators/home/', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setIndicators(res)
        } else {
            alert(res.message);
        }
    }

    const processEvents = function (data) {

        setEvents(data);
    }

    const getOperations = async function () {
        const req = await get(`/operations/home`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processOperations(res)
        } else {
            alert(res.message);
        }

    }

    const processOperations = function (operations) {
        let result = [];
        for (let p of operations) {
            p.status = <Badge bg='' className='content-table status' style={{ backgroundColor: `#${p.Status.color}` }}>{p.Status.name}</Badge>
            p.inactividad = timeSince(p.updatedAt)
            p.action = <Link to={`/operaciones/detalles/?id=${p.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></Button></Link>
            result.push(p);
        }
        setOperations(result);
    }


    return (
        <div className='p-0 mt-5' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <Container className=''>
                <Row className='mt-4 mb-4'>
                    {indicators.map((ind, i) => (
                        <Col xs={3}>
                            <Card className={`statusCard ${ind.color}`}>
                                <Card.Body>
                                    <Card.Title>{ind.name}</Card.Title>
                                    <Card.Text>
                                        <p className='m-0' >
                                            <span style={{ fontSize: 28, fontStyle: 'bold' }}>{ind.value}</span>
                                            <span className='ms-1' style={{ fontSize: 13, color: 'gray' }}>{ind.text}</span>
                                        </p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>

                <Row className='mb-5'>
                    <Col>
                        <Card className='cards' style={{ minHeight: 560 }}>
                            <Card.Body>
                                <Card.Title>Eventos</Card.Title>
                                {events.length > 0 ?
                                    <div>
                                        {events.map((e, i) => (
                                            <div className='file element mt-2' >
                                                <Row className="align-items-center">
                                                    <Col xs={2}>
                                                        <p className='m-0' style={{ textWeight: 700 }}>{processDateEvents(e.dueDate)}</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='m-0'>{e.Operation.code} - {e.name}</p>
                                                        <p className='m-0' style={{ fontSize: 12 }}>{e.description}</p>
                                                        <p className='m-0'>{timeDueWithBadge(e.dueDate)}</p>

                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <Row className='mt-5' style={{ height: 300, textAlign: "center" }}>
                                        <p className='' style={{ height: 20, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon></p>
                                        <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay eventos</p>
                                    </Row>
                                }


                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='cards' style={{ minHeight: 560 }}>
                            <Card.Body>
                                <Card.Title>Operaciones</Card.Title>
                                <Card.Text>
                                    <div className='ps-2 pe-2'>
                                        {operations.map((r, i) => (
                                            <Row key={i} className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                                <Col xs={2} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0'><Badge bg='primary'>{r.code}</Badge></p>
                                                    </div>
                                                </Col>

                                                <Col xs={5} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0'>{r.Business.name}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={3} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0'>{r.inactividad}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={2} className='' style={{ textAlign: 'right' }}>
                                                    <div>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))}

                                    </div>
                                </Card.Text>

                            </Card.Body>
                        </Card>


                    </Col>
                </Row>

            </Container>





        </div>
    )
}