import React, { useEffect } from 'react'
import WebFont from 'webfontloader';

// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Table, Badge, Button, Form, DropdownButton, Dropdown, Modal, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShip, faPlusCircle, faSearch, faEye, faFilter, faFile } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post } from '../../controllers/Endpoints';
import { getUserToken, logout } from '../../controllers/UserController';
import { timeSince } from '../../tools/tools';

import "../../styles/styles.css";

export default function Operations() {

    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);

    const [orderTypes, setOrderTypes] = React.useState(["Ordenar por código (A -> Z)", "Ordenar por código (Z -> A)", "Ordenar por cliente (A -> Z)", "Ordenar por cliente (Z -> A)", "Ordenar por inactividad (menor a mayor)", "Ordenar por inactividad (mayor a menor)", "Ordenar por fecha de creación"]);
    const [selectedOrderType, setSelectedOrderType] = React.useState("Ordenar por código (A -> Z)");

    const [selectedStatusOpened, setSelectedStatusOpened] = React.useState(true);
    const [selectedStatusClosed, setSelectedStatusClosed] = React.useState(false);
    const [selectedComplexLow, setSelectedComplexLow] = React.useState(true);
    const [selectedComplexMed, setSelectedComplexMed] = React.useState(true);
    const [selectedComplexHigh, setSelectedComplexHigh] = React.useState(true);

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const handleCloseFilterModal = () => { setShowFilterModal(false); };
    const handleShowFilterModal = () => {
        setShowFilterModal(true);
    };

    const handleOrderType = (event) => {
        setSelectedOrderType(event.target.value);
    };

    const handleStatusOpened = (event) => { setSelectedStatusOpened(event.target.checked) };
    const handleStatusClosed = (event) => { setSelectedStatusClosed(event.target.checked) };
    const handleComplexLow = (event) => { setSelectedComplexLow(event.target.checked) };
    const handleComplexMed = (event) => { setSelectedComplexMed(event.target.checked) };
    const handleComplexHigh = (event) => { setSelectedComplexHigh(event.target.checked) };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = "GrinBalb - Operaciones";
        setupTable();
        handleSearch();
    }, []);

    const setupTable = function () {
        const cols = ['Código', 'Cliente', 'Inactividad', 'Complejidad', 'Estado', ''];
        setTableColumns(cols);
    };

    const processOperations = function (operations) {
        let result = [];
        for (let p of operations) {
            p.status = <Badge bg='' className='content-table status' style={{ backgroundColor: `#${p.Status.color}` }}>{p.Status.name}</Badge>
            p.inactividad = timeSince(p.updatedDate)
            p.action = <Link to={`/operaciones/detalles/?id=${p.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></Button></Link>
            switch (p.complexityId) {
                case 1:
                    p.complexity = <Badge bg='success' className='content-table status'>Baja</Badge>;
                    break;
                case 2:
                    p.complexity = <Badge bg='warning' className='content-table status'>Media</Badge>;
                    break;
                case 3:
                    p.complexity = <Badge bg='danger' className='content-table status'>Alta</Badge>;
                    break;
            }
            result.push(p);
        }
        setTableRows(result);
    }

    const handleSearch = async function () {
        const body = {
            chain: document.getElementById('textSearch').value,
            complexity: {
                low: selectedComplexLow,
                mid: selectedComplexMed,
                high: selectedComplexHigh
            },
            status: {
                opened: selectedStatusOpened,
                closed: selectedStatusClosed
            },
            order: selectedOrderType
        };
        const req = await post(`/operations/search`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            handleCloseFilterModal();
            processOperations(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>Operaciones</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={faShip} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>

                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='ms-2' type="text" id="textSearch" style={{ width: 250, display: 'inline' }} placeholder="" onChange={() => handleSearch()} />
                                    <Button className="ms-2" variant="primary" onClick={handleShowFilterModal}><FontAwesomeIcon icon={faFilter} style={{ marginRight: 8 }} />Filtros</Button>
                                </td>
                                <td className='header rows right'>

                                        <Button href="/reportes/cntr" target="_blank" variant="success"><FontAwesomeIcon icon={faFile} style={{ marginRight: 8 }} />Reporte CNTRs</Button>
                                 
                                    <Link to={'/operaciones/nueva/'}>
                                        <Button variant="success" className='ms-2'><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva operación</Button>
                                    </Link>
                                </td>
                            </tr>
                        </table>

                        {tableRows.length > 0 ?
                            <Container className='pt-3 pb-2'>
                                <Row className='p-2 mb-2' style={{ background: `#39AEDE`, color: 'white', borderRadius: 8 }}>
                                    <Col xs={1} className='align-center-vertically-v2'>
                                        <div>
                                            Cod.
                                        </div>
                                    </Col>

                                    <Col xs={5} className='align-center-vertically-v2' >
                                        <div>
                                            Cliente
                                        </div>
                                    </Col>

                                    <Col xs={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            Inactividad
                                        </div>
                                    </Col>

                                    <Col xs={1} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            Complej.
                                        </div>
                                    </Col>

                                    <Col xs={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            Estado
                                        </div>
                                    </Col>


                                    <Col xs={1} className='align-center-vertically-v2'>
                                        <div>

                                        </div>
                                    </Col>
                                </Row>
                                {tableRows.map((r, i) => (
                                    <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col xs={1} className='align-center-vertically'>
                                            <div>
                                                <p className='m-0'>{r.code ? <Badge bg='primary' className='content-table status'>{r.code}</Badge> : <div></div>}</p>
                                            </div>
                                        </Col>

                                        <Col xs={5} className='align-center-vertically-v2' >
                                            <div>
                                                <p className='m-0'>{r.Business.name}</p>
                                            </div>
                                        </Col>

                                        <Col xs={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                <p className='m-0'>{r.inactividad}</p>
                                            </div>
                                        </Col>

                                        <Col xs={1} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                <p className='m-0'>{r.complexity}</p>
                                            </div>
                                        </Col>

                                        <Col xs={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                <p className='m-0'>{r.status}</p>
                                            </div>
                                        </Col>

                                        <Col xs={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'right' }}>
                                                {r.action}
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faShip}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se han encontrado empresas</p>
                                </Row>
                            </Container>

                        }

                        
                    </Card>
                </Row>
            </Container>

            <Modal show={showFilterModal} onHide={handleCloseFilterModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtros</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Orden<br></br></Form.Text>
                        <Form.Select className='cover content rows element' id="" value={selectedOrderType} onChange={(event) => handleOrderType(event)}>
                            {orderTypes.map((p, i) => (
                                <option key={i} id={i} value={p}>{p}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-3'>
                        <Form.Text>Estado</Form.Text>
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Abierta"
                            checked={selectedStatusOpened}
                            onChange={(event) => handleStatusOpened(event)}
                        />
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Cerrada"
                            checked={selectedStatusClosed}
                            onChange={(event) => handleStatusClosed(event)}
                        />
                    </Form.Group>

                    <Form.Group className='mt-3'>
                        <Form.Text>Complejidad</Form.Text>
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Baja"
                            checked={selectedComplexLow}
                            onChange={(event) => handleComplexLow(event)}
                        />
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Media"
                            checked={selectedComplexMed}
                            onChange={(event) => handleComplexMed(event)}
                        />
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Alta"
                            checked={selectedComplexHigh}
                            onChange={(event) => handleComplexHigh(event)}
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilterModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleSearch}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}