import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShip, faArrowLeft, faCheckCircle, faCalculator, faFile } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import Dropzone from 'react-dropzone'
import { get, post } from '../../controllers/Endpoints'
import "../../styles/styles.css";
import { getUserToken } from '../../controllers/UserController';
import { processDate, fileToBase64, pn, addZero } from '../../tools/tools';


export default function NewPaymentOrderCreated() {

    const [type, setType] = React.useState("");
    const [title, setTitle] = React.useState("");

    const PAGE_TITLE = title;
    const PAGE_ICON = faFile;

    const [poFile, setPoFile] = React.useState('');
    const [poNumber, setPoNumber] = React.useState('');

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        getType();
        init();
    }, []);

    const init = function () {
        window.scrollTo(0, 0);
        getOrder()

    }

    const getOrderId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getType = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const data = urlParams.get('type');
        setType(data);
        let aux = ""
        if (data == "OP") {
            aux = "Nueva orden de pago";
        } else {
            aux = "Nuevo recibo";
        }
        setTitle(aux)
        document.title = `GrinBalb - ${aux}`;
    }

    const getOrder = async () => {
        const req = await get(`/operations/payments/orders/${getOrderId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPoNumber(`#${addZero(res.number, 8)}`)
            setPoFile(res.File.url)
        } else {
            alert(res.message);
        }
    }

    const openOrder = () => {
        window.open(`https://secdevstest.com.ar/grinbalb${poFile}`)
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#28A745' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontWeight: 300 }}>
                                    <p style={{ color: 'white', fontSize: 45 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "white" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <Link to={'/administracion'} className='linkBlue'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Administración
                                    </Link>
                                </td>
                            </tr>
                        </table>

                        <Container>
                            <Row className='mt-5 mb-5' style={{ textAlign: 'center' }}>
                                <Col>
                                    <p style={{ fontSize: 70, color: '#28A745' }}><FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: 8 }} /></p>
                                    <p className='m-0' style={{ fontSize: 20 }}>¡Listo!</p>
                                    <p className='m-0' style={{ fontSize: 20 }}>{type == "R" ? "Recibo creado " : "Orden de pago creada "} correctamente</p>
                                    <p className='mt-4' style={{ fontSize: 40 }}>{poNumber}</p>
                                    <Button variant='primary' onClick={openOrder}>Descargar</Button>
                                </Col>
                            </Row>

                        </Container>

                    </Card>
                </Row>
            </Container>
        </div>
    )
}