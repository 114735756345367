import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Table, Form, DropdownButton, Badge} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserFriends, faPlusCircle, faSearch, faEye} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import {get, post} from '../../controllers/Endpoints'
import "../../styles/styles.css";
import { getUserToken } from '../../controllers/UserController';

export default function Customers(){

    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);


    const handleSearchChain = (event) => {
        const chain = event.target.value;

        if(chain === ""){
            getCustomers();
        }else{
            searchCustomers(chain)
        }
    };
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = "GrinBalb - Clientes";
        setupTable();
        getCustomers();
    }, []);

    const setupTable = function(){
        const cols = ['Código', 'Nombre', 'CUIT', 'Razón social', 'Operaciones', ''];
        setTableColumns(cols);
    };

    const getCustomers = async function(){
        const req = await get('/business/type/CLIENT', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            console.log(res)
            processCustomers(res)
        }else{
            alert(res.message);
        }
    }

    const processCustomers = function(customers){
        let result = [];
        for(let c of customers){
            c.action = <Link to={`/clientes/detalles/?id=${c.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></Button></Link>
            result.push(c);
        }
        setTableRows(result);
    }

    const searchCustomers = async function(c){
        const chain = {
            chain: c
        }

        const req = await post('/business/type/CLIENT/search', getUserToken(), chain);
        const res = await req.json();

        if(req.status === 200){
            processCustomers(res)
        }else{
            alert(res.message);
        }
    }

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                <span style={{color: '#A0A0A0'}}>Clientes</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={faUserFriends} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='cover content rows element ms-2' style={{width: 250}} type="text" id="businessIdentifier" placeholder="" onChange = {(event) => handleSearchChain(event)} />
                                </td>
                                <td className='header rows right'>
                                    <Link to='/clientes/nuevo'>
                                        <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nuevo cliente</Button>
                                    </Link>
                                </td>
                            </tr>
                        </table>
                        




                        <Table striped hover className='mt-3'>
                            <thead>
                                <tr>
                                    {tableColumns.map((c, i) => (
                                        <th key={i} className='opTable head'>{c}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows.map((r, i) => (
                                    <tr key={i} >
                                        <td className='opTable rows align-middle'>{r.identifier}</td>
                                        <td className='opTable rows align-middle'>{r.name}</td>
                                        <td className='opTable rows align-middle'>{r.legalId}</td>
                                        <td className='opTable rows align-middle'>{r.legalName}</td>
                                        <td className='opTable rows align-middle'>{r.Operations.length}</td>
                                        <td className='opTable rows align-middle'>{r.action}</td>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </Table>
                    </Card>
                </Row>
            </Container>
            
        </div>
    )
}