import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Form, Col, Table, Modal, Dropdown, Badge} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends, faAddressBook, faArrowLeft, faPlusCircle, faPhoneAlt, faEnvelope, faMapMarkerAlt, faInfoCircle, faAddressCard, faTrash, faPen, faIndustry, faUser, faCheck, faEllipsisV, faEye, faDownload, faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import {post, get, del, put} from '../../controllers/Endpoints'
import { getUserToken } from '../../controllers/UserController';
import "../../styles/styles.css";
import { pn } from '../../tools/tools';

export default function CustomerDetails(){

    const [businessName, setBusinessName] = React.useState('');
    const [businessIdentifier, setBusinessIdentifier] = React.useState('');
    const [businessLegalName, setBusinessLegalName] = React.useState('');
    const [businessLegalId, setBusinessLegalId] = React.useState('');
    const [businessAddress, setBusinessAddress] = React.useState('');
    const [businessPostalCode, setBusinessPostalCode] = React.useState('');
    const [businessCity, setBusinessCity] = React.useState('');
    const [businessProvince, setBusinessProvince] = React.useState('');
    const [businessCountry, setBusinessCountry] = React.useState('');
    const [businessContacts, setBusinessContacts] = React.useState([]);
    const [tableColumns, setTableColumns] = React.useState([]);
    const [showNewContact, setShowNewContact] = React.useState(false);
    const [showNewContactData, setShowNewContactData] = React.useState(false);
    const [showSureDelete, setShowSureDelete] = React.useState(false);
    const [showSureEditCustomer, setShowSureEditCustomer] = React.useState(false);
    const [newContactName, setNewContactName] = React.useState('');
    const [currentContact, setCurrentContact] = React.useState('');
    const [currentContactName, setCurrentContactName] = React.useState('');
    const [currentContactType, setCurrentContactType] = React.useState('Seleccionar...');
    const [newContactData, setNewContactData] = React.useState('');
    const [countries, setCountries] = React.useState([]);

    const [pendingPayments, setPendingPayments] = React.useState([]);
    const [invoices, setInvoices] = React.useState([]);
    const [showInvoices, setShowInvoices] = React.useState(false);
    const handleCloseInvoices = () => setShowInvoices(false);
    const handleShowInvoices = (i, pid) => {
        //setNewReceiptPayment(pid)
        setInvoices(i)
        setShowInvoices(true);
    }

    const handleCloseNewContact = () => setShowNewContact(false);
    const handleShowNewContact = () => setShowNewContact(true);
    const handleCloseNewContactData = () => setShowNewContactData(false);
    const handleShowNewContactData = (contactId) => {
        setCurrentContact(contactId);
        setShowNewContactData(true);
    };
    const handleCloseSureDelete = () => setShowSureDelete(false);
    const handleShowSureDelete = (name, id) => {
        setCurrentContactName(name);
        setCurrentContact(id);
        setShowSureDelete(true);
    };
    const handleCloseSureEditCustomer = () => setShowSureEditCustomer(false);
    const handleShowSureEditCustomer = () => setShowSureEditCustomer(true);

    const PAGE_TITLE = "Cliente";
    const PAGE_ICON = faUserFriends;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `GrinBalb - ${PAGE_TITLE}`;
        
        getData();
    }, []);

    const getData = async () => {
        await getCountries();
        getCustomer();
        getContacts();
        setupTable();
        getPendingPayments();
    }

    const getCountries = async function () {
        const req = await get('/countries/', getUserToken());
        const res = await req.json();

        console.log(req)

        if (req.status === 200) {
            setCountries(res);
        } else {
            alert(res.message);
        }
    }

    const handleBusinessName = (event) => {
        setBusinessName(event.target.value);
    };

    const handleBusinessIdentifier = (event) => {
        setBusinessIdentifier(event.target.value);
    };

    const handleBusinessLegalName = (event) => {
        setBusinessLegalName(event.target.value);
    };

    const handleBusinessLegalId = (event) => {
        setBusinessLegalId(event.target.value);
    };

    const handleBusinessAddress = (event) => {
        setBusinessAddress(event.target.value);
    };

    const handleBusinessPostalCode = (event) => {
        setBusinessPostalCode(event.target.value);
    };

    const handleBusinessCity = (event) => {
        setBusinessCity(event.target.value);
    };

    const handleBusinessProvince = (event) => {
        setBusinessProvince(event.target.value);
    };

    const handleBusinessCountry = (event) => {
        setBusinessCountry(event.target.value);
    };

    const getCustomer = async function(){
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const businessId = urlParams.get('id');

        const req = await get(`/business/${businessId}`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            console.log(res)
            processCustomer(res)
        }else{
            alert(res.message);
        }
    }

    const getParamId = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('id');
    }

    const processCustomer = function(data){
        document.getElementById('businessName').value = data.name;
        document.getElementById('businessIdentifier').value = data.identifier;
        document.getElementById('businessLegalName').value = data.legalName;
        document.getElementById('businessLegalId').value = data.legalId;
        document.getElementById('businessAddress').value = data.address;
        document.getElementById('businessPostalCode').value = data.postalCode;
        document.getElementById('businessCity').value = data.city;
        document.getElementById('businessProvince').value = data.province;
        document.getElementById('businessCountry').value = data.country;

        document.getElementById('titleBusinessName').innerHTML = data.name;

        setBusinessName(data.name);
        setBusinessIdentifier(data.identifier);
        setBusinessLegalName(data.legalName);
        setBusinessLegalId(data.legalId);
    }

    const handleNewContactName = (event) => {
        setNewContactName(event.target.value);
    };

    const handleNewContactData = (event) => {
        setNewContactData(event.target.value);
    };

    const createContact = async function(){
        setShowNewContact(false);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const businessId = urlParams.get('id');

        const contact = {
            name: newContactName,
        };

        const req = await post(`/business/${businessId}/contact`, getUserToken(), contact);
        const res = await req.json();

        if(req.status === 201){
            getContacts();
        }else{
            alert(res.message);
        }
    }

    const getContacts = async function(){
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const businessId = urlParams.get('id');

        const req = await get(`/business/${businessId}/contact`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            console.log(res)
            processContacts(res)
        }else{
            alert(res.message);
        }
    }

    const processContacts = function(data){
        let result = [];
        let aux;
        let icon;

        for(let c of data){
            aux = null;

            for(let i = 0; i < c.ContactData.length; i++){
                switch(c.ContactData[i].param){
                    case "PHON":
                        icon = <FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon>;
                        break;
                    case "EMAI":
                        icon = <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>;
                        break;
                    case "ADDR":
                        icon = <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>;
                        break;
                    case "POSI":
                        icon = <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>;
                        break;
                    case "OTHE":
                        icon = <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>;
                        break;
                    default:
                        break;
                }

                if(i === 0){
                    aux = <span>{icon} {c.ContactData[i].data}</span>;
                }else{
                    aux = <span>{aux} | {icon} {c.ContactData[i].data}</span>;
                }
            }
            c.data = aux;
            c.action = <span><Button variant="success" onClick={() => handleShowNewContactData(c.id)}><FontAwesomeIcon icon={faAddressCard} style={{marginRight: 8}}></FontAwesomeIcon>Añadir</Button><Button className='ms-1' variant="danger" onClick={() => handleShowSureDelete(c.name, c.id)}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button></span>
            result.push(c);
        }
        setBusinessContacts(result);
    }

    const setupTable = function(){
        const cols = ['Nombre', 'Datos', ''];
        setTableColumns(cols);
    }

    const changeCurrentContactType = function(type){
        setCurrentContactType(type);
        document.getElementById('currentContactType').innerHTML = type;
    }

    const createContactData = async function(){
        setShowNewContactData(false);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const businessId = urlParams.get('id');

        let param;

        switch(currentContactType){
            case "Teléfono":
                param = "PHON";
                break;
            case "Correo electrónico":
                param = "EMAI";
                break;
            case "Dirección":
                param = "ADDR";
                break;
            case "Cargo":
                param = "POSI";
                break;
            case "Otros":
                param = "OTHE";
                break;
            default:
                break;
        }

        const contact = {
            param: param,
            data: newContactData
        };

        const req = await post(`/business/${businessId}/contact/${currentContact}`, getUserToken(), contact);
        const res = await req.json();

        if(req.status === 201){
            getContacts();
        }else{
            alert(res.message);
        }
    }

    const deleteContact = async function(){
        setShowSureDelete(false);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const businessId = urlParams.get('id');

        const req = await del(`/business/${businessId}/contact/${currentContact}`, getUserToken());
        const res = await req.json();

        if(req.status === 201){
            console.log(res)
            getContacts();
        }else{
            alert(res.message);
        }
    }

    const editCustomer = async function(){
        setShowSureEditCustomer(false);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const businessId = urlParams.get('id');

        const customer = {
            name: businessName,
            legalName: businessLegalName,
            legalId: businessLegalId,
            identifier: businessIdentifier,
            businessType: 'CLIENT'
        };

        const req = await put(`/business/${businessId}`, getUserToken(), customer);
        const res = await req.json();

        if(req.status === 201){
            getCustomer();
        }else{
            alert(res.message);
        }
    }

    const getPendingPayments = async function () {
        const req = await get(`/operations/payments/pendingByBusiness/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processPayments(res)
            console.log(res)
        } else {
            alert(res.message);
        }
    }

    const processPayments = function (data) {
        let result = [];
        let result2 = [];
        let isFirst = true;
        let aux1, aux2, aux3, aux4, aux5, aux6;

        for (let s of data) {
            //Its a payment
            aux1 = `${pn(s.amount)} ${s.currencyAmount.code}`;
            if(s.exchAmount){
                aux2 = `${pn(s.exchAmount)} ${s.currencyExchange.code}`;
            }else{
                aux2 = "";
            }
            
            aux3 = <Badge bg={s.Status.color}>{s.Status.name}</Badge>
            aux4 = s.Receipts[0]
            aux5 = s.Receipts
            aux6 = `${pn(s.leftAmount)}`
            result.push(Payment(s.income, aux1, aux2, s.description, null, s.createdAt, s.id, s.Receipts, s.Business.legalName, aux3, aux4.number, aux5, aux6))

            isFirst = false;
        }
    
        setPendingPayments(result)
       
   
    }

    const Payment = (income, amount, exchange, description, user, date, paymentId, receipts, business, status, invoice, invoices, left) => (
        <div className={`file element `}>
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>Factura {invoice} - {description}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{income ? <Badge bg='success'>Ingreso</Badge> : <Badge bg='danger'>Egreso</Badge>} - {status} - {business}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{amount} - {exchange} - Saldo: {left} ARS</p>

                    </td>
                    <td>
                        {fileExtIcon(receipts[0].File.url)}
                    </td>
                    <td className='w-100'>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleShowInvoices(invoices, paymentId)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver documentos</Dropdown.Item>
                                <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            </table>
        </div>
    )

    const fileExtIcon = function (ext) {
        if (ext.includes(".pdf")) {
            return (<FontAwesomeIcon icon={faFilePdf} style={{ marginRight: 20, color: '#DC3545', fontSize: 30 }} />)
        }
        if (ext.includes(".xlsx")) {
            return (<FontAwesomeIcon icon={faFileExcel} style={{ marginRight: 20, color: '#28A745', fontSize: 30 }} />)
        }
    }

    const viewFile = async function (fileId) {
        const req = await get(`/files/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            //setShowFile(true);
            //showFileData(res.data);

            const linkSource = res.data;
            const downloadLink = document.createElement("a");
            const fileName = "demo.pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

        } else {
            alert(res.message);
        }
    }


    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                    <p style={{fontSize: 20}} className='text-muted mb-0'>{PAGE_TITLE}</p>
                                    <p style={{color: '#A0A0A0', fontSize: 45}} id="titleBusinessName"></p>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <Link to={'/clientes'} className='linkBlue'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Clientes
                                    </Link>
                                    <span className='ms-3' style={{color: '#A0A0A0'}}>|</span><span className='ms-3'>Datos generales</span>
                                </td>

                                <td className='header rows right'>
                                    <Button variant="warning" onClick={handleShowSureEditCustomer}><FontAwesomeIcon icon={faPen} style={{marginRight: 8}} />Editar</Button>
                                </td>
                                
                            </tr>
                        </table>
                        
                        <Row className='ms-4 me-4 mt-5'>
                            <Col xs={2} className='cover content rows title'>
                                Nombre
                            </Col>
                            <Col> 
                                <Form.Control className='cover content rows element' type="text" id="businessName" placeholder="" onChange = {(event) => handleBusinessName(event)} />
                            </Col>
                            <Col>
                                
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Identificador
                            </Col>
                            <Col> 
                                <Form.Control className='cover content rows element' type="text" id="businessIdentifier" placeholder="" onChange = {(event) => handleBusinessIdentifier(event)} />
                            </Col>
                            <Col>
                                
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Razón social
                            </Col>
                            <Col> 
                                <Form.Control className='cover content rows element' type="text" id="businessLegalName" placeholder="" onChange = {(event) => handleBusinessLegalName(event)}/>
                            </Col>
                            <Col>
                                
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                CUIT / Tax ID
                            </Col>
                            <Col> 
                                <Form.Control className='cover content rows element' type="text" id="businessLegalId" placeholder="" onChange = {(event) => handleBusinessLegalId(event)}/>
                            </Col>
                            <Col>
                                
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Dirección
                            </Col>
                            <Col> 
                                <Form.Control className='cover content rows element' type="text" id="businessAddress" placeholder="" onChange = {(event) => handleBusinessAddress(event)}/>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Código postal
                            </Col>
                            <Col> 
                                <Form.Control className='cover content rows element w-25' type="text" id="businessPostalCode" placeholder="" onChange = {(event) => handleBusinessPostalCode(event)}/>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Localidad
                            </Col>
                            <Col> 
                                <Form.Control className='cover content rows element' type="text" id="businessCity" placeholder="" onChange = {(event) => handleBusinessCity(event)}/>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Provincia
                            </Col>
                            <Col> 
                                <Form.Control className='cover content rows element' type="text" id="businessProvince" placeholder="" onChange = {(event) => handleBusinessProvince(event)}/>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3 mb-5'>
                            <Col xs={2} className='cover content rows title'>
                                País
                            </Col>
                            <Col> 
                            <Form.Select className='' id="businessCountry">
                                    <option value={"0"}>Seleccionar...</option>
                                    {countries.map((c, i) => (
                                        <option key={i} id={i} value={c.name}>{c.name} ({c.code})</option>
                                    ))}
                                </Form.Select>
                                
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </Card>
                </Row>
            </Container>

            <Container>
                <Row style={{marginTop: -20}}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>

                                    <span>Contactos</span>
                                </td>
                                <td className='header rows right'>
                                    <Button variant="success" onClick={handleShowNewContact}><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nuevo contacto</Button>
                                </td>
                                
                            </tr>
                        </table>
                        
                        {businessContacts.length > 0 ? 
                            <Table striped hover className='mt-3'>
                                <thead>
                                    <tr>
                                        {tableColumns.map((c, i) => (
                                            <th key={i} className='opTable head'>{c}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {businessContacts.map((r, i) => (
                                        <tr key={i} >
                                            <td className='ps-4 align-middle'>{r.name}</td>
                                            <td className='align-middle'>{r.data}</td>
                                            <td className='pe-4 row-right align-middle'>{r.action}</td>
                                        </tr>
                                    ))}
                                    
                                </tbody>
                            </Table>

                        : 
                            <Row style={{height: 300, textAlign: "center"}}>
                                <p className='' style={{height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50}}><FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon></p>
                                <p className='m-0' style={{fontSize: 20, color: "#A0A0A0"}}>No hay contactos</p>
                            </Row>
                        }
                        
                        <Modal show={showNewContact} centered onHide={handleCloseNewContact}>
                            <Modal.Header closeButtonNewContact>
                                <Modal.Title>Nuevo contacto</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <Form>
                                <Row>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Nombre y apellido</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Juan Pérez"
                                        autoFocus
                                        onChange = {(event) => handleNewContactName(event)}
                                    />
                                </Form.Group>
                                </Row>
                       
                                
                            </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseNewContact}>
                                    Cancelar
                                </Button>
                                <Button variant="success" onClick={createContact}>
                                    Confirmar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        
                        <Modal show={showNewContactData} centered onHide={handleCloseNewContactData}>
                            <Modal.Header closeButtonNewContact>
                                <Modal.Title>Añadir datos de contacto</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <Form>
                                <Row>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Row>
                                        <Col xs={5}>
                                        <Dropdown className='w-100'>
                                            <Dropdown.Toggle variant="primary" className='w-100' id="dropdown-basic">
                                                <span id='currentContactType'>Seleccionar...</span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='w-100'>
                                                <Dropdown.Item onClick={() => changeCurrentContactType("Telefono")}>Teléfono</Dropdown.Item>
                                                <Dropdown.Item onClick={() => changeCurrentContactType("Correo electrónico")}>Correo electrónico</Dropdown.Item>
                                                <Dropdown.Item onClick={() => changeCurrentContactType("Dirección")}>Dirección</Dropdown.Item>
                                                <Dropdown.Item onClick={() => changeCurrentContactType("Cargo")}>Cargo</Dropdown.Item>
                                                <Dropdown.Item onClick={() => changeCurrentContactType("Otro")}>Otro</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        </Col>
                                        
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                autoFocus
                                                onChange = {(event) => handleNewContactData(event)}
                                            />
                                        </Col>
                                    </Row>
                                    
                                </Form.Group>
                                </Row>
                       
                                
                            </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseNewContactData}>
                                    Cancelar
                                </Button>
                                <Button variant="success" onClick={createContactData}>
                                    Confirmar
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showSureDelete} centered onHide={handleCloseSureDelete}>
                            <Modal.Header closeButtonSureDelete>
                                <Modal.Title>Eliminar contacto</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                    ¿Está seguro de eliminar al contacto {currentContactName}?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseSureDelete}>
                                    Cancelar
                                </Button>
                                <Button variant="success" onClick={deleteContact}>
                                    Confirmar
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showSureEditCustomer} centered onHide={handleCloseSureEditCustomer}>
                            <Modal.Header closeButtonSureEditCustomer>
                                <Modal.Title>Editar cliente</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                    ¿Está seguro de editar los datos de {businessName}?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseSureEditCustomer}>
                                    Cancelar
                                </Button>
                                <Button variant="success" onClick={editCustomer}>
                                    Confirmar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Card>
                </Row>
            </Container>

            <Container>
                <Row style={{ marginTop: -20 }}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>

                                    <span>Facturas pendientes de pago</span>
                                </td>
                                <td className='header rows right'>
                                   
                                </td>

                            </tr>
                        </table>

                        <Container className='mt-3 mb-4'>
                            {pendingPayments.map((r, i) => (
                                <div className='mt-2'>
                                    {r ? r : ""}
                                </div>
                            ))}
                        </Container>


                    </Card>
                </Row>
            </Container>
            
        </div>
    )
}
