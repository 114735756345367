import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Form, Dropdown, Badge, Modal, Col, ToggleButton, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator, faEllipsisV, faEye, faIndustry, faPlusCircle, faSearch, faTrash, faFilePdf, faFileExcel, faDownload } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import Dropzone from 'react-dropzone'
import { get, post } from '../../controllers/Endpoints'
import "../../styles/styles.css";
import { getUserToken } from '../../controllers/UserController';
import { processDate, fileToBase64, pn, addZero } from '../../tools/tools';

export default function Administration() {

    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);

    const [currencies, setCurrencies] = React.useState([]);
    const [receiptTypes, setReceiptTypes] = React.useState([]);
    const [selectedBusiness, setSelectedBusiness] = React.useState(null);

    const [currentAccount, setCurrentAccount] = React.useState([]);
    const [currentAccountIndicators, setCurrentAccountIndicators] = React.useState([]);

    const [newReceiptNum, setNewReceiptNum] = React.useState("");
    const [newReceiptType, setNewReceiptType] = React.useState(0);
    const [newReceiptDescription, setNewReceiptDescription] = React.useState("");
    const [newReceiptFile, setNewReceiptFile] = React.useState(null);
    const [showNewReceipt, setShowNewReceipt] = React.useState(false);
    const [newReceiptPayment, setNewReceiptPayment] = React.useState(0);
    const [modalData, setModalData] = React.useState([]);
    const [selectableInvoices, setSelectableInvoices] = React.useState([]);
    const [invoices, setInvoices] = React.useState([]);
    const [modalSearchChain, setModalSearchChain] = React.useState("");
    const [modalTableHeader, setModalTableHeader] = React.useState([]);

    const [paymentOrders, setPaymentOrders] = React.useState([]);
    const [receipts, setReceipts] = React.useState([]);

    const [selectedInvoices, setSelectedInvoices] = React.useState([]);
    const [selectedInvoicesIds, setSelectedInvoicesIds] = React.useState([]);
    const [showInvoices, setShowInvoices] = React.useState(false);

    const [showSelect, setShowSelect] = React.useState(false);
    const handleCloseSelect = () => setShowSelect(false);
    const handleShowSelect = () => setShowSelect(true);

    const [showNewPaymentOrder, setShowNewPaymentOrder] = React.useState(false);
    const handleCloseNewPaymentOrder = () => setShowNewPaymentOrder(false);
    const handleShowNewPaymentOrder = () => setShowNewPaymentOrder(true);

    const [showInvoicesSelector, setShowInvoicesSelector] = React.useState(false);
    const handleCloseInvoicesSelector = () => setShowInvoicesSelector(false);
    const handleShowInvoicesSelector = () => setShowInvoicesSelector(true);

    const handleShowInvoices = (i, pid) => {
        //setNewReceiptPayment(pid)
        setInvoices(i)
        setShowInvoices(true);
    }

    const [modalNewPaymentTitle, setModalNewPaymentTitle] = React.useState("Nueva factura");
    const [newPaymentAmount, setNewPaymentAmount] = React.useState("");
    const [newPaymentCurrencyId, setNewPaymentCurrencyId] = React.useState(0);
    const [newPaymentExchAmount, setNewPaymentExchAmount] = React.useState(null);
    const [newPaymentExchQuotation, setNewPaymentExchQuotation] = React.useState(null);
    const [newPaymentExchCurrencyId, setNewPaymentExchCurrencyId] = React.useState(null);
    const [newPaymentContent, setNewPaymentContent] = React.useState("");
    const [newPaymentInvoiceNumber, setNewPaymentInvoiceNumber] = React.useState(null);
    const [newPaymentType, setNewPaymentType] = React.useState("Ingreso");
    const [showNewPayment, setShowNewPayment] = React.useState(false);
    const [newPaymentStep, setNewPaymentStep] = React.useState(0);

    const handleCloseNewPayment = () => setShowNewPayment(false);

    const handleNewPaymentAmount = (event) => {
        setNewPaymentAmount(event.target.value);
    };

    const handleNewPaymentCurrencyId = (event) => {
        setNewPaymentCurrencyId(event.target.value);
    };

    const handleNewPaymentExchAmount = (event) => {
        setNewPaymentExchAmount(event.target.value);
    };

    const handleNewPaymentExchQuotation = (event) => {
        setNewPaymentExchQuotation(event.target.value);
    };

    const handleNewPaymentExchCurrencyId = (event) => {
        setNewPaymentExchCurrencyId(event.target.value);
        getExchange(event.target.value);
    };

    const handleNewPaymentContent = (event) => {
        setNewPaymentContent(event.target.value);
    };

    const handleNewPaymentType = (event) => {
        setNewPaymentType(event);
    };

    const handleNewPaymentInvoiceNumber = (event) => {
        setNewPaymentInvoiceNumber(event.target.value);
    };

    const handleCloseInvoices = () => setShowInvoices(false);

    const handleShowNewReceipt = () => {
        handleCloseInvoices()
        setNewReceiptPayment();
        setShowNewReceipt(true);
    }

    const handleCloseNewReceipt = () => setShowNewReceipt(false);

    const handleNewReceiptNum = (event) => {
        setNewReceiptNum(event.target.value);
    };

    const handleNewReceiptDescription = (event) => {
        setNewReceiptDescription(event.target.value);
    };

    const handleNewReceiptType = (event) => {
        setNewReceiptType(event.target.value);
    };

    const handleShowNewPayment = (step, stepName) => {
        setModalNewPaymentTitle(`Nueva factura`)
        setNewPaymentStep(step);
        setShowNewPayment(true);
    }

    const [error, setError] = React.useState(<div></div>);
    const [newOperationDate, setNewOperationDate] = React.useState(new Date());
    const [newOperationFile, setNewOperationFile] = React.useState(null);
    const [newOperationCategory, setNewOperationCategory] = React.useState("0");
    const [showNewOperationModal, setShowNewOperationModal] = React.useState(false);
    const handleCloseNewOperationModal = () => { setShowNewOperationModal(false); };
    const handleShowNewOperationModal = () => {
        setError()
        setShowNewOperationModal(true);
    };
    const [newOperationIsCredit, setNewOperationIsCredit] = React.useState(false);

    const PAGE_TITLE = "Administración";
    const PAGE_ICON = faCalculator;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `GrinBalb - ${PAGE_TITLE}`;
        setupTable();
        getPendingPayments();
        getCurrencies();
        getPaymentOrders();
    }, []);

    const fileExtIcon = function (ext) {
        if (ext.includes(".pdf")) {
            return (<FontAwesomeIcon icon={faFilePdf} style={{ marginRight: 20, color: '#DC3545', fontSize: 30 }} />)
        }
        if (ext.includes(".xlsx")) {
            return (<FontAwesomeIcon icon={faFileExcel} style={{ marginRight: 20, color: '#28A745', fontSize: 30 }} />)
        }
    }

    const viewFile = async function (fileId) {
        const req = await get(`/files/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            //setShowFile(true);
            //showFileData(res.data);

            const linkSource = res.data;
            const downloadLink = document.createElement("a");
            const fileName = "demo.pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

        } else {
            alert(res.message);
        }
    }

    const getExchange = async function (currency) {
        const req = await get(`/exchange/${currency}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setNewPaymentExchQuotation(res.exchange)
            if (newPaymentAmount != null) {
                if (newPaymentAmount.length > 0) {
                    setNewPaymentExchAmount((newPaymentAmount / res.exchange).toFixed(2))
                }
            }
        } else {
            alert(res.message);
        }
    }

    const createPayment = async function () {
        const payment = {
            amount: newPaymentAmount,
            currencyId: newPaymentCurrencyId,
            exchAmount: newPaymentExchAmount,
            exchQuotation: newPaymentExchQuotation,
            exchCurrencyId: newPaymentExchCurrencyId,
            description: newPaymentContent,
            income: newPaymentType == "Ingreso" ? true : false,
            stepId: newPaymentStep,
            operationId: null,
            businessId: selectedBusiness,
            file: newReceiptFile,
            invoiceNumber: newPaymentInvoiceNumber
        }
        console.log(payment)

        const req = await post(`/operations/payment`, getUserToken(), payment);
        const res = await req.json();

        if (req.status === 200) {
            setShowNewPayment(false);
        } else {
            alert(res.message);
        }
    }

    const setupTable = function () {
        const cols = ['Fecha', 'Descripción', 'Operación', 'Debe', 'Haber', 'Documentos', ''];
        setTableColumns(cols);
    };

    const getPendingPayments = async function () {
        const req = await get('/operations/payments/pending', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processPayments(res)
            console.log(res)
        } else {
            alert(res.message);
        }
    }

    const processPayments = function (data) {
        let result = [];
        let result2 = [];
        let isFirst = true;
        let aux1, aux2, aux3, aux4, aux5, aux6;

        for (let s of data) {
            //Its a payment
            aux1 = `${pn(s.amount)} ${s.currencyAmount.code}`;
            if(s.exchAmount){
                aux2 = `${pn(s.exchAmount)} ${s.currencyExchange.code}`;
            }else{
                aux2 = "";
            }
            
            aux3 = <Badge bg={s.Status.color}>{s.Status.name}</Badge>
            aux4 = s.Receipts[0]
            aux5 = s.Receipts
            aux6 = `${pn(s.leftAmount)}`
            result.push(Payment(s.income, aux1, aux2, s.description, null, s.createdAt, s.id, s.Receipts, s.Business.legalName, aux3, aux4.number, aux5, aux6))

            isFirst = false;
        }
        isFirst = true;
        for (let s of data) {
            //Its a payment
            aux1 = `${s.amount} ${s.currencyAmount.code}`;
            if(s.exchAmount){
                aux2 = `${pn(s.exchAmount)} ${s.currencyExchange.code}`;
            }else{
                aux2 = "";
            }
            aux3 = <Badge bg={s.Status.color}>{s.Status.name}</Badge>
            aux4 = s.Receipts[0]
            aux5 = s.Receipts
            result2.push(SelectablePayment(s.income, aux1, aux2, s.description, null, s.createdAt, s.id, s.Receipts, s.Business.legalName, aux3, aux4.number, aux5))

            isFirst = false;
        }

        setTableRows(result)
        setSelectableInvoices(result2)
   
    }

    const getPaymentOrders = async function () {
        const req = await get('/operations/payments/orders/all', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            //processPayments(res)
            processPaymentOrders(res)
            console.log(res)
        } else {
            alert(res.message);
        }
    }

    /*const processPaymentOrders = function (data) {
        let result = [];
        let result2 = [];
        let isFirst = true;
        let aux1, aux2, aux3, aux4, aux5, aux6;

        for (let s of data) {
            //Its a payment
            aux1 = `${pn(s.amount)} ${s.currencyAmount.code}`;
            aux2 = `${pn(s.exchAmount)} ${s.currencyExchange.code}`;
            aux3 = <Badge bg={s.Status.color}>{s.Status.name}</Badge>
            aux4 = s.Receipts[0]
            aux5 = s.Receipts
            aux6 = `${pn(s.leftAmount)}`
            result.push(Payment(s.income, aux1, aux2, s.description, null, s.createdAt, s.id, s.Receipts, s.Business.legalName, aux3, aux4.number, aux5, aux6))

            isFirst = false;
        }


        setPaymentOrders(result)
   
    }*/

    const downloadOrder = (url) => {
        window.open(`https://secdevstest.com.ar/grinbalb${url}`)
    }

    const processPaymentOrders = function (data) {
        let result = [];
        let po = [];
        let r = [];
        let isFirst = true;
        let isFirstPo = true;
        let isFirstR = true;
        let aux1, aux2, aux3, aux4, aux5;

        for (let s of data) {
            //Its a payment
            if(s.isReceipt){
                aux3 = <Badge bg={s.Status.color}>{s.Status.name}</Badge>

                r.push(PaymentOrder(addZero(s.number, 8), pn(s.amount), s.User, s.createdAt, s.Business.legalName, aux3, s.File.url, true))
    
                isFirstR = false;
            }else{
                aux3 = <Badge bg={s.Status.color}>{s.Status.name}</Badge>

            po.push(PaymentOrder(addZero(s.number, 8), pn(s.amount), s.User, s.createdAt, s.Business.legalName, aux3, s.File.url, false))

            isFirstPo = false;
            }
            
            
        }

        setPaymentOrders(po)
        setReceipts(r)
    }


    const Comment = (isFirst, title, content, isFile, file, user, date, commentId) => (
        <div className={`file element ${isFirst ? 'mt-0' : 'mt-3'}`}>
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>{title}</p>
                        <p className='m-0' style={{ fontSize: 12 }}>{content}</p>
                        <p className='mt-3 mb-0' style={{ fontSize: 12 }}><img className='me-1' style={{ width: 20, borderRadius: "50%" }} src="https://secdevs.com.ar/cbimage.png" alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{user.name} {user.surname} @ {processDate(date)}</span></p>
                    </td>
                    <td>
                        {isFile ? fileExtIcon(file.url) : ""}
                    </td>
                    <td className='w-100'>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {isFile ? <Dropdown.Item onClick={() => viewFile(file.id)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver</Dropdown.Item> : ''}
                                {isFile ? <Dropdown.Item><FontAwesomeIcon icon={faDownload} style={{ marginRight: 9, marginLeft: 1 }} />Descargar</Dropdown.Item> : ''}
                                <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            </table>
        </div>
    )

    const Payment = (income, amount, exchange, description, user, date, paymentId, receipts, business, status, invoice, invoices, left) => (
        <div className={`file element `}>
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>Factura {invoice} - {description}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{income ? <Badge bg='success'>Ingreso</Badge> : <Badge bg='danger'>Egreso</Badge>} - {status} - {business}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{amount} - {exchange} - Saldo: {left} ARS</p>

                    </td>
                    <td>
                        {fileExtIcon(receipts[0].File.url)}
                    </td>
                    <td className='w-100'>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleShowInvoices(invoices, paymentId)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver documentos</Dropdown.Item>
                                <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            </table>
        </div>
    )

    const PaymentOrder = (number, amount, user, date, business, status, url, isReceipt) => (
        <div className={`file element `}>
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>{isReceipt ? "Recibo " : "Orden de pago "}#{number}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{business} - {amount} ARS</p>
                        <p className='mt-1 mb-0' style={{ fontSize: 12 }}><img className='me-1' style={{ width: 20, borderRadius: "50%" }} src="https://secdevs.com.ar/cbimage.png" alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{user.name} {user.surname} @ {processDate(date)}</span></p>
                    </td>

                    <td className='w-100'>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => downloadOrder(url)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver documento</Dropdown.Item>
                                <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            </table>
        </div>
    )

    const SelectablePayment = (income, amount, exchange, description, user, date, paymentId, receipts, business, status, invoice, invoices) => (
        <div className={`file element `}>
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>{income ? "Cobro" : "Pago"} - Factura {invoice} - {description}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{status} - {business}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{amount} - {exchange}</p>

                    </td>

                    <td className='w-100'>
                        <Button onClick={() => selectInvoice(paymentId)}>Seleccionar</Button>
                    </td>
                </tr>
            </table>
        </div>
    )

    const SelectedPayment = (income, amount, exchange, description, user, date, paymentId, receipts, business, status, invoice, invoices) => (
        <div className={`file element `}>
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>{income ? "Cobro" : "Pago"} - Factura {invoice} - {description}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{status} - {business}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{amount} - {exchange}</p>

                    </td>

                    <td className='w-100'>
                        <Button onClick={() => selectInvoice(paymentId)}>Eliminar</Button>
                    </td>
                </tr>
            </table>
        </div>
    )

    const getCurrencies = async function () {
        const req = await get('/currencies/', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setCurrencies(res);
            console.log(res)
        } else {
            alert(res.message);
        }
    }

    const showSelector = () => {
        getBusiness();
        setShowSelect(true);
    };

    const selectInvoice = (id) => {
        let aux = selectedInvoices;
        aux.push(id)
        setSelectedInvoicesIds(aux)
       
    };

    
    const getBusiness = async function () {
        const req = await get('/business/type/ALL', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processBusiness(res)
        } else {
            alert(res.message);
        }
    }

    const handleSearchChain = (event) => {
        const chain = event.target.value;

        if (chain === "") {
            getBusiness();
        } else {
            searchBusiness(chain)
        }
    };

    const searchBusiness = async function (c) {
        const chain = {
            chain: c
        }

        const req = await post('/business/type/ALL/search', getUserToken(), chain);
        const res = await req.json();

        if (req.status === 200) {
            processBusiness(res)
        } else {
            alert(res.message);
        }
    }

    const processBusiness = function (providers) {
        const cols = ['Código', 'Nombre', 'CUIT', 'Razón social', ''];
        setModalTableHeader(cols);

        let result = [];
        for (let p of providers) {
            p.action = <Button className='buttonBlue' variant="primary" onClick={() => confirmBusiness(p.id, p.legalName, false)}>Seleccionar</Button>
            result.push(p);
        }

        setModalData(
            <Table striped hover>
                <thead>
                    <tr>
                        {cols.map((c, i) => (
                            <th key={i} className='opTable head'>{c}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {result.map((r, i) => (
                        <tr key={i} >
                            <td className='opTable rows align-middle'>{r.identifier}</td>
                            <td className='opTable rows align-middle'>{r.name}</td>
                            <td className='opTable rows align-middle'>{r.legalId}</td>
                            <td className='opTable rows align-middle'>{r.legalName}</td>
                            <td className='opTable rows align-middle'>{r.action}</td>
                        </tr>
                    ))}

                </tbody>
            </Table>
        )
    }

    const confirmBusiness = function (id, data, contact) {
        setShowSelect(false);
        document.getElementById('business').value = data;
        setSelectedBusiness(id);
    }

    const createReceipt = async function () {
        const receipt = {
            file: newReceiptFile,
            number: newReceiptNum,
            description: newReceiptDescription,
            receiptTypeId: newReceiptType,
            paymentId: newReceiptPayment,
        }
        console.log(receipt)

        const req = await post(`/operations/payment/receipt`, getUserToken(), receipt);
        const res = await req.json();

        if (req.status === 200) {
            setShowNewReceipt(false);
            getPendingPayments();
        } else {
            alert(res.message);
        }
    }

    const getReceiptTypes = async function () {
        const req = await get('/receipts/types/', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setReceiptTypes(res);
        } else {
            alert(res.message);
        }
    }

    const processReceiptFile = async function (file) {
        const result = await fileToBase64(file[0]);
        setNewReceiptFile({
            data: result,
            name: file[0].name,
            extension: file[0].name.split(".")[(file[0].name.split(".").length) - 1]
        });
        /*setFile1Alert(
            <Alert variant='success'>
                {file[0].name}
            </Alert>
        );*/

    }



    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    Facturas pendientes <span className='ps-2 pe-2'>|</span> <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='cover content rows element ms-2' style={{ width: 250 }} type="text" id="businessIdentifier" placeholder="" />
                                </td>
                                <td className='header rows right'>
                                    <Button variant="success" onClick={() => handleShowNewPayment(null, null)}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva factura</Button>
                                    <Link to='/administracion/ordendepago/nueva/1/?type=OP'><Button variant="success" className='ms-2'><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva orden de pago</Button></Link>
                                    <Link to='/administracion/ordendepago/nueva/1/?type=R'><Button variant="success" className='ms-2'><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo recibo</Button></Link>
                                </td>
                            </tr>
                        </table>

                        <Container className='mt-3 mb-4'>
                            {tableRows.map((r, i) => (
                                <div className='mt-2'>
                                    {r ? r : ""}
                                </div>
                            ))}
                        </Container>
                    </Card>
                </Row>

                <Row >
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    Ordenes de pago <span className='ps-2 pe-2'>|</span> <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='cover content rows element ms-2' style={{ width: 250 }} type="text" id="businessIdentifier" placeholder="" />
                                </td>
                                <td className='header rows right'>
                                    
                                </td>
                            </tr>
                        </table>

                        <Container className='mt-3 mb-4'>
                            {paymentOrders.map((r, i) => (
                                <div className='mt-2'>
                                    {r ? r : ""}
                                </div>
                            ))}
                        </Container>
                    </Card>
                </Row>

                <Row >
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    Recibos <span className='ps-2 pe-2'>|</span> <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='cover content rows element ms-2' style={{ width: 250 }} type="text" id="businessIdentifier" placeholder="" />
                                </td>
                                <td className='header rows right'>
                                    
                                </td>
                            </tr>
                        </table>

                        <Container className='mt-3 mb-4'>
                            {receipts.map((r, i) => (
                                <div className='mt-2'>
                                    {r ? r : ""}
                                </div>
                            ))}
                        </Container>
                    </Card>
                </Row>

               
            </Container>

     

            <Modal show={showNewReceipt} centered onHide={handleCloseNewReceipt}>
                <Modal.Header >
                    <Modal.Title>Nuevo comprobante</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="" placeholder="Número de comprobante" onChange={(event) => handleNewReceiptNum(event)} />
                    <Form.Select className='mt-2' onChange={(event) => handleNewReceiptType(event)} id="">
                        <option value="0">Seleccionar tipo de comprobante...</option>
                        {receiptTypes.map((c, i) => (
                            <option key={i} id={i} value={c.id}>{c.name}</option>
                        ))}
                    </Form.Select>
                    <Form.Control className='mt-2 mb-4' type="text" id="" placeholder="Comentario" onChange={(event) => handleNewReceiptDescription(event)} />
                    <Dropzone multiple={false} onDrop={file => processReceiptFile(file)}>
                        {({ getRootProps, getInputProps }) => (
                            <section className='dropzone pt-5 pb-5'>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Arrastrá el archivo o hacé clic para buscarlo</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary">
                        Volver
                    </Button>
                    <Button variant="success" onClick={createReceipt}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSelect} size="lg" centered onHide={handleCloseSelect}>
                <Modal.Header>
                    <Modal.Title>Seleccionar cliente / proveedor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Form.Group className="" controlId="exampleForm.ControlInput1">
                                <div className='mb-3'><FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='cover content rows element ms-2' type="text" id="businessIdentifier" placeholder="" onChange={(event) => handleSearchChain(event)} /></div>
                                {modalData}
                            </Form.Group>
                        </Row>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSelect}>
                        Cancelar
                    </Button>
                    <Button variant="success" >
                        Crear nuevo
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewPayment} centered onHide={handleCloseNewPayment}>
                <Modal.Header >
                    <Modal.Title>{modalNewPaymentTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <ButtonGroup>
                            <ToggleButton
                                key={1}
                                id={1}
                                type="radio"
                                variant='outline-success'
                                name="radio"
                                value={"Ingreso"}
                                checked={newPaymentType === "Ingreso"}
                                onChange={(e) => handleNewPaymentType(e.currentTarget.value)}
                            >
                                Ingreso
                            </ToggleButton>

                            <ToggleButton
                                key={2}
                                id={2}
                                type="radio"
                                variant='outline-danger'
                                name="radio"
                                value={"Egreso"}
                                checked={newPaymentType === "Egreso"}
                                onChange={(e) => handleNewPaymentType(e.currentTarget.value)}
                            >
                                Egreso
                            </ToggleButton>
                        </ButtonGroup>
                    </Row>
                    <Row className='mt-2'>
                        <Form.Text>Cliente / Proveedor</Form.Text>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control className='cover content rows element' readOnly type="text" id="business" placeholder="" />
                            <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={showSelector}><FontAwesomeIcon icon={faSearch} /></Button>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control type="text" id="" placeholder="Monto" onChange={(event) => handleNewPaymentAmount(event)} />
                        </Col>
                        <Col xs={4}>
                            <Form.Control type="text" id="" value="ARS" readOnly />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Form.Text>Conversión a moneda extranjera</Form.Text>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <Form.Control type="text" id="" placeholder="Monto" value={newPaymentExchAmount} onChange={(event) => handleNewPaymentExchAmount(event)} />
                        </Col>
                        <Col xs={1}>
                            @
                        </Col>
                        <Col xs={3}>
                            <Form.Control type="text" id="" placeholder="Cotización" value={newPaymentExchQuotation} onChange={(event) => handleNewPaymentExchQuotation(event)} />
                        </Col>
                        <Col xs={4}>
                            <Form.Select className='' onChange={(event) => handleNewPaymentExchCurrencyId(event)} id="">
                                <option value="0">Seleccionar...</option>
                                {currencies.map((c, i) => (
                                    <option key={i} id={i} value={c.id}>{c.code}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <Col>
                            <Form.Text>Descripción</Form.Text>
                            <Form.Control type="text" id="" placeholder="" onChange={(event) => handleNewPaymentContent(event)} />
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <Col>
                            <Form.Text>Factura</Form.Text>
                            <Form.Control type="text" id="" placeholder="" onChange={(event) => handleNewPaymentInvoiceNumber(event)} />
                        </Col>
                    </Row>
                    <Dropzone className='mt-3' multiple={false} onDrop={file => processReceiptFile(file)}>
                        {({ getRootProps, getInputProps }) => (
                            <section className='dropzone mt-2 pt-5 pb-5'>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Arrastrá el archivo o hacé clic para buscarlo</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary">
                        Volver
                    </Button>
                    <Button variant="success" onClick={createPayment}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showInvoices} size="lg" centered onHide={handleCloseInvoices}>
                <Modal.Header>
                    <Modal.Title>Documentos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row style={{ marginTop: -17 }}>
                            <Form.Group className="" controlId="exampleForm.ControlInput1">
                                {invoices != undefined ? invoices.map((r, i) => (
                                    <div>{Comment(false, `${r.ReceiptType.name} - ${r.number}`, r.description, true, r.File, r.User, r.createdAt, r.id)}</div>
                                )) : ""}
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseInvoices}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            

            

        </div>
    )
}