import React, { useEffect } from 'react'
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import NavBar from "../navbar/Navbar";
import { get, post, del, put } from '../../controllers/Endpoints';
import { getUserToken, logout, validateUser } from '../../controllers/UserController';

export default function Index() {

    useEffect(() => {
         validateToken();

    }, []);

    const validateToken = async function () {
        const token = localStorage.getItem("userToken");

        if (token !== undefined && token !== null) {
            const req = await get('/users/token', getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                validateUser();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            validateUser();
        }
    }

    return (
        <div>
            <Container>
                <Row style={{marginTop: 200}}>
                    <Col style={{textAlign: 'center'}}>
                        <Spinner animation="grow" variant="primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <Spinner className='ms-2' animation="grow" variant="primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <Spinner className='ms-2' animation="grow" variant="primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}