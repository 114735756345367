import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Col, Badge, Dropdown, Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faCheck, faFilePdf, faEllipsisV, faCommentAlt, faArrowLeft, faPlusCircle, faGavel, faTrash, faSearch, faTasks, faCogs, faEye, faLink, faMap } from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-free/css/all.css';
import NavBar from "../navbar/Navbar";
import WebFont from 'webfontloader';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import moment from 'moment'
import 'moment/locale/es-mx';
import { get, put, post } from '../../controllers/Endpoints'
import { getUserToken, logout } from '../../controllers/UserController';
import { processDate, fileToBase64, pn, addZero } from '../../tools/tools';
import { params } from '../../tools/constants';



export default function Settings() {

    moment.locale('es-mx');
    const PAGE_TITLE = "Configuración";
    const PAGE_ICON = faCogs;
    const [usersCol, setUsersCol] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [merchantCategories, setMerchantCategories] = React.useState([]);

    const [showNewOperationCategoryModal, setShowNewOperationCategoryModal] = React.useState(false);
    const handleCloseNewOperationCategoryModal = () => { setShowNewOperationCategoryModal(false); };
    const handleShowNewOperationCategoryModal = () => {
        setShowNewOperationCategoryModal(true);
    };
    const [integrations, setIntegrations] = React.useState([]);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `GrinBalb - ${PAGE_TITLE}`;
        getUsers();
        getMerchantCategories();
    }, []);

    const getUsers = async function () {
        const req = await get('/users', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setUsersCol(['', 'Nombre y apellido', 'Correo electrónico', 'Rol', 'Estado', 'Detalles'])
            processUsers(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processUsers = (data) => {
        let result = [];

        for (let u of data) {
            u.status = u.enabled ? <Badge bg='success' className='content-table status'>Habilitado</Badge> : <Badge bg='danger' className='content-table status'>Bloqueado</Badge>
            if (u.token == getUserToken()) {
                u.actions = <Link to={`/configuracion/usuarios/detalles/`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            } else {
                u.actions = <Link to={`/sistema/usuarios/detalles/?id=${u.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            }

            result.push(u);
        }

        setUsers(result)
    }

    const getMerchantCategories = async function () {
        const req = await get(`/operations/merchantCategories`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setMerchantCategories(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    const createMerchantCategories = async function () {
        const data = {
            name: document.getElementById('textOperationCategoryName').value,
        }
        const req = await post(`/operations/merchantCategories`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getMerchantCategories();
            handleCloseNewOperationCategoryModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <span style={{ fontSize: 18 }}>Usuarios</span>
                                </td>
                                <td className='header rows right'>
                                    <Link to={'/sistema/usuarios/nuevo'}><Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo usuario</Button></Link>
                                </td>
                            </tr>
                        </table>

                        <table striped hover className='mb-3 w-100'>
                            <thead>
                                <tr>
                                    {usersCol.map((c, i) => (
                                        <th key={i} className='content-table head'>{c}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((r, i) => (
                                    <tr key={i} >
                                        <td className='content-table rows middle align-middle' style={{ width: 70 }}><img className='avatar-10' src={`${params().dataUri}${r.avatar}`} /></td>
                                        <td className='content-table rows middle align-middle'>{r.name} {r.surname}</td>
                                        <td className='content-table rows middle align-middle'>{r.email}</td>
                                        <td className='content-table rows middle align-middle'>{r.Role.name}</td>
                                        <td className='content-table rows middle align-middle'>{r.status}</td>
                                        <td className='content-table rows middle align-middle'>{r.actions}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </Card>


                </Row>

                <Row>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <span style={{ fontSize: 18 }}>Rubros de operaciones</span>
                                </td>
                                <td className='header rows right'>
                                    <Button variant="success" onClick={handleShowNewOperationCategoryModal} ><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo rubro</Button>
                                </td>
                            </tr>
                        </table>

                        <p className='mt-3 ps-4 pe-4'>
                            {merchantCategories.map((r, i) => (
                                <Row className='p-2 mb-3' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                    <Col xs={11} className='align-center-vertically middle'>
                                        <div>
                                            <p className='m-0'>{r.name}</p>
                                        </div>
                                    </Col>
                                    
                                    <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            ))}


                        </p>

                    </Card>
                </Row>
            </Container>




            <Modal show={showNewOperationCategoryModal} onHide={handleCloseNewOperationCategoryModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo rubro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Nombre</Form.Text>
                        <Row>
                            <Col>
                                <Form.Control type="text" id="textOperationCategoryName" placeholder="" />
                            </Col>
                        </Row>
                    </Form.Group>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewOperationCategoryModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createMerchantCategories}>Crear rubro</Button>


                </Modal.Footer>
            </Modal>
        </div>
    )
}