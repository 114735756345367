import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Form, Badge, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faIndustry, faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post } from '../../controllers/Endpoints'
import "../../styles/styles.css";
import { getUserToken } from '../../controllers/UserController';

export default function Providers() {

    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);

    const PAGE_TITLE = "Clientes y proveedores";
    const PAGE_ICON = faIndustry;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `GrinBalb - ${PAGE_TITLE}`;
        setupTable();
        getProviders();
    }, []);

    const handleSearchChain = (event) => {
        const chain = event.target.value;

        if (chain === "") {
            getProviders();
        } else {
            searchProviders(chain)
        }
    };

    const searchProviders = async function (c) {
        const chain = {
            chain: c
        }

        const req = await post('/business/type/ALL/search', getUserToken(), chain);
        const res = await req.json();

        if (req.status === 200) {
            processProviders(res)
        } else {
            alert(res.message);
        }
    }

    const setupTable = function () {
        const cols = ['Código', 'Nombre', 'CUIT', 'Razón social', 'Tipo', ''];
        setTableColumns(cols);
    };

    const getProviders = async function () {
        const req = await get('/business/type/ALL', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processProviders(res)
        } else {
            alert(res.message);
        }
    }

    const processProviders = function (providers) {
        let result = [];
        for (let p of providers) {
            p.action = <Link to={`/empresas/detalles/?id=${p.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></Button></Link>
            p.type = (
                p.BusinessProviderTypes.map((c, i) => (
                    <span className='me-1' key={i}><Badge bg='primary' className='content-table status'>{c.ProviderType.name}</Badge></span>
                ))
            )
            result.push(p);
        }
        setTableRows(result);
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='cover content rows element ms-2' style={{ width: 250 }} type="text" id="businessIdentifier" placeholder="" onChange={(event) => handleSearchChain(event)} />
                                </td>
                                <td className='header rows right'>
                                    <Link to='/empresas/nuevo'>
                                        <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva empresa</Button>
                                    </Link>
                                </td>
                            </tr>
                        </table>


                        {tableRows.length > 0 ?
                            <Container className='pt-3'>
                                <Row className='p-2 mb-2' style={{ background: `#39AEDE`, color: 'white', borderRadius: 8 }}>
                                    <Col xs={1} className='align-center-vertically-v2'>
                                        <div>
                                            Cod.
                                        </div>
                                    </Col>

                                    <Col xs={8} className='align-center-vertically-v2' >
                                        <div>
                                            Razón social / Nombre
                                        </div>
                                    </Col>

                                    <Col xs={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            CUIT / Tax ID
                                        </div>
                                    </Col>


                                    <Col xs={1} className='align-center-vertically-v2'>
                                        <div>

                                        </div>
                                    </Col>
                                </Row>
                                {tableRows.map((r, i) => (
                                    <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col xs={1} className='align-center-vertically'>
                                            <div>
                                                <p className='m-0'>{r.identifier ? <Badge bg='primary' className='content-table status'>{r.identifier}</Badge> : <div></div>}</p>
                                            </div>
                                        </Col>

                                        <Col xs={8} className='align-center-vertically-v2' >
                                            <div>
                                                <p className='m-0' style={{ fontSize: 12 }}>{r.legalName}</p>
                                                <p className='m-0'>{r.name}</p>
                                            </div>
                                        </Col>

                                        <Col xs={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                <p className='m-0'>{r.legalId}</p>
                                            </div>
                                        </Col>

                                        <Col xs={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'right' }}>
                                                {r.action}
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faIndustry}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se han encontrado empresas</p>
                                </Row>
                            </Container>

                        }



                    </Card>
                </Row>
            </Container>

        </div>
    )
}