import React, { useEffect, useMemo } from 'react'
import WebFont from 'webfontloader';

// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Badge, Button, Form, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShip, faPlusCircle, faSearch, faEye, faFilter } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post } from '../../controllers/Endpoints';
import { getUserToken, logout } from '../../controllers/UserController';
import { timeSince } from '../../tools/tools';
import { Table, Column, Cell } from 'fixed-data-table-2';
import '../../styles/data-table.css'

import "../../styles/styles.css";


import { useTable, useBlockLayout } from 'react-table';
import { FixedSizeList } from 'react-window';
//import '../react-table/react-table.css';
//import '../../../../node_modules/react-table/react-table.css'

import TableView from '../tables/TableView';

export default function CntrReport() {


    const [columns, setColumns] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);

    const [orderTypes, setOrderTypes] = React.useState(["Ordenar por código (A -> Z)", "Ordenar por código (Z -> A)", "Ordenar por cliente (A -> Z)", "Ordenar por cliente (Z -> A)", "Ordenar por inactividad (menor a mayor)", "Ordenar por inactividad (mayor a menor)", "Ordenar por fecha de creación"]);
    const [selectedOrderType, setSelectedOrderType] = React.useState("Ordenar por código (A -> Z)");

    const [selectedStatusOpened, setSelectedStatusOpened] = React.useState(true);
    const [selectedStatusClosed, setSelectedStatusClosed] = React.useState(false);
    const [selectedComplexLow, setSelectedComplexLow] = React.useState(true);
    const [selectedComplexMed, setSelectedComplexMed] = React.useState(true);
    const [selectedComplexHigh, setSelectedComplexHigh] = React.useState(true);

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const handleCloseFilterModal = () => { setShowFilterModal(false); };
    const handleShowFilterModal = () => {
        setShowFilterModal(true);
    };

    const handleOrderType = (event) => {
        setSelectedOrderType(event.target.value);
    };

    const handleStatusOpened = (event) => { setSelectedStatusOpened(event.target.checked) };
    const handleStatusClosed = (event) => { setSelectedStatusClosed(event.target.checked) };
    const handleComplexLow = (event) => { setSelectedComplexLow(event.target.checked) };
    const handleComplexMed = (event) => { setSelectedComplexMed(event.target.checked) };
    const handleComplexHigh = (event) => { setSelectedComplexHigh(event.target.checked) };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = "GrinBalb - Operaciones";
        setupTable();
        handleSearch();
    }, []);

    const setupTable = function () {

        const c = [
            { name: 'Nº', property: 'number', width: 60, fixed: true },
            { name: 'Año', property: 'year', width: 80, fixed: true},
            { name: 'Cliente', property: 'customer', width: 300, fixed: true },
            { name: 'Código', property: 'code', width: 100, fixed: true},
            { name: 'Estado', property: 'status', width: 110 },
            { name: 'Complej.', property: 'complexity', width: 90},
            { name: 'Inactividad', property: 'inactividad', width: 110 },
            { name: 'Forwarder', property: 'forwarder', width: 300},
            { name: 'FOB', property: 'fob', width: 100 },
            { name: 'CIF', property: 'cif', width: 100},
            { name: 'Comentarios', property: 'comments', width: 500 },
            { name: 'Acciones inmediatas', property: 'events', width: 500},
            { name: 'Forma de pago', property: 'paymentCondition', width: 400 },
            { name: 'PI', property: 'pi', width: 200},
            { name: 'CI', property: 'ci', width: 200 },
            { name: 'ETD', property: 'etd', width: 130},
            { name: 'ETA', property: 'eta', width: 130 },
            { name: 'Producto', property: 'commodity', width: 400},
            { name: 'Fecha entrega', property: 'deadline', width: 130},
            { name: 'Buque', property: 'vessel', width: 200 },
            { name: 'Terminal', property: 'terminal', width: 200},
            { name: 'Línea', property: 'vesselLine', width: 200 },
            { name: 'Datos envío', property: 'shippingObs', width: 300},
            { name: 'Despachante', property: 'dispatcher', width: 300 },
            { name: 'SIRA', property: 'simi', width: 200},
            { name: 'Pago', property: 'payment', width: 300 }
          ];

          setColumns(c)
    };

    const processOperations = function (operations) {
        let result = [];
        let events = null;
        let aux;

        for (let p of operations) {
            events = null;
            p.inactividad = timeSince(p.updatedDate)
            if (p.inmediateActions) {
                events = p.inmediateActions.split('<br>');
            }
            p.events = events;
            switch (p.complexityId) {
                case 1:
                    p.complexity = <Badge bg='success' className='content-table status'>Baja</Badge>;
                    break;
                case 2:
                    p.complexity = <Badge bg='warning' className='content-table status'>Media</Badge>;
                    break;
                case 3:
                    p.complexity = <Badge bg='danger' className='content-table status'>Alta</Badge>;
                    break;
            }

            aux={
                number: p.num,
                year: p.year,
                customer: p.client,
                code: p.code,
                status: p.status,
                complexity: p.complexity,
                inactividad: p.inactividad,
                forwarder: p.forwarder,
                fob: p.fob,
                cif: p.cif,
                comments: p.comments,
                events: p.events != null ? p.events.map((r, i) => (<p className='m-0'>{r}</p>)) : <div></div>,
                paymentCondition: p.paymentCondition,
                pi: p.pi,
                ci: p.ci,
                etd: p.etd,
                eta: p.eta,
                commodity: p.commodity,
                deadline: p.deadline,
                vessel: p.vessel,
                terminal: p.terminal,
                vesselLine: p.vesselLine,
                shippingObs: p.shippingObs,
                dispatcher: p.dispatcher,
                simi: p.simi,
                payment: p.payment
            }




                                    
                                            

            result.push(aux)
            //result.push(p);
        }
        //setTableRows(result);
        setData(result)
    }

    const handleSearch = async function () {
        const body = {
            chain: document.getElementById('textSearch').value,
            complexity: {
                low: selectedComplexLow,
                mid: selectedComplexMed,
                high: selectedComplexHigh
            },
            status: {
                opened: selectedStatusOpened,
                closed: selectedStatusClosed
            },
            order: selectedOrderType
        };
        const req = await post(`/reports/operations/cntr`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            handleCloseFilterModal();
            processOperations(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>





            <Row >
                <Card className='cards content'>
                    <table className='header content'>
                        <tr>
                            <td className='header rows left'>
                                <span className='me-4'>Reporte de operaciones</span>
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='ms-2' type="text" id="textSearch" style={{ width: 250, display: 'inline' }} placeholder="" onChange={() => handleSearch()} />
                                <Button className="ms-2" variant="primary" onClick={handleShowFilterModal}><FontAwesomeIcon icon={faFilter} style={{ marginRight: 8 }} />Filtros</Button>
                            </td>
                            <td className='header rows right'>
                                
                            </td>
                        </tr>
                    </table>

              

                    {data.length > 0 ?
                        <div className='report'>
                            <Table
                                rowHeight={60}
                                rowsCount={data.length}
                                width={window.innerWidth - 3}
                                height={window.innerHeight - 80}
                                headerHeight={50}
                                footerHeight={0}
                                isColumnResizing={false}
                            >
                                {columns.map((column) => (
                                    <Column
                                        key={column.name}
                                        header={<Cell>{column.name}</Cell>}
                                        cell={(props) => {
                                            return <Cell>{data[props.rowIndex][column.property]}</Cell>;
                                        }}
                                        width={column.width}
                                        fixed={column.fixed}
                                    />
                                ))}
                            </Table>
                            
                        </div>
                        :
                        <Row style={{ height: 300, textAlign: "center" }}>
                            <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faShip}></FontAwesomeIcon></p>
                            <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron operaciones</p>
                        </Row>
                    }
                </Card>
            </Row>

            <Modal show={showFilterModal} onHide={handleCloseFilterModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtros</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Orden<br></br></Form.Text>
                        <Form.Select className='cover content rows element' id="" value={selectedOrderType} onChange={(event) => handleOrderType(event)}>
                            {orderTypes.map((p, i) => (
                                <option key={i} id={i} value={p}>{p}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-3'>
                        <Form.Text>Estado</Form.Text>
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Abierta"
                            checked={selectedStatusOpened}
                            onChange={(event) => handleStatusOpened(event)}
                        />
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Cerrada"
                            checked={selectedStatusClosed}
                            onChange={(event) => handleStatusClosed(event)}
                        />
                    </Form.Group>

                    <Form.Group className='mt-3'>
                        <Form.Text>Complejidad</Form.Text>
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Baja"
                            checked={selectedComplexLow}
                            onChange={(event) => handleComplexLow(event)}
                        />
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Media"
                            checked={selectedComplexMed}
                            onChange={(event) => handleComplexMed(event)}
                        />
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Alta"
                            checked={selectedComplexHigh}
                            onChange={(event) => handleComplexHigh(event)}
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilterModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleSearch}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}