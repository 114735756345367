import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { Routes, Route, Link } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Badge, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faDotCircle, faSignOutAlt, faUser, faCog } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { getUserRole, getUserToken, logout } from '../../controllers/UserController';

import "../../styles/styles.css";
import { put, get } from '../../controllers/Endpoints';
import { timeSince } from '../../tools/tools';
import { params } from '../../tools/constants';

export default function NavBar() {
    const [notifications, setNotifications] = React.useState([]);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto']
            }
        });

        getMyNotifications();
    }, []);

    const menus = [{ text: "Inicio", link: "/inicio" },
    { text: "Clientes / Proveedores", link: "/empresas" },
    { text: "Operaciones", link: "/operaciones" },
    { text: "Administración", link: "/administracion" },
    { text: "Configuración", link: "/sistema" }];

    const checkPage = function (key) {
        const actualUri = window.location.pathname.split('/')[1];

        if (actualUri === menus[key].link.replace('/', '')) {
            return (
                <Link to={menus[key].link} className="menuActive pe-3 ps-3 text-light">{menus[key].text}</Link>
            )
        } else {
            return (
                <Link to={menus[key].link} className="menuNotActive pe-3 ps-3 text-light">{menus[key].text}</Link>
            )
        }
    };

    const setNotificationAsSeen = async function (notificationId, url) {
        const req = await put(`/notifications/seen`, getUserToken(), { notificationId: notificationId });
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = url;
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getMyNotifications = async function(){
        const req = await get(`/notifications`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setNotifications(res);
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const goToProfile = () => {
        window.location.href = '/configuracion/usuarios/detalles/';
    }

    return (
        <div style={{ marginBottom: 125, fontFamily: 'Roboto' }}>
            <Navbar fixed="top" className="pb-2" style={{ background: "white" }}>
                <Container>
                    <Navbar.Brand>
                        <img
                            src="https://sistema.grinbalb.com/imgs/logo-header.png"
                            height="40"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text className='me-3'>

                            <Dropdown align="end">
                            <Dropdown.Toggle variant="light" id="dropdown-basic" style={{ zIndex: '9', height: 52 }}>
                                    <FontAwesomeIcon icon={faBell} className='ms-2' style={{ marginRight: 8 }} />{notifications.length > 0 ? <Badge bg="danger" className='me-2'>{notifications.length}</Badge> : <span></span>}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {notifications.length == 0 ? <p className='m-0 p-3' style={{ width: 220, textAlign: 'center' }}>No hay notificaciones</p> : ""}
                                    {notifications.map((n, i) => (
                                        <Dropdown.Item onClick={() => setNotificationAsSeen(n.id, n.url)}><p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ marginRight: 8 }} />{n.text}</p><p className='m-0' style={{ paddingLeft: 24, fontSize: 10 }}>{timeSince(n.createdAt)}</p></Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Navbar.Text>

                        

                        <Navbar.Text>

                        <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ background: "#39aede", borderColor: "#39aede", zIndex: '9' }}>
                                <img className='avatar-10' src={`${params().dataUri}${localStorage.getItem('userAvatar')}`} alt=""></img>
                                    <span className="ms-3 me-3">{`${localStorage.getItem('userName')} ${localStorage.getItem('userSurname')}`}</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={goToProfile}><FontAwesomeIcon icon={faUser} style={{ marginRight: 10 }} />Mi perfil</Dropdown.Item>
                                    <Dropdown.Item onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: 9 }} />Salir</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                     
                        </Navbar.Text>


                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Navbar fixed="top" style={{ background: "#39aede", marginTop: 85, zIndex: '10', height: 41 }}>
                <Container>
                    <Nav className="flex-grow-1 justify-content-center pb-0">
                        {menus.map((menu, i) => (
                            <div key={i}>{checkPage(i)}</div>
                        ))}
                    </Nav>
                </Container>
            </Navbar>
        </div>
    )
}