import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Form, Col, Modal, Badge} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faIndustry} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { getUserToken } from '../../controllers/UserController';
import "../../styles/styles.css";
import { post, get, del, put } from '../../controllers/Endpoints'

export default function NewProvider(){

    const [businessName, setBusinessName] = React.useState('');
    const [businessIdentifier, setBusinessIdentifier] = React.useState('');
    const [businessLegalName, setBusinessLegalName] = React.useState('');
    const [businessLegalId, setBusinessLegalId] = React.useState('');
    const [businessId, setBusinessId] = React.useState('');
    const [showOk, setShowOk] = React.useState(false);
    const [countries, setCountries] = React.useState([]);

    const handleCloseOk = () => setShowOk(false);
    const handleShowOk = () => setShowOk(true);

    const PAGE_TITLE = "Nueva empresa";
    const PAGE_ICON = faIndustry;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `GrinBalb - ${PAGE_TITLE}`;
        getCountries();
    }, []);

    const getCountries = async function () {
        const req = await get('/countries/', getUserToken());
        const res = await req.json();

        console.log(req)

        if (req.status === 200) {
            setCountries(res);
        } else {
            alert(res.message);
        }
    }

    const handleBusinessName = (event) => {
        setBusinessName(event.target.value);
    };

    const handleBusinessIdentifier = (event) => {
        setBusinessIdentifier(event.target.value);
    };

    const handleBusinessLegalName = (event) => {
        setBusinessLegalName(event.target.value);
    };

    const handleBusinessLegalId = (event) => {
        setBusinessLegalId(event.target.value);
    };

    const createProvider = async function(){
        const provider = {
            name: businessName,
            legalName: businessLegalName,
            legalId: businessLegalId,
            identifier: businessIdentifier,
            address: document.getElementById('businessAddress').value,
            postalCode: document.getElementById('businessPostalCode').value,
            city: document.getElementById('businessCity').value,
            province: document.getElementById('businessProvince').value,
            country: document.getElementById('businessCountry').value,
            businessType: 'PROVIDER'
        };

        const req = await post('/business/', getUserToken(), provider);
        const res = await req.json();

        if(req.status === 201){
            setBusinessId(res.id);
            setShowOk(true);
        }else{
            alert(res.message);
        }
    }

    const goToProvider = () => {
        window.location.href = `/empresas/detalles/?id=${businessId}`;
    };

    const goToProviders = () => {
        window.location.href = `/empresas`;
    };

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                <span style={{color: '#A0A0A0'}}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <Link to={'/empresas'} className='linkBlue'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Empresas
                                    </Link>
                                    <span className='ms-3' style={{color: '#A0A0A0'}}>|</span><span className='ms-3'>Datos generales</span>
                                </td>
                                
                            </tr>
                        </table>
                        
                        <Row className='ms-4 me-4 mt-5'>
                            <Col xs={2} className='cover content rows title'>
                                Nombre
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessName" placeholder=""  onChange={(event) => handleBusinessName(event)} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Identificador
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessIdentifier" placeholder=""  onChange={(event) => handleBusinessIdentifier(event)} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Razón social
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessLegalName" placeholder=""  onChange={(event) => handleBusinessLegalName(event)} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                CUIT / Tax ID
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessLegalId" placeholder=""  onChange={(event) => handleBusinessLegalId(event)} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Dirección
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessAddress"  placeholder="" />
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Código postal
                            </Col>
                            <Col>
                                <Form.Control className='w-25' type="text" id="businessPostalCode"  placeholder="" />
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Localidad
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessCity"  placeholder="" />
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Provincia
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessProvince"  placeholder="" />
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3 mb-5'>
                            <Col xs={2} className='cover content rows title'>
                                País
                            </Col>
                            <Col>
                                <Form.Select className='' id="businessCountry" >
                                    <option value={""}>Seleccionar...</option>
                                    {countries.map((c, i) => (
                                        <option key={i} id={i} value={c.name}>{c.name} ({c.code})</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                            </Col>
                        </Row>

                        <Row className='m-4'>
                            <Col style={{textAlign: 'right'}}>
                                <Button variant='primary' onClick={createProvider}>Confirmar</Button>
                            </Col>
                        </Row>

                        <Modal show={showOk} centered onHide={handleCloseOk}>
                            <Modal.Header closeButtonOk>
                                <Modal.Title>Nueva empresa</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                    Empresa creada correctamente
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={goToProviders}>
                                    Volver
                                </Button>
                                <Button variant="primary" onClick={goToProvider}>
                                    Ver empresa
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Card>
                </Row>
            </Container>
            
        </div>
    )
}
