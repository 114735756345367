import React, { useEffect, setState } from 'react'
import $ from 'jquery';
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { Container, Row, Card, Button, Form, Col, Modal, Table, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShip, faArrowLeft, faSearch, faPhoneAlt, faEnvelope, faMapMarkerAlt, faInfoCircle, faAddressBook, faCheck } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post, put } from '../../controllers/Endpoints';
import { getUserToken, logout } from '../../controllers/UserController';
import { timeSince } from '../../tools/tools';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';


export default function NewOperation(props) {

    const [isEditing, setIsEditing] = React.useState(false);
    const [operationId, setOperationId] = React.useState(null);
    const [operationCode, setOperationCode] = React.useState(null);
    const [coverId, setCoverId] = React.useState(null);

    const [operationTypes, setOperationTypes] = React.useState([]);
    const [date, setDate] = React.useState(new Date());

    let dataAux = null;

    const [importer, setImporter] = React.useState(null);
    const [importerContact, setImporterContact] = React.useState(null);
    const [exporter, setExporter] = React.useState(null);
    const [exporterContact, setExporterContact] = React.useState(null);
    const [customer, setCustomer] = React.useState(null);
    const [customerContact, setCustomerContact] = React.useState(null);
    const [dispatcher, setDispatcher] = React.useState(null);
    const [dispatcherContact, setDispatcherContact] = React.useState(null);
    const [forwarder, setForwarder] = React.useState(null);
    const [forwarderContact, setForwarderContact] = React.useState(null);

    const [modalType, setModalType] = React.useState('');
    const [selectionType, setSelectionType] = React.useState('');
    //let selectionType;
    const [modalData, setModalData] = React.useState([]);
    const [modalSearchChain, setModalSearchChain] = React.useState("");
    const [modalTableHeader, setModalTableHeader] = React.useState([]);

    const [showSelect, setShowSelect] = React.useState(false);
    const handleCloseSelect = () => setShowSelect(false);
    const handleShowSelect = () => setShowSelect(true);

    const [merchantCategories, setMerchantCategories] = React.useState([]);

    const [step1Data, setStep1Data] = React.useState({});


    //---AUX DATA---

    const [countries, setCountries] = React.useState([]);

    //--------------

    //---DATA VARS---

    const [providers, setProviders] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [contacts, setContacts] = React.useState([]);
    const [chETD, setChETD] = React.useState(new Date());
    const [chETA, setChETA] = React.useState(new Date());

    //--------------


    //---HANDLERS---

    const handleDate = (event) => {
        setDate(event);
    };

    const handleChETD = (event) => {
        setChETD(event);
    };

    const handleChETA = (event) => {
        setChETA(event);
    };

    const handleChCommodityUnit = (event) => {
        const value = event.target.value;

        const chFobReal = document.getElementById('textChFobRealTotal').value;
        const chCifReal = document.getElementById('textChCifRealTotal').value;
        let aux = 0;

        if (value.length > 0 && chFobReal.length > 0) {
            aux = value * chFobReal;
            document.getElementById('textChFobRealTotal').value = parseFloat(aux).toFixed(2);
        }

        if (value.length > 0 && chCifReal.length > 0) {
            aux = value * chFobReal;
            document.getElementById('textChCifRealTotal').value = parseFloat(aux).toFixed(2);
        }
    };

    const handleChFobReal = (event) => {
        const value = event.target.value;
        const chCommodityUnit = document.getElementById('textChCommodityUnit').value;
        let aux = 0;

        if (chCommodityUnit.length > 0 && value.length > 0) {
            aux = value * chCommodityUnit;
            document.getElementById('textChFobRealTotal').value = parseFloat(aux).toFixed(2);
        }
    };

    const handleChCifReal = (event) => {
        const value = event.target.value;
        const chCommodityUnit = document.getElementById('textChCommodityUnit').value;
        let aux = 0;

        if (chCommodityUnit.length > 0 && value.length > 0) {
            aux = value * chCommodityUnit;
            document.getElementById('textChCifRealTotal').value = parseFloat(aux).toFixed(2);
        }
    };

    //--------------



    const PAGE_TITLE = "Nueva operación";
    const PAGE_ICON = faShip;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });


        init();
    }, []);

    const init = function () {
        window.scrollTo(0, 0);
        registerLocale('es', es);

        getData();
    }

    const getData = async () => {
        await getOperationTypes();
        await getMerchantCategories();
        await getCountries();

        if (getParamId() != -1) {
            getCover();
        } else {
            document.title = `GrinBalb - ${PAGE_TITLE}`;
        }
    }

    const getParamId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getCover = async function () {
        setIsEditing(true);
        document.title = `GrinBalb - Modificar carátula`;

        const req = await get(`/operations/cover/${getParamId()}`, getUserToken());
        const res = await req.json();

        console.log(res)

        if (req.status === 200) {
            processCover(res.cover, res.operation)
        } else {
            alert(res.message);
        }
    }

    const processCover = (data, op) => {
        setOperationId(op.id);
        setCoverId(data.id);
        setOperationCode(op.code);

        setDate(new Date(data.date));
        document.getElementById('selectType').value = op.operationTypeId;
        document.getElementById('selectMerchantCategory').value = op.merchantCategoryId;
        document.getElementById('selectComplexity').value = op.complexityId;

        setImporter(data.importerId)
        if (data.importerId) {
            document.getElementById('importer').value = data.Importer.name;
        }

        setImporterContact(data.importerContactId)
        if (data.importerContactId) {
            document.getElementById('importerContact').value = data.ImporterContact.name;
        }

        setExporter(data.exporterId)
        if (data.exporterId) {
            document.getElementById('exporter').value = data.Exporter.name;
        }

        setExporterContact(data.exporterContactId)
        if (data.exporterContactId) {
            document.getElementById('exporterContact').value = data.ExporterContact.name;
        }

        setCustomer(data.clientId)
        if (data.clientId) {
            document.getElementById('customer').value = data.Client.name;
        }

        setCustomerContact(data.clientContactId)
        if (data.clientContactId) {
            document.getElementById('customerContact').value = data.ClientContact.name;
        }

        setDispatcher(data.dispatcherId)
        if (data.dispatcherId) {
            document.getElementById('dispatcher').value = data.Dispatcher.name;
        }

        setDispatcherContact(data.dispatcherContactId)
        if (data.dispatcherContactId) {
            document.getElementById('dispatcherContact').value = data.DispatcherContact.name;
        }

        setForwarder(data.forwarderId)
        if (data.forwarderId) {
            document.getElementById('forwarder').value = data.Forwarder.name;
        }

        setForwarderContact(data.forwarderContactId)
        if (data.forwarderContactId) {
            document.getElementById('forwarderContact').value = data.ForwarderContact.name;
        }

        document.getElementById('textChCommodity').value = data.chCommodity;
        document.getElementById('textChCommodityUnit').value = data.chCommodityUnit;
        document.getElementById('textChTariffPosition').value = data.chTariffPosition;
        document.getElementById('selectChTariffPositionUnit').value = data.chTariffPositionUnit;
        document.getElementById('selectChOrigin').value = data.chOriginId == null ? "0" : data.chOriginId;
        setChETA(new Date(data.chEta))
        setChETD(new Date(data.chEtd))
        document.getElementById('selectChLicense').value = data.chLicense;
        document.getElementById('textChQuantity').value = data.chQuantity;
        document.getElementById('textChQuantityUnit').value = data.chQuantityUnit;
        document.getElementById('textChFobReal').value = data.chFobReal;
        document.getElementById('textChFobRealUnit').value = data.chFobRealUnit;
        document.getElementById('textChFobRealTotal').value = data.chFobRealTotal;
        document.getElementById('textChCifReal').value = data.chCifReal;
        document.getElementById('textChCifRealUnit').value = data.chCifRealUnit;
        document.getElementById('textChCifRealTotal').value = data.chCifRealTotal;
        document.getElementById('selectChIncoterm').value = data.chIncoterm;
        document.getElementById('textChCntr').value = data.chCntr;
        document.getElementById('selectChCntrUnit').value = data.chCntrUnit;
        document.getElementById('selectChPreviousDeclaration').value = data.chPreviousDeclaration;
        document.getElementById('textChPaymentCondition').value = data.chPaymentCondition;
        document.getElementById('textBuShipper').value = data.buShipper;
        document.getElementById('textBuShipperUnit').value = data.buShipperUnit;
        document.getElementById('textBuShipperTotal').value = data.buShipperTotal;
        document.getElementById('textBuClient').value = data.buClient;
        document.getElementById('textBuClientUnit').value = data.buClientUnit;
        document.getElementById('textBuClientTotal').value = data.buClientTotal;
        document.getElementById('textBuDispatcher').value = data.buDispatcher;
        document.getElementById('textBuDispatcherUnit').value = data.buDispatcherUnit;
        document.getElementById('textBuDispatcherTotal').value = data.buDispatcherTotal
        document.getElementById('textBuForwarderAgency').value = data.buForwarderAgency;
        document.getElementById('textBuForwarderAgencyUnit').value = data.buForwarderAgencyUnit;
        document.getElementById('textBuForwarderAgencyTotal').value = data.buForwarderAgencyTotal;
        document.getElementById('textBuForwarderCharter').value = data.buForwarderCharter;
        document.getElementById('textBuForwarderCharterUnit').value = data.buForwarderCharterUnit;
        document.getElementById('textBuForwarderCharterTotal').value = data.buForwarderCharterTotal;
        document.getElementById('textBuDiffTCAdvance').value = data.buDiffTCAdvance;
        document.getElementById('textBuDiffTCAdvanceUnit').value = data.buDiffTCAdvanceUnit;
        document.getElementById('textBuDiffTCAdvanceTotal').value = data.buDiffTCAdvanceTotal;
        document.getElementById('textBuDiffTCBalance').value = data.buDiffTCBalance;
        document.getElementById('textBuDiffTCBalanceUnit').value = data.buDiffTCBalanceUnit;
        document.getElementById('textBuDiffTCBalanceTotal').value = data.buDiffTCBalanceTotal;
        document.getElementById('textBuDiffTax').value = data.buDiffTax;
        document.getElementById('textBuDiffTaxUnit').value = data.buDiffTaxUnit;
        document.getElementById('textBuDiffTaxTotal').value = data.buDiffTaxTotal;
        document.getElementById('textBuOthers').value = data.buOthers;
        document.getElementById('textBuOthersUnit').value = data.buOthersUnit;
        document.getElementById('textBuOthersTotal').value = data.buOthersTotal;
        document.getElementById('textBuTotal').value = data.buTotal;

        getProforma();
    }

    const getProforma = async function () {
        const req = await get(`/operations/proforma/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            if(res.number){
                document.getElementById('textProformaNumber').value = res.number
            }
        } else {
            alert(res.message);
        }
    }

    const getOperationTypes = async function () {
        const req = await get('/operations/types', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setOperationTypes(res);
        } else {
            alert(res.message);
        }
    }

    const showSelector = (type) => {
        let canShow = true;
        setProviders([]);
        setCustomers([]);
        setContacts([]);
        console.log(type)

        switch (type) {
            case "IMPORTER":
                setSelectionType("IMPORTER")
                setModalType("Importador")
                break;
            case "IMPORTER-CONTACT":
                if (importer != null) {
                    setSelectionType("IMPORTER")
                    setModalType("Contacto de Importador")
                    getContacts(importer);
                } else {
                    canShow = false;
                }
                break;
            case "EXPORTER":
                setSelectionType("EXPORTER")
                setModalType("Exportador")
                break;
            case "EXPORTER-CONTACT":
                if (exporter != null) {
                    setSelectionType("EXPORTER")
                    setModalType("Contacto de Exportador")
                    getContacts(exporter);
                } else {
                    canShow = false;
                }
                break;
            case "CUSTOMER":
                setSelectionType("CUSTOMER")
                setModalType("Cliente")
                break;
            case "CUSTOMER-CONTACT":
                if (customer != null) {
                    setSelectionType("CUSTOMER")
                    setModalType("Contacto de Cliente")
                    getContacts(customer);
                } else {
                    canShow = false;
                }
                break;
            case "DISPATCHER":
                setSelectionType("DISPATCHER")
                setModalType("Despachante")
                break;
            case "DISPATCHER-CONTACT":
                if (dispatcher != null) {
                    setSelectionType("DISPATCHER")
                    setModalType("Contacto de Despachante")
                    getContacts(dispatcher);
                } else {
                    canShow = false;
                }
                break;
            case "FORWARDER":
                setSelectionType("FORWARDER")
                setModalType("Forwarder")
                break;
            case "FORWARDER-CONTACT":
                if (forwarder != null) {
                    setSelectionType("FORWARDER")
                    setModalType("Contacto de Forwarder")
                    getContacts(forwarder);
                } else {
                    canShow = false;
                }
                break;
        }

        if (canShow) {
            setShowSelect(true);
        } else {
            alert("Seleccione una empresa")
        }
    };

    const getProviders = async function () {
        const req = await get('/business/type/ALL', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processProviders(res)
        } else {
            alert(res.message);
        }
    }

    const handleSearchChain = (event) => {
        const chain = event.target.value;

        switch (modalType) {
            case "Importador":
                searchProviders(chain)
                break;
            case "Exportador":
                searchProviders(chain)
                break;
            case "Cliente":
                searchProviders(chain)
                break;
            case "Despachante":
                searchProviders(chain)
                break;
            case "Forwarder":
                searchProviders(chain)
                break;
        }
    };

    const getCustomers = async function () {
        const req = await get('/business/type/CLIENT', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCustomers(res)
        } else {
            alert(res.message);
        }
    }

    const processCustomers = function (customers) {
        const cols = ['Código', 'Nombre', 'CUIT', 'Razón social', ''];
        setModalTableHeader(cols);

        let result = [];
        for (let c of customers) {
            c.action = <Button className='buttonBlue' variant="primary" onClick={() => confirmModal(c.id, c.name, false)}>Seleccionar</Button>
            result.push(c);
        }

        setCustomers(result)
    }

    const searchCustomers = async function (c) {
        if (c.length > 0) {
            const chain = {
                chain: c
            }

            const req = await post('/business/type/CLIENT/search', getUserToken(), chain);
            const res = await req.json();

            if (req.status === 200) {
                processClients(res)
            } else {
                alert(res.message);
            }
        } else {
            processClients([])
        }

    }

    const searchProviders = async function (c) {
        if (c.length > 0) {
            const chain = {
                chain: c
            }

            const req = await post('/business/type/ALL/search', getUserToken(), chain);
            const res = await req.json();

            if (req.status === 200) {
                processProviders(res)
            } else {
                alert(res.message);
            }
        } else {
            processProviders([])
        }

    }

    const processProviders = function (providers) {
        const cols = ['Código', 'Nombre', 'CUIT', 'Razón social', ''];
        setModalTableHeader(cols);

        let result = [];
        for (let p of providers) {
            p.action = <Button className='buttonBlue' variant="primary" onClick={() => confirmModal(p.id, p.name, false)}><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></Button>
            result.push(p);
        }


        setProviders(result)


    }

    const processClients = function (providers) {
        const cols = ['Código', 'Nombre', 'CUIT', 'Razón social', ''];
        setModalTableHeader(cols);

        let result = [];
        for (let p of providers) {
            p.action = <Button className='buttonBlue' variant="primary" onClick={() => confirmModal(p.id, p.name, false)}><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></Button>
            result.push(p);
        }


        setCustomers(result)


    }

    const getContacts = async function (businessId) {
        const req = await get(`/business/${businessId}/contact`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processContacts(res)
        } else {
            alert(res.message);
        }
    }

    const processContacts = function (data) {
        const cols = ['Nombre', 'Datos', ''];
        setModalTableHeader(cols);

        let result = [];
        let aux;
        let icon;

        for (let c of data) {
            aux = null;

            for (let i = 0; i < c.ContactData.length; i++) {
                switch (c.ContactData[i].param) {
                    case "PHON":
                        icon = <FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon>;
                        break;
                    case "EMAI":
                        icon = <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>;
                        break;
                    case "ADDR":
                        icon = <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>;
                        break;
                    case "OTHE":
                        icon = <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>;
                        break;
                    default:
                        break;
                }

                if (i === 0) {
                    aux = <span>{icon} {c.ContactData[i].data}</span>;
                } else {
                    aux = <span>{aux} | {icon} {c.ContactData[i].data}</span>;
                }
            }
            c.data = aux;
            c.action = <Button className='buttonBlue' variant="primary" onClick={() => confirmModal(c.id, c.name, true)}>Seleccionar</Button>
            result.push(c);
        }

        setContacts(result)
    }

    const confirmModal = function (id, data, contact) {
        console.log(id, data, contact, selectionType)
        setShowSelect(false);
        switch (selectionType) {
            case "IMPORTER":
                if (contact) {
                    document.getElementById('importerContact').value = data;
                    setImporterContact(id);
                } else {
                    document.getElementById('importer').value = data;
                    setImporter(id);
                }
                break;
            case "EXPORTER":
                if (contact) {
                    document.getElementById('exporterContact').value = data;
                    setExporterContact(id);
                } else {
                    document.getElementById('exporter').value = data;
                    setExporter(id);
                }
                break;
            case "CUSTOMER":
                if (contact) {
                    document.getElementById('customerContact').value = data;
                    setCustomerContact(id);
                } else {
                    document.getElementById('customer').value = data;
                    setCustomer(id);


                }
                break;
            case "DISPATCHER":
                if (contact) {
                    document.getElementById('dispatcherContact').value = data;
                    setDispatcherContact(id);
                } else {
                    document.getElementById('dispatcher').value = data;
                    setDispatcher(id);
                }
                break;
            case "FORWARDER":
                if (contact) {
                    document.getElementById('forwarderContact').value = data;
                    setForwarderContact(id);
                } else {
                    document.getElementById('forwarder').value = data;
                    setForwarder(id);
                }
                break;
        }
    }

    const newContact = function () {
        let uri = '';

        if (modalType.includes("Contacto")) {
            //Create a contact
            if (modalType.includes("Cliente")) {
                uri = `/clientes/detalles/?id=${customer}`;
            } else if (modalType.includes("Importador")) {
                uri = `/proveedores/detalles/?id=${importer}`;
            } else if (modalType.includes("Exportador")) {
                uri = `/proveedores/detalles/?id=${exporter}`;
            } else if (modalType.includes("Despachante")) {
                uri = `/proveedores/detalles/?id=${dispatcher}`;
            } else if (modalType.includes("Forwarder")) {
                uri = `/proveedores/detalles/?id=${forwarder}`;
            }
        } else {
            //Create a business
            if (modalType.includes("Cliente")) {
                uri = `/clientes/nuevo`;
            } else {
                uri = `/proveedores/nuevo`;
            }
        }

        window.location.href = uri;
    }

    const getMerchantCategories = async function () {
        const req = await get(`/operations/merchantCategories`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setMerchantCategories(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCountries = async function () {
        const req = await get('/countries/', getUserToken());
        const res = await req.json();

        console.log(req)

        if (req.status === 200) {
            setCountries(res);
        } else {
            alert(res.message);
        }
    }

    const createOperation = async () => {
        const body = {
            date: date,
            operationTypeId: parseInt(document.getElementById('selectType').value),
            importerId: importer,
            importerContactId: importerContact,
            exporterId: exporter,
            exporterContactId: exporterContact,
            clientId: customer,
            clientContactId: customerContact,
            dispatcherId: dispatcher,
            dispatcherContactId: dispatcherContact,
            forwarderId: forwarder,
            forwarderContactId: forwarderContact,
            complexityId: parseInt(document.getElementById('selectComplexity').value),
            merchantCategoryId: parseInt(document.getElementById('selectMerchantCategory').value),
            chCommodity: document.getElementById('textChCommodity').value,
            chCommodityUnit: document.getElementById('textChCommodityUnit').value,
            chTariffPosition: document.getElementById('textChTariffPosition').value,
            chTariffPositionUnit: document.getElementById('selectChTariffPositionUnit').value,
            chOriginId: document.getElementById('selectChOrigin').value != '0' ? document.getElementById('selectChOrigin').value : null,
            chEtd: chETD,
            chEta: chETA,
            chLicense: document.getElementById('selectChLicense').value,
            chQuantity: document.getElementById('textChQuantity').value,
            chQuantityUnit: document.getElementById('textChQuantityUnit').value,
            chFobReal: document.getElementById('textChFobReal').value,
            chFobRealUnit: document.getElementById('textChFobRealUnit').value,
            chFobRealTotal: document.getElementById('textChFobRealTotal').value,
            chCifReal: document.getElementById('textChCifReal').value,
            chCifRealUnit: document.getElementById('textChCifRealUnit').value,
            chCifRealTotal: document.getElementById('textChCifRealTotal').value,
            chIncoterm: document.getElementById('selectChIncoterm').value,
            chCntr: document.getElementById('textChCntr').value,
            chCntrUnit: document.getElementById('selectChCntrUnit').value,
            chPreviousDeclaration: document.getElementById('selectChPreviousDeclaration').value,
            chPaymentCondition: document.getElementById('textChPaymentCondition').value,
            buShipper: document.getElementById('textBuShipper').value,
            buShipperUnit: document.getElementById('textBuShipperUnit').value,
            buShipperTotal: document.getElementById('textBuShipperTotal').value,
            buClient: document.getElementById('textBuClient').value,
            buClientUnit: document.getElementById('textBuClientUnit').value,
            buClientTotal: document.getElementById('textBuClientTotal').value,
            buDispatcher: document.getElementById('textBuDispatcher').value,
            buDispatcherUnit: document.getElementById('textBuDispatcherUnit').value,
            buDispatcherTotal: document.getElementById('textBuDispatcherTotal').value,
            buForwarderAgency: document.getElementById('textBuForwarderAgency').value,
            buForwarderAgencyUnit: document.getElementById('textBuForwarderAgencyUnit').value,
            buForwarderAgencyTotal: document.getElementById('textBuForwarderAgencyTotal').value,
            buForwarderCharter: document.getElementById('textBuForwarderCharter').value,
            buForwarderCharterUnit: document.getElementById('textBuForwarderCharterUnit').value,
            buForwarderCharterTotal: document.getElementById('textBuForwarderCharterTotal').value,
            buDiffTCAdvance: document.getElementById('textBuDiffTCAdvance').value,
            buDiffTCAdvanceUnit: document.getElementById('textBuDiffTCAdvanceUnit').value,
            buDiffTCAdvanceTotal: document.getElementById('textBuDiffTCAdvanceTotal').value,
            buDiffTCBalance: document.getElementById('textBuDiffTCBalance').value,
            buDiffTCBalanceUnit: document.getElementById('textBuDiffTCBalanceUnit').value,
            buDiffTCBalanceTotal: document.getElementById('textBuDiffTCBalanceTotal').value,
            buDiffTax: document.getElementById('textBuDiffTax').value,
            buDiffTaxUnit: document.getElementById('textBuDiffTaxUnit').value,
            buDiffTaxTotal: document.getElementById('textBuDiffTaxTotal').value,
            buOthers: document.getElementById('textBuOthers').value,
            buOthersUnit: document.getElementById('textBuOthersUnit').value,
            buOthersTotal: document.getElementById('textBuOthersTotal').value,
            buTotal: document.getElementById('textBuTotal').value
        }

        const operation = {
            operation: {
                code: document.getElementById('opCode').value.length > 1 ? document.getElementById('opCode').value : null,
                clientId: customer,
                operationTypeId: parseInt(document.getElementById('selectType').value),
                complexityId: parseInt(document.getElementById('selectComplexity').value),
                merchantCategoryId: parseInt(document.getElementById('selectMerchantCategory').value)
            },
            cover: body
        }

        const req = await post('/operations/', getUserToken(), operation);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = `/operaciones/nueva/ok/?id=${res.operationId}&code=${res.operationCode}`;
        } else {
            alert(res.message);
        }
    }


    const editOperation = async () => {
        const body = {
            id: coverId,
            date: date,
            operationTypeId: parseInt(document.getElementById('selectType').value),
            importerContactId: importerContact,
            exporterContactId: exporterContact,
            clientContactId: customerContact,
            dispatcherId: dispatcher,
            dispatcherContactId: dispatcherContact,
            forwarderId: forwarder,
            forwarderContactId: forwarderContact,
            complexityId: parseInt(document.getElementById('selectComplexity').value),
            merchantCategoryId: parseInt(document.getElementById('selectMerchantCategory').value),
            chCommodity: document.getElementById('textChCommodity').value,
            chCommodityUnit: document.getElementById('textChCommodityUnit').value,
            chTariffPosition: document.getElementById('textChTariffPosition').value,
            chTariffPositionUnit: document.getElementById('selectChTariffPositionUnit').value,
            chOriginId: document.getElementById('selectChOrigin').value != '0' ? document.getElementById('selectChOrigin').value : null,
            chEtd: chETD,
            chEta: chETA,
            chLicense: document.getElementById('selectChLicense').value,
            chQuantity: document.getElementById('textChQuantity').value,
            chQuantityUnit: document.getElementById('textChQuantityUnit').value,
            chFobReal: document.getElementById('textChFobReal').value,
            chFobRealUnit: document.getElementById('textChFobRealUnit').value,
            chFobRealTotal: document.getElementById('textChFobRealTotal').value,
            chCifReal: document.getElementById('textChCifReal').value,
            chCifRealUnit: document.getElementById('textChCifRealUnit').value,
            chCifRealTotal: document.getElementById('textChCifRealTotal').value,
            chIncoterm: document.getElementById('selectChIncoterm').value,
            chCntr: document.getElementById('textChCntr').value,
            chCntrUnit: document.getElementById('selectChCntrUnit').value,
            chPreviousDeclaration: document.getElementById('selectChPreviousDeclaration').value,
            chPaymentCondition: document.getElementById('textChPaymentCondition').value,
            buShipper: document.getElementById('textBuShipper').value,
            buShipperUnit: document.getElementById('textBuShipperUnit').value,
            buShipperTotal: document.getElementById('textBuShipperTotal').value,
            buClient: document.getElementById('textBuClient').value,
            buClientUnit: document.getElementById('textBuClientUnit').value,
            buClientTotal: document.getElementById('textBuClientTotal').value,
            buDispatcher: document.getElementById('textBuDispatcher').value,
            buDispatcherUnit: document.getElementById('textBuDispatcherUnit').value,
            buDispatcherTotal: document.getElementById('textBuDispatcherTotal').value,
            buForwarderAgency: document.getElementById('textBuForwarderAgency').value,
            buForwarderAgencyUnit: document.getElementById('textBuForwarderAgencyUnit').value,
            buForwarderAgencyTotal: document.getElementById('textBuForwarderAgencyTotal').value,
            buForwarderCharter: document.getElementById('textBuForwarderCharter').value,
            buForwarderCharterUnit: document.getElementById('textBuForwarderCharterUnit').value,
            buForwarderCharterTotal: document.getElementById('textBuForwarderCharterTotal').value,
            buDiffTCAdvance: document.getElementById('textBuDiffTCAdvance').value,
            buDiffTCAdvanceUnit: document.getElementById('textBuDiffTCAdvanceUnit').value,
            buDiffTCAdvanceTotal: document.getElementById('textBuDiffTCAdvanceTotal').value,
            buDiffTCBalance: document.getElementById('textBuDiffTCBalance').value,
            buDiffTCBalanceUnit: document.getElementById('textBuDiffTCBalanceUnit').value,
            buDiffTCBalanceTotal: document.getElementById('textBuDiffTCBalanceTotal').value,
            buDiffTax: document.getElementById('textBuDiffTax').value,
            buDiffTaxUnit: document.getElementById('textBuDiffTaxUnit').value,
            buDiffTaxTotal: document.getElementById('textBuDiffTaxTotal').value,
            buOthers: document.getElementById('textBuOthers').value,
            buOthersUnit: document.getElementById('textBuOthersUnit').value,
            buOthersTotal: document.getElementById('textBuOthersTotal').value,
            buTotal: document.getElementById('textBuTotal').value
        }

        const operation = {
            operation: {
                id: operationId,
                operationTypeId: parseInt(document.getElementById('selectType').value),
                complexityId: parseInt(document.getElementById('selectComplexity').value),
                merchantCategoryId: parseInt(document.getElementById('selectMerchantCategory').value)
            },
            cover: body
        }

        const req = await put('/operations/', getUserToken(), operation);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = `/operaciones/detalles/?id=${operationId}`;
        } else {
            alert(res.message);
        }
    }

    const goBackToOperation = () => {
        window.location.href = `/operaciones/nueva/?id=${operationId}`;
    }


    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontWeight: 300 }}>
                                    {isEditing ? <p style={{ fontSize: 20 }} className='text-muted mb-0'>Modificar carátula</p> : <p style={{ fontSize: 20 }} className='text-muted mb-0'>{PAGE_TITLE}</p>}
                                    {isEditing ? <p style={{ color: '#A0A0A0', fontSize: 45 }}>{operationCode}</p> : <p style={{ color: '#A0A0A0', fontSize: 45 }}>Carátula</p>}
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    {isEditing ?
                                        <div>
                                            <Link to={`/operaciones/detalles/?id=${operationId}`} className='linkBlue'>
                                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a la Operación
                                            </Link>
                                            <span className='ms-3' style={{ color: '#A0A0A0' }}>|</span><span className='ms-3'>Datos generales</span>
                                        </div>
                                        :
                                        <div>
                                            <Link to={'/operaciones'} className='linkBlue'>
                                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Operaciones
                                            </Link>
                                            <span className='ms-3' style={{ color: '#A0A0A0' }}>|</span><span className='ms-3'>Datos generales</span>
                                        </div>
                                    }
                                </td>
                            </tr>
                        </table>

                        <Row className='ms-4 me-4 mt-4'>
                            <Col xs={2} className='cover content rows title'>
                                Código (DATA ENTRY, NO UTILIZAR)
                            </Col>
                            <Col>
                            <Form.Control className='' type="text" id="opCode" placeholder="" />
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-4'>
                            <Col xs={2} className='cover content rows title'>
                                Fecha de creación
                            </Col>
                            <Col>
                                <DatePicker className='form-control cover content rows element' locale="es" dateFormat="dd/MM/yyyy" selected={date} onChange={(d) => handleDate(d)} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Tipo de operación
                            </Col>
                            <Col>
                                <Form.Select className='cover content rows element' id="selectType">
                                    {operationTypes.map((ot, i) => (
                                        <option key={i} id={i} value={ot.id}>{ot.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Rubro
                            </Col>
                            <Col>
                                <Form.Select id="selectMerchantCategory" className='cover content rows element'>
                                    <option value={null}>Seleccionar...</option>
                                    {merchantCategories.map((ot, i) => (
                                        <option key={i} id={i} value={ot.id}>{ot.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Complejidad
                            </Col>
                            <Col>
                                <Form.Select id="selectComplexity" className='cover content rows element'>
                                    <option value={1}>Baja</option>
                                    <option value={2}>Media</option>
                                    <option value={3}>Alta</option>
                                </Form.Select>
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-5'>
                            <Col xs={2} className='cover content rows title'>

                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Empresa
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Contacto
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Importador
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="importer" placeholder="" />
                                {isEditing ? <div></div> : <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={() => showSelector("IMPORTER")}><FontAwesomeIcon icon={faSearch} /></Button>}
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="importerContact" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={() => showSelector("IMPORTER-CONTACT")}><FontAwesomeIcon icon={faSearch} /></Button>
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Exportador
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="exporter" placeholder="" />
                                {isEditing ? <div></div> : <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={() => showSelector("EXPORTER")}><FontAwesomeIcon icon={faSearch} /></Button>}
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="exporterContact" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={() => showSelector("EXPORTER-CONTACT")}><FontAwesomeIcon icon={faSearch} /></Button>
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Cliente
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="customer" placeholder="" />
                                {isEditing ? <div></div> : <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={() => showSelector("CUSTOMER")}><FontAwesomeIcon icon={faSearch} /></Button>}
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="customerContact" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={() => showSelector("CUSTOMER-CONTACT")}><FontAwesomeIcon icon={faSearch} /></Button>
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Despachante
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="dispatcher" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={() => showSelector("DISPATCHER")}><FontAwesomeIcon icon={faSearch} /></Button>
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="dispatcherContact" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={() => showSelector("DISPATCHER-CONTACT")}><FontAwesomeIcon icon={faSearch} /></Button>
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3 mb-5'>
                            <Col xs={2} className='cover content rows title'>
                                Forwarder
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="forwarder" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={() => showSelector("FORWARDER")}><FontAwesomeIcon icon={faSearch} /></Button>
                            </Col>
                            <Col>
                                <Form.Control className='cover content rows element' readOnly type="text" id="forwarderContact" placeholder="" />
                                <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={() => showSelector("FORWARDER-CONTACT")}><FontAwesomeIcon icon={faSearch} /></Button>
                            </Col>
                        </Row>



                    </Card>
                </Row>
            </Container>

            <Container>
                <Row>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    Datos de carga
                                </td>
                            </tr>
                        </table>


                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>

                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Valores
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Unidades
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Totales (USD)
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Mercadería
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textChCommodity" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textChCommodityUnit" placeholder="" onChange={(event) => handleChCommodityUnit(event)} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Posición arancelaria
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textChTariffPosition" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Select id="selectChTariffPositionUnit" className=''>
                                    <option value={null}>Seleccionar...</option>
                                    <option value="Bien de capital">Bien de capital</option>
                                    <option value="Servicio">Servicio</option>
                                    <option value="Insumos">Insumos</option>
                                    <option value="Bienes finales">Bienes finales</option>
                                </Form.Select>
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Origen
                            </Col>
                            <Col>
                                <Form.Select className='' id="selectChOrigin">
                                    <option value={"0"}>Seleccionar...</option>
                                    {countries.map((c, i) => (
                                        <option key={i} id={i} value={c.id}>{c.name} ({c.code})</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                ETD
                            </Col>
                            <Col>
                                <DatePicker className='form-control' locale="es" dateFormat="dd/MM/yyyy" selected={chETD} onChange={(event) => handleChETD(event)} />
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                ETA
                            </Col>
                            <Col>
                                <DatePicker className='form-control ' locale="es" dateFormat="dd/MM/yyyy" selected={chETA} onChange={(event) => handleChETA(event)} />
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Licencia
                            </Col>
                            <Col>
                                <Form.Select id="selectChLicense" className='' >
                                    <option value={"Automática"}>Automática</option>
                                    <option value={"No automática"}>No automática</option>
                                </Form.Select>
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Cantidad
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textChQuantity" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textChQuantityUnit" placeholder="" />
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                FOB real
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textChFobReal" placeholder="" onChange={(event) => handleChFobReal(event)} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textChFobRealUnit" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textChFobRealTotal" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                CIF real
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textChCifReal" placeholder="" onChange={(event) => handleChCifReal(event)} />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textChCifRealUnit" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textChCifRealTotal" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Incoterm
                            </Col>
                            <Col>
                                <Form.Select id="selectChIncoterm" className=''>
                                    <option value={null}>Seleccionar...</option>
                                    <option value="EXW">EXW</option>
                                    <option value="FOB">FOB</option>
                                    <option value="FCA">FCA</option>
                                    <option value="CIP">CIP</option>
                                    <option value="CIF">CIF</option>
                                    <option value="DDP">DDP</option>
                                </Form.Select>
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Contenedor
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textChCntr" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Select id="selectChCntrUnit" className=''>
                                    <option value={null}>Seleccionar...</option>
                                    <option value={'LCL'}>LCL</option>
                                    <option value={'20"'}>20"</option>
                                    <option value={'40" STD'}>40" STD</option>
                                    <option value={'40" HQ'}>40" HQ</option>
                                    <option value={'40" NOR'}>40" NOR</option>
                                </Form.Select>
                            </Col>
                            <Col>

                            </Col>
                        </Row>

                        {isEditing ?
                            <Row className='ms-4 me-4 mt-3'>
                                <Col xs={2} className='cover content rows title'>
                                    Proforma
                                </Col>
                                <Col>
                                    <Form.Control className='' type="text" id="textProformaNumber" readOnly placeholder="" />
                                </Col>
                                <Col>

                                </Col>
                                <Col>

                                </Col>
                            </Row>
                            :
                            <div></div>
                        }



                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Declaración previa
                            </Col>
                            <Col>
                                <Form.Select id="selectChPreviousDeclaration" className='' >
                                    <option value={"SIMI"}>SIMI</option>
                                    <option value={"SIRA"}>SIRA</option>
                                </Form.Select>
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                        </Row>


                        <Row className='ms-4 me-4 mt-3 mb-5'>
                            <Col xs={2} className='cover content rows title'>
                                Condición de pago
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textChPaymentCondition" placeholder="" />
                            </Col>

                        </Row>


                    </Card>
                </Row>
            </Container>

            <Container>
                <Row >
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    Datos del negocio
                                </td>
                            </tr>
                        </table>


                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>

                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Valores
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Unidades
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                Totales (USD)
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Shipper
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuShipper" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuShipperUnit" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuShipperTotal" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Cliente
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuClient" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuClientUnit" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuClientTotal" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Despachante
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuDispatcher" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuDispatcherUnit" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuDispatcherTotal" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Forwarder (x gs agencia)
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuForwarderAgency" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuForwarderAgencyUnit" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuForwarderAgencyTotal" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Forwarder (x dif flete)
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuForwarderCharter" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuForwarderCharterUnit" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuForwarderCharterTotal" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Diferencia TC anticipo
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuDiffTCAdvance" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuDiffTCAdvanceUnit" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuDiffTCAdvanceTotal" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Diferencia TC saldo
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuDiffTCBalance" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuDiffTCBalanceUnit" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuDiffTCBalanceTotal" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Diferencia impositiva
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuDiffTax" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuDiffTaxUnit" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuDiffTaxTotal" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Varios
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuOthers" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuOthersUnit" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuOthersTotal" placeholder="" />
                            </Col>
                        </Row>

                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Total
                            </Col>
                            <Col>

                            </Col>
                            <Col>

                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="textBuTotal" placeholder="" />
                            </Col>
                        </Row>

                        {isEditing ?
                            <Row className='m-4'>
                                <Col style={{ textAlign: 'right' }}>
                                    <Button variant='danger' onClick={editOperation}><FontAwesomeIcon icon={faCheck} className='me-2'></FontAwesomeIcon>Confirmar</Button>
                                </Col>
                            </Row>
                            :
                            <Row className='m-4'>
                                <Col style={{ textAlign: 'right' }}>
                                    <Button variant='primary' onClick={createOperation}><FontAwesomeIcon icon={faCheck} className='me-2'></FontAwesomeIcon>Confirmar</Button>
                                </Col>
                            </Row>
                        }

                    </Card>
                </Row>
            </Container>

            <Modal show={showSelect} size="lg" onHide={handleCloseSelect}>
                <Modal.Header>
                    <Modal.Title>{modalType}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Form.Group className="" >
                                {modalType.includes("Contacto") ?
                                    ''
                                    :
                                    <div className='mb-3' style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='cover content rows element ms-2' type="text" id="businessIdentifier" placeholder="" autoComplete={false} onChange={(event) => handleSearchChain(event)} /></div>
                                }
                                <Container>
                                    {providers.map((r, i) => (
                                        <Row key={i} className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                            <Col xs={1} className='align-center-vertically middle'>
                                                <div>
                                                    <p className='m-0'><Badge bg='primary'>{r.identifier}</Badge></p>
                                                </div>
                                            </Col>

                                            <Col xs={9} className='align-center-vertically'>
                                                <div>
                                                    <p className='m-0' style={{ fontSize: 10 }}>{r.legalName}</p>
                                                    <p className='m-0'>{r.name}</p>
                                                </div>
                                            </Col>

                                            <Col xs={2} className='' style={{ textAlign: 'right' }}>
                                                <div>
                                                    {r.action}
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}

                                    {customers.map((r, i) => (
                                        <Row key={i} className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                            <Col xs={1} className='align-center-vertically middle'>
                                                <div>
                                                    <p className='m-0'><Badge bg='primary'>{r.identifier}</Badge></p>
                                                </div>
                                            </Col>

                                            <Col xs={9} className='align-center-vertically'>
                                                <div>
                                                    <p className='m-0' style={{ fontSize: 10 }}>{r.legalName}</p>
                                                    <p className='m-0'>{r.name}</p>
                                                </div>
                                            </Col>

                                            <Col xs={2} className='' style={{ textAlign: 'right' }}>
                                                <div>
                                                    {r.action}
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}

                                    {contacts.map((r, i) => (
                                        <Row key={i} className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                            <Col xs={3} className='align-center-vertically'>
                                                <div>
                                                    <p className='m-0'>{r.name}</p>
                                                </div>
                                            </Col>

                                            <Col xs={7} className='align-center-vertically'>
                                                <div>
                                                    <p className='m-0'>{r.data}</p>
                                                </div>
                                            </Col>

                                            <Col xs={2} className='' style={{ textAlign: 'right' }}>
                                                <div>
                                                    <Button className='buttonBlue' variant="primary" onClick={() => confirmModal(r.id, r.name, true)}><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </Container>
                            </Form.Group>
                        </Row>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSelect}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={newContact}>
                        Crear nuevo
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}