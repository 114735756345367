const DEV = false;

const DEV_PARAMS = {
    apiUri: "http://localhost:4000",
    homePage: "http://localhost:3000/inicio",
    dataUri: 'https://gestion.grinbalb.com/data'
};

const PROD_PARAMS = {
    apiUri: "https://ar-caba-sv6.seconline.net.ar:16301/api",
    homePage: "https://gestion.grinbalb.com/inicio",
    dataUri: 'https://gestion.grinbalb.com/data'
};


export const params = function () {
    if(DEV){
        return DEV_PARAMS;
    }else{
        return PROD_PARAMS;
    }
}