import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Form, Dropdown, Badge, Modal, Col, ToggleButton, ButtonGroup, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator, faEllipsisV, faEye, faIndustry, faPlusCircle, faSearch, faTrash, faFilePdf, faFileExcel, faDownload, faFile } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import Dropzone from 'react-dropzone'
import { get, post } from '../../controllers/Endpoints'
import "../../styles/styles.css";
import { getUserToken } from '../../controllers/UserController';
import { processDate, fileToBase64, pn } from '../../tools/tools';
import { getOverlayDirection } from 'react-bootstrap/esm/helpers';

export default function NewPaymentOrderStep1() {

    const [type, setType] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    const [payments, setPayments] = React.useState([]);
    const [currencies, setCurrencies] = React.useState([]);
    const [receiptTypes, setReceiptTypes] = React.useState([]);
    const [selectedBusiness, setSelectedBusiness] = React.useState(null);
    const [modalData, setModalData] = React.useState([]);
    const [selectableInvoices, setSelectableInvoices] = React.useState([]);
    const [modalTableHeader, setModalTableHeader] = React.useState([]);
    const [selectedInvoices, setSelectedInvoices] = React.useState([]);
    const [selectedInvoicesIds, setSelectedInvoicesIds] = React.useState([]);
    const [showInvoices, setShowInvoices] = React.useState(false);
    const [showSelect, setShowSelect] = React.useState(false);
    const handleCloseSelect = () => setShowSelect(false);
    const handleShowSelect = () => setShowSelect(true);
    const [showNewPaymentOrder, setShowNewPaymentOrder] = React.useState(false);
    const handleCloseNewPaymentOrder = () => setShowNewPaymentOrder(false);
    const handleShowNewPaymentOrder = () => setShowNewPaymentOrder(true);
    const [showInvoicesSelector, setShowInvoicesSelector] = React.useState(false);
    const handleCloseInvoicesSelector = () => setShowInvoicesSelector(false);
    const handleShowInvoicesSelector = () => setShowInvoicesSelector(true);
    const handleShowInvoices = (i, pid) => {
        //setNewReceiptPayment(pid)
        //setInvoices(i)
        setShowInvoices(true);
    }


    const PAGE_TITLE = title;
    const PAGE_ICON = faFile;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        getType();
        getCurrencies();

    }, []);

    const getType = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const data = urlParams.get('type');
        setType(data);
        let aux = ""
        if (data == "OP") {
            aux = "Nueva orden de pago";
        } else {
            aux = "Nuevo recibo";
        }
        setTitle(aux)
        document.title = `GrinBalb - ${aux}`;
    }

    const fileExtIcon = function (ext) {
        if (ext.includes(".pdf")) {
            return (<FontAwesomeIcon icon={faFilePdf} style={{ marginRight: 20, color: '#DC3545', fontSize: 30 }} />)
        }
        if (ext.includes(".xlsx")) {
            return (<FontAwesomeIcon icon={faFileExcel} style={{ marginRight: 20, color: '#28A745', fontSize: 30 }} />)
        }
    }

    const viewFile = async function (fileId) {
        const req = await get(`/files/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            //setShowFile(true);
            //showFileData(res.data);

            const linkSource = res.data;
            const downloadLink = document.createElement("a");
            const fileName = "demo.pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

        } else {
            alert(res.message);
        }
    }




    const processPayments = function (data) {
        let result = [];
        let isFirst = true;
        let aux1, aux2, aux3, aux4, aux5, aux6;

        for (let s of data) {
            if (type == "OP") {
                if (s.income == false) {
                    aux1 = `${pn(s.amount)} ${s.currencyAmount.code}`;
                    if (s.exchAmount) {
                        aux2 = `${pn(s.exchAmount)} ${s.currencyExchange.code}`;
                    } else {
                        aux2 = "";
                    }
                    aux3 = <Badge bg={s.Status.color}>{s.Status.name}</Badge>
                    aux4 = s.Receipts[0]
                    aux5 = s.Receipts
                    aux6 = `${pn(s.leftAmount)}`
                    result.push(Payment(s.income, aux1, aux2, s.description, null, s.createdAt, s.id, s.Receipts, s.Business.legalName, aux3, aux4.number, aux5, aux6))

                    isFirst = false;
                }
            } else if (type == "R") {
                if (s.income == true) {
                    aux1 = `${pn(s.amount)} ${s.currencyAmount.code}`;
                    if (s.exchAmount) {
                        aux2 = `${pn(s.exchAmount)} ${s.currencyExchange.code}`;
                    } else {
                        aux2 = "";
                    }
                    aux3 = <Badge bg={s.Status.color}>{s.Status.name}</Badge>
                    aux4 = s.Receipts[0]
                    aux5 = s.Receipts
                    aux6 = `${pn(s.leftAmount)}`
                    result.push(Payment(s.income, aux1, aux2, s.description, null, s.createdAt, s.id, s.Receipts, s.Business.legalName, aux3, aux4.number, aux5, aux6))

                    isFirst = false;
                }
            }



        }

        setTableRows(result)
        setPayments(data)

    }


    const Comment = (isFirst, title, content, isFile, file, user, date, commentId) => (
        <div className={`file element ${isFirst ? 'mt-0' : 'mt-3'}`}>
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>{title}</p>
                        <p className='m-0' style={{ fontSize: 12 }}>{content}</p>
                        <p className='mt-3 mb-0' style={{ fontSize: 12 }}><img className='me-1' style={{ width: 20, borderRadius: "50%" }} src="https://secdevs.com.ar/cbimage.png" alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{user.name} {user.surname} @ {processDate(date)}</span></p>
                    </td>
                    <td>
                        {isFile ? fileExtIcon(file.url) : ""}
                    </td>
                    <td className='w-100'>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {isFile ? <Dropdown.Item onClick={() => viewFile(file.id)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver</Dropdown.Item> : ''}
                                {isFile ? <Dropdown.Item><FontAwesomeIcon icon={faDownload} style={{ marginRight: 9, marginLeft: 1 }} />Descargar</Dropdown.Item> : ''}
                                <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            </table>
        </div>
    )

    const Payment = (income, amount, exchange, description, user, date, paymentId, receipts, business, status, invoice, invoices, left) => (
        <div className={`file element `}>
            <table>
                <tr>
                    <td style={{ width: 50 }}>
                        <Form.Check inline label="" name="group1" type='checkbox' id={`invoice-checked-${paymentId}`} />
                    </td>
                    <td className='w-100'>
                        <p className='m-0'>Factura {invoice} - {description}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{income ? <Badge bg='success'>Ingreso</Badge> : <Badge bg='danger'>Egreso</Badge>} - {status} - {business}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{amount} - {exchange} - Saldo: {left} ARS</p>

                    </td>
                    <td className='w-100'>
                        <Form.Control className='' style={{ width: 150, display: 'inline' }} type="text" id={`invoice-amount-${paymentId}`} placeholder="Monto" />
                    </td>
                </tr>
            </table>
        </div>
    )



    const getCurrencies = async function () {
        const req = await get('/currencies/', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setCurrencies(res);
            console.log(res)
        } else {
            alert(res.message);
        }
    }

    const showSelector = () => {
        getBusiness();
        setShowSelect(true);
    };

    const selectInvoice = (id) => {
        let aux = selectedInvoices;
        aux.push(id)
        setSelectedInvoicesIds(aux)

    };



    const getBusiness = async function () {
        const req = await get('/business/type/ALL', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processBusiness(res)
        } else {
            alert(res.message);
        }
    }

    const handleSearchChain = (event) => {
        const chain = event.target.value;

        if (chain === "") {
            getBusiness();
        } else {
            searchBusiness(chain)
        }
    };

    const searchBusiness = async function (c) {
        const chain = {
            chain: c
        }

        const req = await post('/business/type/ALL/search', getUserToken(), chain);
        const res = await req.json();

        if (req.status === 200) {
            processBusiness(res)
        } else {
            alert(res.message);
        }
    }

    const processBusiness = function (providers) {
        const cols = ['Código', 'Nombre', 'CUIT', 'Razón social', ''];
        setModalTableHeader(cols);

        let result = [];
        for (let p of providers) {
            p.action = <Button className='buttonBlue' variant="primary" onClick={() => confirmBusiness(p.id, p.legalName, false)}>Seleccionar</Button>
            result.push(p);
        }

        setModalData(
            <Table striped hover>
                <thead>
                    <tr>
                        {cols.map((c, i) => (
                            <th key={i} className='opTable head'>{c}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {result.map((r, i) => (
                        <tr key={i} >
                            <td className='opTable rows align-middle'>{r.identifier}</td>
                            <td className='opTable rows align-middle'>{r.name}</td>
                            <td className='opTable rows align-middle'>{r.legalId}</td>
                            <td className='opTable rows align-middle'>{r.legalName}</td>
                            <td className='opTable rows align-middle'>{r.action}</td>
                        </tr>
                    ))}

                </tbody>
            </Table>
        )
    }

    const confirmBusiness = function (id, data, contact) {
        setShowSelect(false);
        document.getElementById('business').value = data;
        setSelectedBusiness(id);

        //Get invoices
        getPendingInvoices(id)
    }

    const getPendingInvoices = async function (id) {
        const req = await get(`/operations/payments/pendingByBusiness/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processPayments(res)
        } else {
            alert(res.message);
        }
    }

    const createPaymentOrder = async () => {
        const qty = payments.length;
        let result = [];
        let aux, aux2;

        for (let i = 0; i < qty; i++) {
            aux = payments[i].id;
            if (document.getElementById(`invoice-checked-${aux}`).checked == true) {
                //Invoice selected
                if (document.getElementById(`invoice-amount-${aux}`).value.length > 0) {
                    aux2 = document.getElementById(`invoice-amount-${aux}`).value.replace(",", ".");
                } else {
                    aux2 = payments[i].leftAmount;
                }
                result.push({
                    id: aux,
                    amount: parseFloat(aux2)
                })
            }
        }

        let isReceipt = true;

        if (type == "OP") {
            isReceipt = false;
        }

        const body = {
            invoices: result,
            businessId: selectedBusiness,
            isReceipt: isReceipt
        }

        const req = await post(`/operations/payments/orders`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = `/administracion/ordendepago/nueva/2/?id=${res.id}&type=${type}`
        } else {
            alert(res.message);
        }
    }




    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    Facturas pendientes
                                </td>
                                <td className='header rows right'>


                                </td>
                            </tr>
                        </table>

                        <Container>
                            <Row className='mt-2'>
                                <Form.Text>Cliente / Proveedor</Form.Text>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control className='cover content rows element' readOnly type="text" id="business" placeholder="" />
                                    <Button variant='primary' className='ms-2 cover content rows searchbtn' onClick={showSelector}><FontAwesomeIcon icon={faSearch} /></Button>
                                </Col>
                            </Row>
                        </Container>


                        <Container className='mt-3 mb-4'>
                            {tableRows.map((r, i) => (
                                <div className='mt-2'>
                                    {r ? r : ""}
                                </div>
                            ))}
                        </Container>

                        <Row className='m-4'>
                            <Col style={{ textAlign: 'right' }}>

                                <Button variant='primary' onClick={createPaymentOrder}>Siguiente</Button>

                            </Col>
                        </Row>

                    </Card>
                </Row>
            </Container>



            <Modal show={showSelect} size="lg" centered onHide={handleCloseSelect}>
                <Modal.Header>
                    <Modal.Title>Seleccionar cliente / proveedor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Form.Group className="" controlId="exampleForm.ControlInput1">
                                <div className='mb-3'><FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='cover content rows element ms-2' type="text" id="businessIdentifier" placeholder="" onChange={(event) => handleSearchChain(event)} /></div>
                                {modalData}
                            </Form.Group>
                        </Row>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSelect}>
                        Cancelar
                    </Button>
                    <Button variant="success" >
                        Crear nuevo
                    </Button>
                </Modal.Footer>
            </Modal>




            <Modal show={showInvoicesSelector} size="lg" centered onHide={handleCloseInvoicesSelector}>
                <Modal.Header>
                    <Modal.Title>Facturas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Form.Group className="" controlId="exampleForm.ControlInput1">
                                {selectableInvoices.map((r, i) => (
                                    <div>
                                        {r ? r : ""}
                                    </div>

                                ))}
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseInvoicesSelector}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}