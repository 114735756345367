import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Form, Col, Table, Modal, Dropdown, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook, faArrowLeft, faPlusCircle, faPhoneAlt, faEnvelope, faMapMarkerAlt, faInfoCircle, faAddressCard, faTrash, faPen, faIndustry, faUser, faCheck, faEllipsisV, faEye, faDownload, faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { post, get, del, put } from '../../controllers/Endpoints'
import { getUserToken } from '../../controllers/UserController';
import "../../styles/styles.css";
import { processDate, fileToBase64, pn } from '../../tools/tools';

export default function ProviderDetails() {

    const [businessName, setBusinessName] = React.useState('');
    const [businessIdentifier, setBusinessIdentifier] = React.useState('');
    const [businessLegalName, setBusinessLegalName] = React.useState('');
    const [businessLegalId, setBusinessLegalId] = React.useState('');
    const [businessTypes, setBusinessTypes] = React.useState([]);
    const [businessTypesBadges, setBusinessTypesBadges] = React.useState([]);
    const [businessContacts, setBusinessContacts] = React.useState([]);
    const [businessAddress, setBusinessAddress] = React.useState('');
    const [businessPostalCode, setBusinessPostalCode] = React.useState('');
    const [businessCity, setBusinessCity] = React.useState('');
    const [businessProvince, setBusinessProvince] = React.useState('');
    const [businessCountry, setBusinessCountry] = React.useState('');
    const [tableColumns, setTableColumns] = React.useState([]);
    const [showNewContact, setShowNewContact] = React.useState(false);
    const [showNewContactData, setShowNewContactData] = React.useState(false);
    const [showSureDelete, setShowSureDelete] = React.useState(false);
    const [showSureEditCustomer, setShowSureEditCustomer] = React.useState(false);
    const [newContactName, setNewContactName] = React.useState('');
    const [currentContact, setCurrentContact] = React.useState('');
    const [currentContactName, setCurrentContactName] = React.useState('');
    const [currentContactType, setCurrentContactType] = React.useState('Seleccionar...');
    const [newContactData, setNewContactData] = React.useState('');
    const [bankAccounts, setBankAccounts] = React.useState([]);
    const [countries, setCountries] = React.useState([]);
    const [selectedCountry, setSelectedCountry] = React.useState(0);
    const [currencies, setCurrencies] = React.useState([]);
    const [selectedCurrency, setSelectedCurrency] = React.useState(0);
    const [isEditing, setEditing] = React.useState(false);

    const [pendingPayments, setPendingPayments] = React.useState([]);
    const [invoices, setInvoices] = React.useState([]);
    const [showInvoices, setShowInvoices] = React.useState(false);
    const handleCloseInvoices = () => setShowInvoices(false);
    const handleShowInvoices = (i, pid) => {
        //setNewReceiptPayment(pid)
        setInvoices(i)
        setShowInvoices(true);
    }

    const [showNewBankAccountModal, setShowNewBankAccountModal] = React.useState(false);
    const handleCloseNewBankAccountModal = () => setShowNewBankAccountModal(false);
    const handleShowNewBankAccountModal = () => setShowNewBankAccountModal(true);

    const handleCloseNewContact = () => setShowNewContact(false);
    const handleShowNewContact = () => setShowNewContact(true);
    const handleCloseNewContactData = () => setShowNewContactData(false);
    const handleShowNewContactData = (contactId) => {
        setCurrentContact(contactId);
        setShowNewContactData(true);
    };
    const handleCloseSureDelete = () => setShowSureDelete(false);
    const handleShowSureDelete = (name, id) => {
        setCurrentContactName(name);
        setCurrentContact(id);
        setShowSureDelete(true);
    };
    const handleCloseSureEditCustomer = () => setShowSureEditCustomer(false);
    const handleShowSureEditCustomer = () => setShowSureEditCustomer(true);

    const PAGE_TITLE = "Empresa";
    const PAGE_ICON = faIndustry;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `GrinBalb - ${PAGE_TITLE}`;

        getData();
    }, []);

    const getData = async () => {
        await getCountries();
        getProvider();
        getContacts();
        setupTable();
        getProviderTypes();
        getBankAccounts();
        getCountries();
        getCurrencies();
        getPendingPayments();
    }

    const handleEditing = () => {
        setEditing(!isEditing);
    };

    const handleBusinessName = (event) => {
        setBusinessName(event.target.value);
    };

    const handleBusinessIdentifier = (event) => {
        setBusinessIdentifier(event.target.value);
    };

    const handleBusinessLegalName = (event) => {
        setBusinessLegalName(event.target.value);
    };

    const handleBusinessLegalId = (event) => {
        setBusinessLegalId(event.target.value);
    };

    const handleBusinessAddress = (event) => {
        setBusinessAddress(event.target.value);
    };

    const handleBusinessPostalCode = (event) => {
        setBusinessPostalCode(event.target.value);
    };

    const handleBusinessCity = (event) => {
        setBusinessCity(event.target.value);
    };

    const handleBusinessProvince = (event) => {
        setBusinessProvince(event.target.value);
    };

    const handleBusinessCountry = (event) => {
        setBusinessCountry(event.target.value);
    };

    const handleCountry = (event) => {
        setSelectedCountry(event.target.value);
    };

    const handleCurrency = (event) => {
        setSelectedCurrency(event.target.value);
    };

    const getCountries = async function () {
        const req = await get('/countries/', getUserToken());
        const res = await req.json();

        console.log(req)

        if (req.status === 200) {
            setCountries(res);
        } else {
            alert(res.message);
        }
    }

    const getCurrencies = async function () {
        const req = await get('/currencies/', getUserToken());
        const res = await req.json();

        console.log(req)

        if (req.status === 200) {
            setCurrencies(res);
        } else {
            alert(res.message);
        }
    }

    const getParamId = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('id');
    }

    const getProvider = async function () {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const businessId = urlParams.get('id');

        const req = await get(`/business/${businessId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processProvider(res)
        } else {
            alert(res.message);
        }
    }

    const getBusinessId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getBankAccounts = async function () {
        const req = await get(`/operations/payments/accounts/${getBusinessId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processBankAccounts(res)
        } else {
            alert(res.message);
        }
    }

    const processBankAccounts = (data) => {
        let result = [];

        for (let b of data) {
            result.push(
                <div className={`file element mt-3}`}>
                    <Row>
                        <Col xs={2} className='middle-vertical'>
                            <p className='m-0'>{b.name}</p>
                            <p className='m-0' style={{ fontSize: 12 }}>{b.Country.name}</p>
                        </Col>
                        <Col>
                            <p className='m-0'><span className='pe-1' style={{ color: "gray", fontSize: 12 }}>Banco</span>{b.bank}</p>
                            <p className='m-0'><span className='pe-1' style={{ color: "gray", fontSize: 12 }}>{b.Country.name == "Argentina" ? "CBU" : "SWIFT"} </span>{b.Country.name == "Argentina" ? b.cbu : b.swift}</p>
                            <p className='m-0'><span className='pe-1' style={{ color: "gray", fontSize: 12 }}>Nro. de cuenta</span>{b.account}</p>
                        </Col>
                        <Col>
                            <p className='m-0'><span className='pe-1' style={{ color: "gray", fontSize: 12 }}>{b.Country.name == "Argentina" ? "Alias" : "Dirección"} </span>{b.Country.name == "Argentina" ? b.alias : b.bankAddress}</p>
                            <p className='m-0'><span className='pe-1' style={{ color: "gray", fontSize: 12 }}>Moneda</span>{b.Currency.code}</p>

                        </Col>
                        <Col className='middle-vertical' style={{ textAlign: 'right' }}>
                            <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>

                                    <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
            )
        }

        setBankAccounts(result);
    }

    const processProvider = function (data) {
        document.getElementById('businessName').value = data.name;
        document.getElementById('businessIdentifier').value = data.identifier;
        document.getElementById('businessLegalName').value = data.legalName;
        document.getElementById('businessLegalId').value = data.legalId;
        document.getElementById('businessAddress').value = data.address;
        document.getElementById('businessPostalCode').value = data.postalCode;
        document.getElementById('businessCity').value = data.city;
        document.getElementById('businessProvince').value = data.province;
        document.getElementById('businessCountry').value = data.country;

        document.getElementById('titleBusinessName').innerHTML = data.name;

        setBusinessName(data.name);
        setBusinessIdentifier(data.identifier);
        setBusinessLegalName(data.legalName);
        setBusinessLegalId(data.legalId);

        let types = [];
        for (let pt of data.BusinessProviderTypes) {
            console.log(pt.ProviderType.name);
            types.push(<span className='me-1'><Badge bg='primary' className='content-table status'>{pt.ProviderType.name}</Badge></span>)
        }
        types.push(<Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }}></FontAwesomeIcon>Añadir</Button>)
        setBusinessTypesBadges(types);
    }

    const handleNewContactName = (event) => {
        setNewContactName(event.target.value);
    };

    const handleNewContactData = (event) => {
        setNewContactData(event.target.value);
    };

    const createContact = async function () {
        setShowNewContact(false);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const businessId = urlParams.get('id');

        const contact = {
            name: newContactName,
        };

        const req = await post(`/business/${businessId}/contact`, getUserToken(), contact);
        const res = await req.json();

        if (req.status === 201) {
            getContacts();
        } else {
            alert(res.message);
        }
    }

    const getContacts = async function () {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const businessId = urlParams.get('id');

        const req = await get(`/business/${businessId}/contact`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processContacts(res)
        } else {
            alert(res.message);
        }
    }

    const processContacts = function (data) {
        let result = [];
        let aux;
        let icon;

        for (let c of data) {
            aux = null;

            for (let i = 0; i < c.ContactData.length; i++) {
                switch (c.ContactData[i].param) {
                    case "PHON":
                        icon = <FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon>;
                        break;
                    case "EMAI":
                        icon = <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>;
                        break;
                    case "ADDR":
                        icon = <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>;
                        break;
                    case "POSI":
                        icon = <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>;
                        break;
                    case "OTHE":
                        icon = <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>;
                        break;
                    default:
                        break;
                }

                if (i === 0) {
                    aux = <span>{icon} {c.ContactData[i].data}</span>;
                } else {
                    aux = <span>{aux} | {icon} {c.ContactData[i].data}</span>;
                }
            }
            c.data = aux;
            c.action = <span><Button variant="success" onClick={() => handleShowNewContactData(c.id)}><FontAwesomeIcon icon={faAddressCard} style={{ marginRight: 8 }}></FontAwesomeIcon>Añadir</Button><Button className='ms-1' variant="danger" onClick={() => handleShowSureDelete(c.name, c.id)}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button></span>
            result.push(c);
        }
        setBusinessContacts(result);
    }

    const setupTable = function () {
        const cols = ['Nombre', 'Datos', ''];
        setTableColumns(cols);
    }

    const changeCurrentContactType = function (type) {
        setCurrentContactType(type);
        document.getElementById('currentContactType').innerHTML = type;
    }

    const createContactData = async function () {
        setShowNewContactData(false);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const businessId = urlParams.get('id');

        let param;

        switch (currentContactType) {
            case "Teléfono":
                param = "PHON";
                break;
            case "Correo electrónico":
                param = "EMAI";
                break;
            case "Dirección":
                param = "ADDR";
                break;
            case "Cargo":
                param = "POSI";
                break;
            case "Otros":
                param = "OTHE";
                break;
            default:
                break;
        }

        const contact = {
            param: param,
            data: newContactData
        };

        const req = await post(`/business/${businessId}/contact/${currentContact}`, getUserToken(), contact);
        const res = await req.json();

        if (req.status === 201) {
            getContacts();
        } else {
            alert(res.message);
        }
    }

    const deleteContact = async function () {
        setShowSureDelete(false);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const businessId = urlParams.get('id');

        const req = await del(`/business/${businessId}/contact/${currentContact}`, getUserToken());
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getContacts();
        } else {
            alert(res.message);
        }
    }

    const editCustomer = async function () {
        setShowSureEditCustomer(false);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const businessId = urlParams.get('id');

        const customer = {
            name: businessName,
            legalName: businessLegalName,
            legalId: businessLegalId,
            identifier: businessIdentifier,
            address: document.getElementById('businessAddress').value,
            postalCode: document.getElementById('businessPostalCode').value,
            city: document.getElementById('businessCity').value,
            province: document.getElementById('businessProvince').value,
            country: document.getElementById('businessCountry').value,
            businessType: 'PROVIDER'
        };

        const req = await put(`/business/${businessId}`, getUserToken(), customer);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else {
            alert(res.message);
        }
    }

    const getProviderTypes = async function () {
        const req = await get('/business/type/providers', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setBusinessTypes(res);
        } else {
            alert(res.message);
        }
    }

    const createBankAccount = async () => {
        let data = {
            name: document.getElementById('newBankAccountName').value,
            cbu: document.getElementById('newBankAccountCBU') ? document.getElementById('newBankAccountCBU').value : null,
            alias: document.getElementById('newBankAccountAlias') ? document.getElementById('newBankAccountAlias').value : null,
            businessId: getBusinessId(),
            bank: document.getElementById('newBankAccountBank').value,
            bankAddress: document.getElementById('newBankAccountAddress') ? document.getElementById('newBankAccountAddress').value : null,
            currencyId: document.getElementById('newBankAccountCurrency').value,
            swift: document.getElementById('newBankAccountSWIFT') ? document.getElementById('newBankAccountSWIFT').value : null,
            account: document.getElementById('newBankAccountAccount').value,
            other: document.getElementById('newBankAccountOther').value,
            countryId: selectedCountry
        }

        const req = await post('/operations/payments/accounts/', getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseNewBankAccountModal();
            getBankAccounts()
        } else {
            alert(res.message);
        }
    }

    const Comment = (isFirst, title, content, isFile, file, user, date, commentId) => (
        <div className={`file element ${isFirst ? 'mt-0' : 'mt-3'}`}>
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>{title}</p>
                        <p className='m-0' style={{ fontSize: 12 }}>{content}</p>
                        <p className='mt-3 mb-0' style={{ fontSize: 12 }}><img className='me-1' style={{ width: 20, borderRadius: "50%" }} src="https://secdevs.com.ar/cbimage.png" alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{user.name} {user.surname} @ {processDate(date)}</span></p>
                    </td>
                    <td>
                        {isFile ? fileExtIcon(file.url) : ""}
                    </td>
                    <td className='w-100'>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {isFile ? <Dropdown.Item onClick={() => viewFile(file.id)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver</Dropdown.Item> : ''}
                                {isFile ? <Dropdown.Item><FontAwesomeIcon icon={faDownload} style={{ marginRight: 9, marginLeft: 1 }} />Descargar</Dropdown.Item> : ''}
                                <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            </table>
        </div>
    )

    const Payment = (income, amount, exchange, description, user, date, paymentId, receipts, business, status, invoice, invoices, left) => (
        <div className={`file element `}>
            <table>
                <tr>
                    <td className='w-100'>
                        <p className='m-0'>Factura {invoice} - {description}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{income ? <Badge bg='success'>Ingreso</Badge> : <Badge bg='danger'>Egreso</Badge>} - {status} - {business}</p>
                        <p className='m-0 pt-1' style={{ fontSize: 12 }}>{amount} - {exchange} - Saldo: {left} ARS</p>

                    </td>
                    <td>
                        {fileExtIcon(receipts[0].File.url)}
                    </td>
                    <td className='w-100'>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleShowInvoices(invoices, paymentId)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver documentos</Dropdown.Item>
                                <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            </table>
        </div>
    )

    const fileExtIcon = function (ext) {
        if (ext.includes(".pdf")) {
            return (<FontAwesomeIcon icon={faFilePdf} style={{ marginRight: 20, color: '#DC3545', fontSize: 30 }} />)
        }
        if (ext.includes(".xlsx")) {
            return (<FontAwesomeIcon icon={faFileExcel} style={{ marginRight: 20, color: '#28A745', fontSize: 30 }} />)
        }
    }

    const viewFile = async function (fileId) {
        const req = await get(`/files/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            //setShowFile(true);
            //showFileData(res.data);

            const linkSource = res.data;
            const downloadLink = document.createElement("a");
            const fileName = "demo.pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

        } else {
            alert(res.message);
        }
    }

    const getPendingPayments = async function () {
        const req = await get(`/operations/payments/pendingByBusiness/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processPayments(res)
            console.log(res)
        } else {
            alert(res.message);
        }
    }

    const processPayments = function (data) {
        let result = [];
        let result2 = [];
        let isFirst = true;
        let aux1, aux2, aux3, aux4, aux5, aux6;

        for (let s of data) {
            //Its a payment
            aux1 = `${pn(s.amount)} ${s.currencyAmount.code}`;
            if (s.exchAmount) {
                aux2 = `${pn(s.exchAmount)} ${s.currencyExchange.code}`;
            } else {
                aux2 = "";
            }
            aux3 = <Badge bg={s.Status.color}>{s.Status.name}</Badge>
            aux4 = s.Receipts[0]
            aux5 = s.Receipts
            aux6 = `${pn(s.leftAmount)}`
            result.push(Payment(s.income, aux1, aux2, s.description, null, s.createdAt, s.id, s.Receipts, s.Business.legalName, aux3, aux4.number, aux5, aux6))

            isFirst = false;
        }

        setPendingPayments(result)


    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p style={{ fontSize: 20 }} className='text-muted mb-0'>{PAGE_TITLE}</p>
                                    <p style={{ color: '#A0A0A0', fontSize: 45 }} id="titleBusinessName"></p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>
                                    <Link to={'/empresas'} className='linkBlue'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Empresas
                                    </Link>
                                    <span className='ms-3' style={{ color: '#A0A0A0' }}>|</span><span className='ms-3'>Datos generales</span>
                                </td>

                                <td className='header rows right'>
                                    <Button variant="warning" onClick={handleEditing}><FontAwesomeIcon icon={faPen} style={{ marginRight: 8 }} />Editar</Button>
                                </td>

                            </tr>
                        </table>

                        <Row className='ms-4 me-4 mt-5'>
                            <Col xs={2} className='cover content rows title'>
                                Nombre
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessName" placeholder="" readOnly={!isEditing} onChange={(event) => handleBusinessName(event)} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Identificador
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessIdentifier" placeholder="" readOnly={!isEditing} onChange={(event) => handleBusinessIdentifier(event)} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Razón social
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessLegalName" placeholder="" readOnly={!isEditing} onChange={(event) => handleBusinessLegalName(event)} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                CUIT / Tax ID
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessLegalId" placeholder="" readOnly={!isEditing} onChange={(event) => handleBusinessLegalId(event)} />
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Dirección
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessAddress" readOnly={!isEditing} placeholder="" />
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Código postal
                            </Col>
                            <Col>
                                <Form.Control className='w-25' type="text" id="businessPostalCode" readOnly={!isEditing} placeholder="" />
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Localidad
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessCity" readOnly={!isEditing} placeholder="" />
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3'>
                            <Col xs={2} className='cover content rows title'>
                                Provincia
                            </Col>
                            <Col>
                                <Form.Control className='' type="text" id="businessProvince" readOnly={!isEditing} placeholder="" />
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className='ms-4 me-4 mt-3 mb-5'>
                            <Col xs={2} className='cover content rows title'>
                                País
                            </Col>
                            <Col>
                                <Form.Select className='' id="businessCountry" disabled={!isEditing}>
                                    <option value={""}>Seleccionar...</option>
                                    {countries.map((c, i) => (
                                        <option key={i} id={i} value={c.name}>{c.name} ({c.code})</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                            </Col>
                        </Row>

                        <Container>
                            {isEditing ?
                                <div className='mt-2 mb-4' style={{ textAlign: 'right' }}>
                                    <Button variant="danger" onClick={handleShowSureEditCustomer}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 8 }} />Confirmar cambios</Button>
                                </div>

                                :
                                <div></div>
                            }
                        </Container>



                    </Card>
                </Row>
            </Container>

            <Container>
                <Row style={{ marginTop: -20 }}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>

                                    <span>Contactos</span>
                                </td>
                                <td className='header rows right'>
                                    <Button variant="success" onClick={handleShowNewContact}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo contacto</Button>
                                </td>

                            </tr>
                        </table>

                        {businessContacts.length > 0 ?
                            <Table striped hover className='mt-3'>
                                <thead>
                                    <tr>
                                        {tableColumns.map((c, i) => (
                                            <th key={i} className='opTable head'>{c}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {businessContacts.map((r, i) => (
                                        <tr key={i} >
                                            <td className='ps-4 align-middle'>{r.name}</td>
                                            <td className='align-middle'>{r.data}</td>
                                            <td className='pe-4 row-right align-middle'>{r.action}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table>

                            :
                            <Row style={{ height: 300, textAlign: "center" }}>
                                <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon></p>
                                <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay contactos</p>
                            </Row>
                        }


                    </Card>
                </Row>
            </Container>

            <Container>
                <Row style={{ marginTop: -20 }}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>

                                    <span>Datos bancarios</span>
                                </td>
                                <td className='header rows right'>
                                    <Button variant="success" onClick={handleShowNewBankAccountModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo dato</Button>
                                </td>

                            </tr>
                        </table>

                        {bankAccounts.length > 0 ?
                            <div className='mt-3 mb-4'>
                                {bankAccounts.map((c, i) => (
                                    <div className='ps-4 pe-4 pt-2'>{c}</div>
                                ))}
                            </div>

                            :
                            <Row style={{ height: 300, textAlign: "center" }}>
                                <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon></p>
                                <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay contactos</p>
                            </Row>
                        }


                    </Card>
                </Row>
            </Container>

            <Container>
                <Row style={{ marginTop: -20 }}>
                    <Card className='cards content mb-5'>
                        <table className='header content'>
                            <tr>
                                <td className='header rows left'>

                                    <span>Facturas pendientes de pago</span>
                                </td>
                                <td className='header rows right'>

                                </td>

                            </tr>
                        </table>

                        <Container className='mt-3 mb-4'>
                            {pendingPayments.map((r, i) => (
                                <div className='mt-2'>
                                    {r ? r : ""}
                                </div>
                            ))}
                        </Container>


                    </Card>
                </Row>
            </Container>

            <Modal show={showNewContact} centered onHide={handleCloseNewContact}>
                <Modal.Header closeButtonNewContact>
                    <Modal.Title>Nuevo contacto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Nombre y apellido</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Juan Pérez"
                                    autoFocus
                                    onChange={(event) => handleNewContactName(event)}
                                />
                            </Form.Group>
                        </Row>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewContact}>
                        Cancelar
                    </Button>
                    <Button variant="success" onClick={createContact}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewBankAccountModal} centered onHide={handleCloseNewBankAccountModal}>
                <Modal.Header closeButtonNewContact>
                    <Modal.Title>Nuevo dato bancario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>País</Form.Label>
                                <Form.Select className='' onChange={(event) => handleCountry(event)} id="">
                                    <option value="0">Seleccionar...</option>
                                    {countries.map((c, i) => (
                                        <option key={i} id={i} value={c.id}>{c.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            {selectedCountry == 0 ?
                                <div></div>
                                :
                                <div>
                                    {selectedCountry == 11 ?
                                        <div>
                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>Nombre descriptivo</Form.Text>
                                                <Form.Control className='' type="text" id="newBankAccountName" placeholder="" />
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>Moneda</Form.Text>
                                                <Form.Select className='' id="newBankAccountCurrency">
                                                    <option value="0">Seleccionar...</option>
                                                    {currencies.map((c, i) => (
                                                        <option key={i} id={i} value={c.id}>{c.code}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>Banco</Form.Text>
                                                <Form.Control type="text" id="newBankAccountBank" placeholder="" />
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>CBU</Form.Text>
                                                <Form.Control type="text" id="newBankAccountCBU" placeholder="" />
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>Alias</Form.Text>
                                                <Form.Control type="text" id="newBankAccountAlias" placeholder="" />
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>Número de cuenta</Form.Text>
                                                <Form.Control type="text" id="newBankAccountAccount" placeholder="" />
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>Otros</Form.Text>
                                                <Form.Control type="text" id="newBankAccountOther" placeholder="" />
                                            </Form.Group>

                                        </div>
                                        :
                                        <div>
                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>Nombre descriptivo</Form.Text>
                                                <Form.Control className='' type="text" id="newBankAccountName" />
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>Moneda</Form.Text>
                                                <Form.Select className='' id="newBankAccountCurrency">
                                                    <option value="0">Seleccionar...</option>
                                                    {currencies.map((c, i) => (
                                                        <option key={i} id={i} value={c.id}>{c.code}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>Banco</Form.Text>
                                                <Form.Control type="text" id="newBankAccountBank" placeholder="" />
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>SWIFT</Form.Text>
                                                <Form.Control type="text" id="newBankAccountSWIFT" placeholder="" />
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>Dirección</Form.Text>
                                                <Form.Control type="text" id="newBankAccountAddress" placeholder="" />
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>Número de cuenta</Form.Text>
                                                <Form.Control type="text" id="newBankAccountAccount" placeholder="" />
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                                <Form.Text>Otros</Form.Text>
                                                <Form.Control type="text" id="newBankAccountOther" placeholder="" />
                                            </Form.Group>
                                        </div>
                                    }
                                </div>
                            }

                        </Row>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewBankAccountModal}>
                        Cancelar
                    </Button>
                    <Button variant="success" onClick={createBankAccount}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewContactData} centered onHide={handleCloseNewContactData}>
                <Modal.Header closeButtonNewContact>
                    <Modal.Title>Añadir datos de contacto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Row>
                                    <Col xs={5}>
                                        <Dropdown className='w-100'>
                                            <Dropdown.Toggle variant="primary" className='w-100' id="dropdown-basic">
                                                <span id='currentContactType'>Seleccionar...</span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='w-100'>
                                                <Dropdown.Item onClick={() => changeCurrentContactType("Telefono")}>Teléfono</Dropdown.Item>
                                                <Dropdown.Item onClick={() => changeCurrentContactType("Correo electrónico")}>Correo electrónico</Dropdown.Item>
                                                <Dropdown.Item onClick={() => changeCurrentContactType("Dirección")}>Dirección</Dropdown.Item>
                                                <Dropdown.Item onClick={() => changeCurrentContactType("Cargo")}>Cargo</Dropdown.Item>
                                                <Dropdown.Item onClick={() => changeCurrentContactType("Otro")}>Otro</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>

                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            autoFocus
                                            onChange={(event) => handleNewContactData(event)}
                                        />
                                    </Col>
                                </Row>

                            </Form.Group>
                        </Row>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewContactData}>
                        Cancelar
                    </Button>
                    <Button variant="success" onClick={createContactData}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSureDelete} centered onHide={handleCloseSureDelete}>
                <Modal.Header closeButtonSureDelete>
                    <Modal.Title>Eliminar contacto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar al contacto {currentContactName}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSureDelete}>
                        Cancelar
                    </Button>
                    <Button variant="success" onClick={deleteContact}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSureEditCustomer} centered onHide={handleCloseSureEditCustomer}>
                <Modal.Header closeButtonSureEditCustomer>
                    <Modal.Title>Editar proveedor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de editar los datos de {businessName}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSureEditCustomer}>
                        Cancelar
                    </Button>
                    <Button variant="success" onClick={editCustomer}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal show={showInvoices} size="lg" centered onHide={handleCloseInvoices}>
                <Modal.Header>
                    <Modal.Title>Documentos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row style={{ marginTop: -17 }}>
                            <Form.Group className="" controlId="exampleForm.ControlInput1">
                                {invoices != undefined ? invoices.map((r, i) => (
                                    <div>{Comment(false, `${r.ReceiptType.name} - ${r.number}`, r.description, true, r.File, r.User, r.createdAt, r.id)}</div>
                                )) : ""}
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseInvoices}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
