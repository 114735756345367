import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Row } from "react-bootstrap";

import Home from "./components/ui/home/Home";
//import Calendar from "./components/ui/calendar/Calendar";
import Customers from "./components/ui/customers/Customers";
import CustomerDetails from "./components/ui/customers/CustomerDetails";
import NewCustomer from "./components/ui/customers/NewCustomer";
import Operations from "./components/ui/operations/Operations";
import NewOperation from "./components/ui/operations/newOperation";
import NewCoverCreated from "./components/ui/operations/newCover/NewCoverCreated";
import DetailedOperation from "./components/ui/operations/detailedOperation/DetailedOperation";
import Providers from "./components/ui/providers/Providers";
import NewProvider from "./components/ui/providers/NewProvider";
import ProviderDetails from "./components/ui/providers/ProviderDetails";
import Reports from "./components/ui/reports/Reports";
import Administration from "./components/ui/administration/Administration";
import NewPaymentOrderStep1 from "./components/ui/administration/NewPaymentOrderStep1";
import NewPaymentOrderStep2 from "./components/ui/administration/NewPaymentOrderStep2";
import NewPaymentOrderCreated from "./components/ui/administration/NewPaymentOrderCreated";
import Settings from "./components/ui/settings/Settings";
import Index from "./components/ui/home/Index";
import UserDetails from "./components/ui/settings/UserDetails";
import MyUserDetails from "./components/ui/settings/MyUserDetails";
import NewUser from "./components/ui/settings/NewUser";
import Business from "./components/ui/business/Business";
import BusinessDetails from "./components/ui/business/BusinessDetails";
import BusinessNew from "./components/ui/business/BusinessNew";

import CntrReport from "./components/ui/reports/CntrReportV2"
import CntrReport2 from "./components/ui/reports/CntrReportV2"
import Login from "./components/ui/login/Login"

export function App() {
    return (
        <div>
            <Router>
                <Row className="m-0">
                    <Routes>
                        <Route path="/" element={<Index />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/inicio" element={<Home />} />
                        <Route path="/empresas" element={<Business />} />
                        <Route path="/clientes" element={<Customers />} />
                        <Route path="/clientes/detalles" element={<CustomerDetails />} />
                        <Route path="/clientes/nuevo" element={<NewCustomer />} />
                        <Route path="/operaciones" element={<Operations />} />
                        <Route path="/operaciones/nueva/" element={<NewOperation />} />
                        <Route path="/operaciones/nueva/ok" element={<NewCoverCreated />} />
                        <Route path="/operaciones/detalles/" element={<DetailedOperation />} />
                        <Route path="/proveedores" element={<Providers />} />
                        <Route path="/proveedores/nuevo" element={<NewProvider />} />
                        <Route path="/proveedores/detalles" element={<ProviderDetails />} />
                        <Route path="/reportes" element={<Reports />} />
                        <Route path="/administracion" element={<Administration />} />
                        <Route path="/administracion/ordendepago/nueva/1" element={<NewPaymentOrderStep1 />} />
                        <Route path="/administracion/ordendepago/nueva/2/" element={<NewPaymentOrderStep2 />} />
                        <Route path="/administracion/ordendepago/nueva/ok/" element={<NewPaymentOrderCreated />} />
                        <Route path="/sistema" element={<Settings />} />
                        <Route path="/reportes/cntr" element={<CntrReport />} />
                        <Route path="/reportes/cntr2" element={<CntrReport2 />} />
                        <Route path="/sistema/usuarios/detalles" element={<UserDetails />} />
                        <Route path="/sistema/usuarios/nuevo" element={<NewUser />} />
                        <Route path="/configuracion/usuarios/detalles" element={<MyUserDetails />} />
                        <Route path="/empresas" element={<Business />} />
                        <Route path="/empresas/detalles" element={<BusinessDetails />} />
                        <Route path="/empresas/nuevo" element={<BusinessNew />} />
                    </Routes>
                </Row>
            </Router>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
        </div>
    );
}